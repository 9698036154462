import React from 'react'
import {Pagination} from 'react-bootstrap';

const pagination = ({activePage, assetsPerPage, totalAssets, setPageNumber, type}) => {
    // const pagination = ({assetsPerPage, totalAssets}) => {
    const pageNumbers = [];
    const maxNumOfPages = Math.ceil(totalAssets / assetsPerPage);

    for(let i=1; i <= maxNumOfPages; i++){
        pageNumbers.push(
            <Pagination.Item style={{boxShadow: '0px 0px 3px grey'}} key={i} active={i === activePage} onClick={()=> setPageNumber(i)}>
            {/* <Pagination.Item style={{boxShadow: '0px 0px 3px grey'}} key={i}> */}
                <b>{i}</b>
            </Pagination.Item>,
        );
    }

    //console.log(type);

    return (
        <Pagination size='lg' style={{display: 'flex', justifyContent: 'center'}} hidden={maxNumOfPages === 1 ? true : false}>
            <Pagination.Prev style={{boxShadow: '0px 0px 3px grey', marginRight: '1rem'}} onClick={() => {if(activePage > 1) setPageNumber(activePage-1)} }/>
            {pageNumbers}
            <Pagination.Next style={{boxShadow: '0px 0px 3px grey', marginLeft: '1rem'}} onClick={() => {if(activePage < maxNumOfPages) setPageNumber( activePage+1)}}/>
        </Pagination>
    )

}

export default pagination

