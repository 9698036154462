/* eslint-disable no-lone-blocks */
import {React, Component, useState, useEffect} from "react";
// import { Auth, autoShowTooltip, Storage} from 'aws-amplify';
// import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { Button, ProgressBar, Card, Container, Row, Col, Jumbotron, Toast, Spinner, Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styling/upload.css';
import * as QrCode from 'qrcode.react'

import check from '../icons/check.png'
import error from '../icons/cancel_1.png'
import warning from '../icons/warning.png'

// Xenkia Configurations.
import XenkiaAWSconfig from '../config'

import { v5 as uuidv5 } from 'uuid';

export var s3_base_directory = "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/protected/";


// Currently logged in user's email address.
var loggedin_user_email = "";
var updated_upload_counter = 0;



/**
 * This function will return an UUID for each item uploaded in Xenkia.
 * Note: Use the Currently logged in users email, Selected filename and Currently
 *       date&time to generate a unique UUID for the uploaded asset. WE EVENTUIALLY
 *       HAVE TO IMPLEMENT A LAMBDA FUNCTION FOR THIS, IN ORDER TO HIDE THE UUID GENERATION
 *       PROCESS.
 * */ 
function uuid_generator(email, filename)
{
    //return uuidv5(email+filename+Date.now().toString(), XenkiaAWSconfig.UUID_Generation.XENKIA_NAMESPACE);

    // Lets not use the Date(). This way the UUID will be the same every time the user re-upload the file.
    return uuidv5(email+filename, XenkiaAWSconfig.UUID_Generation.XENKIA_NAMESPACE);
}


/**
   * All the Upload from related logic will go here.
   * @returns 
   */
 const UploadManagerFunc = () => {
 
    const [state, setState] = useState({ fileUrl: '', file: '', filename: ''});
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [fileSize, setFileSize] = useState({ total_size: 0, uploaded_size: 0});
    const [accountStatus, setAccountStatus] = useState({
        max_uploads_allowed:    0,
        upload_counter:         0,
        storage_space_used:     0,
        max_storage_space:      0,
        max_file_size:          0,
        expiry_date:            0
    });

    const [accountErrors, setAccountErrors] = useState({
        file_size_limit:                "no-error",
        upload_limit:                   "no-error",
        storage_limit:                  "no-error",
        file_type_error:                "no-error",
        subscription_expiry_error:      "no-error"
    });

    // Upload Button Enable/Disable.
    const [disableUploadBtn, setDisableUploadbtn] = useState(true);

    // Related to the 'Toast'.    
    const [show, setShow] = useState(true);

    // This flag marks whether the User Account Status values have been loaded from remote server.
    const [userAccountStatusLoaded, setUserAccountStatusLoaded] = useState(false);

    const [selectedFileSize, setSelectedFileSize] = useState({
        file_size:              0,
        selectedYet:            false
    });

    // This is the uploaded filename.
    let uploaded_filename = "";
  
    // Currently logged in User Identity (Cognito Identity Pool).
    let user_id = "";

    // These two variables allows us to form th QRCode on the fly (neuropac28@gmail.com, @Waterfall28 - 17-11-2020)
    let s3_base_Url = "https://www.3d-viewer.media/";
    let s3_complete_Url = "";


    // This runs once at the beginning. Source: https://stackoverflow.com/questions/53464595/how-to-use-componentwillmount-in-react-hooks
    useEffect(() => {
        
        getUserAccountStatus();         // Get User Account Status.

        //console.log(0.79*Number(accountStatus.max_uploads_allowed));
      }, []);


    
    // Get use account status.
    const getUserAccountStatus = async() =>
    {
        // Add the User to the database.
        var res = await fetch("https://oidnlpynvl.execute-api.us-east-1.amazonaws.com/prod/accountstatus", {
            method: 'POST',
            /* mode: 'no-cors', */
            body: JSON.stringify({
                "email":     loggedin_user_email
            })
        });

        // Get the response from the Backend.
        var response = await res.json();

        // Proceed only if the Response StatusCode=201.
        if(response.statusCode === 201)
        {
            let storage_limit, upload_limit, subscription_expiry_error;

            setAccountStatus({
                max_uploads_allowed     : response.body.response.Item.max_uploads_allowed,
                upload_counter          : response.body.response.Item.upload_counter,
                storage_space_used      : response.body.response.Item.storage_space_used,
                max_storage_space       : response.body.response.Item.max_storage_space,
                max_file_size           : response.body.response.Item.max_file_size,
                expiry_date             : response.body.response.Item.expiry_date
            });

            // Mark that the information related to User Account has been successfully loaded.
            setUserAccountStatusLoaded(true);

            // After loading the Account status, show warning to users if above 79% Upload Limit and Storage Limit has been reached.
            {
                if(Number(response.body.response.Item.upload_counter) > (0.79*Number(response.body.response.Item.max_uploads_allowed))) upload_limit="warning";
                else upload_limit="no-error";

                if(Number(response.body.response.Item.storage_space_used) > (0.79*Number(response.body.response.Item.max_storage_space))) storage_limit= "warning";
                else storage_limit= "no-error";

                if(Date.now() < accountStatus.expiry_date) subscription_expiry_error = "warning";
                else subscription_expiry_error = "no-error";

                // Update the state.
                setAccountErrors({upload_limit: upload_limit, storage_limit: storage_limit, subscription_expiry_error: subscription_expiry_error});
            }
        }
    }



    // This function will increase the local and the remote upload counter.
    const increseUploadCounterRemoteDB = async() => {

        // Incease the upload counter by 1 in the remote database..
        var res = await fetch("https://oidnlpynvl.execute-api.us-east-1.amazonaws.com/prod/increaseviewcount", {
            method: 'POST',
            //mode: 'no-cors',
            body: JSON.stringify({
                "email":                loggedin_user_email,
                "field_to_update":      "upload_counter",
                "value":                1,
                "inc_dec_reset":        "inc"
            })
        })
        // .then((response) => {
        //    console.log(response);
        //});

        // Get the response from the Backend.
        var response = await res.json();
        //console.log(response);

        // Only update the Upload counter if the remote Upload counter was successfully incremented.
        if(response.statusCode === 201)
        {
            // Increment the local upload counter.
            updated_upload_counter = accountStatus.upload_counter + 1;
            setAccountStatus({...accountStatus, upload_counter: updated_upload_counter, storage_space_used: accountStatus.storage_space_used});
        }
    }

    // This function will increase the local and the remote update the used storage.
    const updateStorageSpaceUsedRemoteDB = async(updated_storage_used) => {

        // Incease the upload counter by 1 in the remote database..
        var res = await fetch("https://oidnlpynvl.execute-api.us-east-1.amazonaws.com/prod/increaseviewcount", {
            method: 'POST',
            //mode: 'no-cors',
            body: JSON.stringify({
                "email":                loggedin_user_email,
                "field_to_update":      "storage_space_used",
                "value":                updated_storage_used,
                "inc_dec_reset":        "update"
            })
        })

        // Get the response from the Backend.
        var response = await res.json();

        // Only update the Upload counter if the remote Upload counter was successfully incremented.
        if(response.statusCode === 201)
        {
            // Update the Storage Space Used value after uploading.
            setAccountStatus({...accountStatus, upload_counter: updated_upload_counter, storage_space_used: updated_storage_used});
        }
    }


    // File selection for upload.
    const handleChange = (e) => {

        let vote_of_confidence = 0;

        // Get the file name selected by the user.
        const file = e.target.files[0];
        const file_extension = file.name.split('.').pop();

        s3_complete_Url = "";

        //console.log(URL.createObjectURL(file) + " " + file.name);

        // Update the state variable.
        setState({ ...state,
            fileUrl: URL.createObjectURL(file),
            file,
            filename: file.name
        });


        // Update the selected file size and mark that a file has been selected by user for uploading.
        setSelectedFileSize({file_size:      (e.target.files[0].size/1048576).toFixed(2), selectedYet:    true});


        // Check if there is User Account Limit violations and set the appropriate flags.
        {
            // Enable the upload button if the Selected file is smaller than Maximum allowed file size for this package.
            if( Date.now() < accountStatus.expiry_date )
            {
                // Enable the Upload button as soon as a file has been selected by user for uploading.
                //setDisableUploadbtn(false);
                vote_of_confidence++;

                //console.log(Date.now(), accountStatus.expiry_date);

                accountErrors.subscription_expiry_error = "no-error";
            }
            else 
            {
                accountErrors.subscription_expiry_error = "error";

                // Notify the user about it.
                alert("Your subscription has expired. Pleased subscribe to a package to resume uploading!");
            }

            // Enable the upload button if the Selected file is smaller than Maximum allowed file size for this package.
            if( (e.target.files[0].size/1048576) < accountStatus.max_file_size )
            {
                // Enable the Upload button as soon as a file has been selected by user for uploading.
                //setDisableUploadbtn(false);
                vote_of_confidence++;

                accountErrors.file_size_limit = "no-error";
            }
            else 
            {
                accountErrors.file_size_limit = "error";

                // Notify the user about it.
                alert("Please select a file that is lower than " + accountStatus.max_file_size + " MB Or UPGRADE to increase file size.");
            }

            // Upload Count Limit check.
            if(accountStatus.upload_counter < accountStatus.max_uploads_allowed)
            {
                vote_of_confidence++;

                accountErrors.upload_limit = "no-error";
            }
            else{
                accountErrors.upload_limit = "error";

                // Notify the user about it.
                alert("Maximum upload limit of " + accountStatus.max_uploads_allowed + " reached. Please UPGRADE to increase the limit.");
            }

            //console.log(Number(accountStatus.storage_space_used) + (e.target.files[0].size/1048576));

            // Max storage size limit.
            if( (Number(accountStatus.storage_space_used) + (e.target.files[0].size/1048576)) < accountStatus.max_storage_space )
            {
                vote_of_confidence++;

                accountErrors.storage_limit = "no-error";
            }
            else{
                accountErrors.storage_limit = "error";

                alert("Uploading this file will exceed Allocated storage space of " + accountStatus.max_storage_space + " MB. Please select a smaller file Or UPGRADE to increase the limit.");
            }

            // Supported file formats.
            if( (file_extension === "glb") || (file_extension === "usdz") )
            {
                vote_of_confidence++;

                accountErrors.file_type_error = "no-error";
            }
            else{
                accountErrors.file_type_error = "error";

                //alert("Unsupported file format detected. Only *.glb and *.usdz files are currently supported.");
            }
        }


        // If all 3 limit checks passes (i.e. no User Account violations have been found), then Enable the Upload button.
        {
            if(vote_of_confidence === 5)
            {
                // Enable the Upload button as soon as a file has been selected by user for uploading.
                setDisableUploadbtn(false);
            }
            else{
                // Disable the Upload button as soon as a file has been which exceeds maximum allowed file size.
                setDisableUploadbtn(true);
            }
        }
    }


    // Get the amount of storage used by the Current Logged in user.
    const getS3BucketSize = async() => {

        await Storage.list('', { level: 'protected' })
        .then(result => {

                let total_storage_used = 0;

                result.forEach(res => {
                     // This value is in Bytes.
                    total_storage_used += res.size;
                });

                // Update storage space used in the remote database.
                updateStorageSpaceUsedRemoteDB((total_storage_used/1048576).toFixed(2));
            })
            .catch(err => console.log(err)
        );
    }


    // This function will upload file to the S3 Bucket.
    const saveFile = () => {
        
        let asset_uuid;

        // Disable the Upload button as soon as Upload button gets pressed.
        setDisableUploadbtn(true);

        // Get the User Identity. Based on this we will generate the QR Code so that the App can know in which folder it can find the models.
        // Auth.currentUserInfo().then((userInfo) => {
        //     if (userInfo) user_id = encodeURIComponent(userInfo.id);

        //     // Generate the UUID for the asset being uploaded.
        //     asset_uuid = uuid_generator(userInfo.attributes.email, state.filename);
        // });

        // Save the filename, so that this can be used to name the QRCode image.
        uploaded_filename = state.filename.split('.').slice(0, -1).join('.');


        // Upload progress: https://stackoverflow.com/questions/55075608/how-do-i-send-progress-of-s3-upload-in-aws-amplify-react-native
        Storage.put(uploaded_filename +"/" + state.filename, state.file, {
        level: 'protected',
            progressCallback(progress) { 
            var prog = parseInt(progress.loaded/progress.total*100);
            
            // Source: https://www.youtube.com/watch?v=Ti8QNiRRzOA, http://ufdjrw.blogspot.com/2019/04/how-do-i-send-progress-of-s3-upload-in.html
            //if(prog < 100){
                setUploadPercentage(prog);
                setFileSize({total_size: (progress.total/1048576).toFixed(3), uploaded_size: (progress.loaded/1048576).toFixed(3)});
            //}
            //console.log(prog + "%");
            }})
        .then( async() => {  
                s3_complete_Url = s3_base_Url + user_id + "/" + state.filename
                //console.log("Successfully savedfile: " + s3_complete_Url)

                // Add info related to this file to the DB.
                {
                    // Add the User to the database.
                    var res = await fetch(XenkiaAWSconfig.AssetManager.addasset, {
                        method: 'POST',
                        /* mode: 'no-cors', */
                        body: JSON.stringify({
                            email:              loggedin_user_email,
                            request:            "create",
                            asset_uuid:         asset_uuid,
                            fileName:           state.filename.split('.').slice(0, -1).join('.'),
                            fileFormat:         state.filename.split('.').pop(),
                            S3Url:              XenkiaAWSconfig.s3.BUCKET_ACCELERATED + user_id + '/' + state.filename.split('.').slice(0, -1).join('.') + '/',
                            fileSizeInMB:       selectedFileSize.file_size,
                            glbFileUploaded:    false,
                            usdzFileUploaded:   false,
                            posterUploaded:     false,
                            qrCodeUploaded:     false
                        })
                    });

                    // Get the response from the Backend.
                    var response = await res.json();
                    //console.log(response);
                }

                // RESET.
                setState({...state, fileUrl: '', file:'', filename: ''});

                // Get the amount of storage used by the Current Logged in user.
                getS3BucketSize();

                // Update the local and remote upload counter.
                increseUploadCounterRemoteDB();

                alert("Success: File uploaded!");
                //UploadConfirmationToast();

                // RESET the Percentage Uploaded value.
                //setState({...state, percentage: 0});
                setUploadPercentage(0);
                setFileSize({...fileSize, uploaded_size: 0});
                setSelectedFileSize({...selectedFileSize, file_size: 0});
            })
        .catch(err=>{
            console.log('Error uploading file!', err)
            s3_complete_Url = ""
        })  
    }

    
    function UploadConfirmationToast(){

        return (
            <Col xs={6}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Header>
                    {/* <img
                        src="holder.js/20x20?text=%20"
                        className="rounded mr-2"
                        alt=""
                    /> */}
                    <strong className="mr-auto">Bootstrap</strong>
                    {/* <small>11 mins ago</small> */}
                    </Toast.Header>
                    <Toast.Body>Upload successful!</Toast.Body>
                </Toast>
            </Col>
        );
      }

    /* // This converts the QR code to image.
    const changeCanvasToPic = () => {
        const canvas = document.getElementById("qrCode");
        const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "QR_" + uploaded_filename + ".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink); 
    } */


    return (
        <>
            <br/> <br/>            

            <Row style={{alignItems: 'center', minWidth: '90%'}}>
                <Col>
                    <Card
                        bg='light' border='info' style={{marginLeft: 'auto', marginRight: 'auto', width: '90%', minWidth: '400px'}}
                    >
                        <Card.Header className="upload-subheader" style={{textAlign: 'justify', color: 'black'}}> <b>Upload Panel</b></Card.Header>
                        <Card.Body >

                            <div style={{textAlign: 'right'}}>
                                {userAccountStatusLoaded === true
                                    ?  
                                        <>
                                            {(accountErrors.upload_limit === "warning")
                                            ?
                                                <p className="upload-subtext"> {accountStatus.upload_counter} / {accountStatus.max_uploads_allowed} <i>Uploads per month</i> <Image height='16px' src={warning}/></p>
                                            :
                                                <>
                                                    {(accountErrors.upload_limit === "no-error")
                                                    ?
                                                        <p className="upload-subtext"> {accountStatus.upload_counter} / {accountStatus.max_uploads_allowed} <i>Uploads per month</i> <Image height='16px' src={check}/></p>
                                                    :
                                                        <p className="upload-subtext"> <u style={{color: 'red'}}>{accountStatus.upload_counter}</u> / {accountStatus.max_uploads_allowed} <i>Uploads per month</i> <Image height='16px' src={error}/></p>
                                                    }
                                                </>
                                            }

                                            {(accountErrors.storage_limit === "warning")
                                            ?
                                                <p className="upload-subtext"> {accountStatus.storage_space_used} MB / {accountStatus.max_storage_space} MB <i>Storage space used</i> <Image height='16px' src={warning}/></p>
                                            :
                                                <>
                                                    {(accountErrors.storage_limit === "no-error")
                                                    ?
                                                        <p className="upload-subtext"> {accountStatus.storage_space_used} MB / {accountStatus.max_storage_space} MB <i>Storage space used</i> <Image height='16px' src={check}/></p>
                                                    :
                                                        <p className="upload-subtext"> <u style={{color: 'red'}}>{accountStatus.storage_space_used} MB</u> / {accountStatus.max_storage_space} MB <i>Storage space used</i> <Image height='16px' src={error}/></p>
                                                    }
                                                </>
                                            }
                                        </>
                                    :  <Spinner animation="border" variant="danger" />
                                }
                            </div>

                            <br/> <br/>
                            { <ProgressBar className="Progress-bar" variant="info" style={{marginLeft: 'auto', marginRight: 'auto', width: 'auto'}} now={uploadPercentage} label={`${uploadPercentage}%`} />}
                            <p className="upload-subtext">  {fileSize.uploaded_size}MB / {selectedFileSize.file_size}MB uploaded </p>
                            
                            <br/> <br/>
                    
                            <input  
                                className="btn-outline-success btn-lg"
                                style={{
                                    width: "100%",
                                    paddingLeft: "10px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    paddingRight: "10px",                
                                    border: "2px solid grey",
                                    outline: "0px",
                                    size: 30,
                                    marginLeft: 'auto', 
                                    marginRight: 'auto'
                                }}
                                type='file' accept=".glb, .usdz" onChange={handleChange}
                            />
                            {selectedFileSize.selectedYet === true
                                ?   <>  
                                        {accountErrors.file_size_limit === "no-error"
                                        ?   <p className="upload-subtext" style={{textAlign: 'left', marginLeft: '20px'}}>   <b>File size: </b> {selectedFileSize.file_size} MB / {accountStatus.max_file_size} MB allowed <Image height='16px' src={check}/></p>
                                        :   <p className="upload-subtext" style={{textAlign: 'left', marginLeft: '20px'}}>   <b>File size: </b> <u style={{color: 'red'}}>{selectedFileSize.file_size} MB</u> / {accountStatus.max_file_size} MB allowed <Image height='16px' src={error}/></p>
                                        }

                                        {accountErrors.file_type_error === "no-error"
                                        ?   <> </>
                                        :   <p className="upload-subtext" style={{textAlign: 'left', marginLeft: '20px'}}>   <b style={{color: 'red'}}>*Unsupported file format detected. Only *.glb and *.usdz files are currently supported.</b> <Image height='16px' src={error}/></p>    
                                        }
                                    </>
                                :  <></>
                            }
                            <br/> <br/>
                            <button className="btn btn-warning btn-lg" disabled={disableUploadBtn} onClick={saveFile}> <b>Upload</b> </button>
                        </Card.Body>
                    </Card>                        
                </Col>

                <br/> <br/>

                <Col style={{marginTop: '5%'}}>
                    <Card bg='secondary' style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '90%', minWidth: '400px'}}>
                        <Card.Header className="upload-subheader" style={{textAlign: 'justify', color: 'white'}}> <b>Guidelines</b></Card.Header>
                        <Card.Body style={{justifyContent: 'left', alignItems: 'left'}}>
                            {//<h4 className="upload-subheader" style={{textAlign: 'justify', color: '#0693E3', marginLeft: '5%', marginRight: '5%'}}> Instructions: </h4>
                            }
                            <ul>
                                <li className="upload-subtext" style={{textAlign: 'justify', marginRight: '5%', color: 'white'}}> <i style={{color: 'orange'}}> <b>Do not</b></i>  <i>upload folder or *.zip files.</i>  </li>
                                <li className="upload-subtext" style={{textAlign: 'justify', marginRight: '5%', color: 'white'}}> <i>Only <a href="https://en.wikipedia.org/wiki/GlTF#GLB" target="_blank" rel="noreferrer">*.glb</a> and <a href="https://graphics.pixar.com/usd/docs/Usdz-File-Format-Specification.html" target="_blank" rel="noreferrer">*.usdz</a> models are currently supported.</i> </li>
                                <li className="upload-subtext" style={{textAlign: 'justify', marginRight: '5%', color: 'white'}}> <i>*.glb model is needed for the browser based 3d model viewer in all platforms. Additionally it is needed for AR in the Android platform.</i> </li>
                                <li className="upload-subtext" style={{textAlign: 'justify', marginRight: '5%', color: 'white'}}> <i>*.usdz is needed exclusively for AR in the iOS platform and so uploading it is <u>not mandatory</u>.</i> </li>
                                <li className="upload-subtext" style={{textAlign: 'justify', marginRight: '5%', color: 'white'}}> <i>Both .glb and *.usdz files need to have the same file name.</i> </li>
                                <li className="upload-subtext" style={{textAlign: 'justify', marginRight: '5%', color: 'white'}}> <i>Recommended number of polygons is less than 100,000, and number of components less than 500.</i> </li>
                                <li className="upload-subtext" style={{textAlign: 'justify', marginRight: '5%', color: 'white'}}> <i>3d models with animation is supported.</i> </li>
                                <li className="upload-subtext" style={{textAlign: 'justify', marginRight: '5%', color: 'white'}}> <i>File names cannot have any whitespaces in it.</i> </li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>

                <br/>
            </Row>
        </>
    );
}


class UploadModel extends Component {

    state = {login_status: 2};

    UNSAFE_componentWillMount(){

        // Check if any user is currently logged in. If yes, only then allow them to upload.
        // Auth.currentUserInfo().then((userInfo) => {
        //     //console.log(userInfo);
        //     //console.log(userInfo.attributes.email);

        //     // Store the currently logged in users email address for use in this page.
        //     loggedin_user_email = userInfo.attributes.email;

        //     // Set the Login Status.
        //     if (userInfo) 
        //     {
        //         //this.user_id = encodeURIComponent(userInfo.attributes.email);
        //         this.setState({login_status: 1});
        //     }
        //     else{
        //         this.setState({login_status: 0});
        //     }
        // });
        
    }


    render() {
        //const {uploadPercentage} = state.percentage;

        if (this.state.login_status === 1)
        {
            return(
                <header className="upload-header">
                    <UploadManagerFunc/>
                </header>
            )
        }
        else if (this.state.login_status === 0){
            return(
                <h1 className="upload-login-error"> Please Login to Upload 3d Models </h1>
            )
        }
        else{
            return(
                <h1 className="upload-login-error"> Please wait </h1>
            )
        }
    }    
}


export default UploadModel;
