
import awsconfig from './aws-exports';

/* const aws_configuration = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:29f3560a-46a8-40eb-b42a-d17e2018edf6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_wfd1MzKBI",
    "aws_user_pools_web_client_id": "2eqrt1bt20j23gk7qmosb73ro",
    "oauth": {},
    "aws_user_files_s3_bucket": "xenkia55844-vscode",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default aws_configuration; */

const XenkiaAWSconfig = {
    s3: {
      REGION:                       "us-east-1",
      BUCKET:                       "https://xenkiaassetbucket.s3.amazonaws.com/protected/",
      BUCKET_ACCELERATED:           "https://xenkiaassetbucket.s3-accelerate.dualstack.amazonaws.com/protected/"
    },
    cognito: {
      REGION:                       "us-east-1",
      USER_POOL_ID:                 "us-east-1_wfd1MzKBI",
      APP_CLIENT_ID:                "7pj01r9vqsl6oskdj3307bcjo9",
      IDENTITY_POOL_ID:             "us-east-1:a73f9563-7472-44d3-a08d-e4209db1763c"
    },
    newsletterSubscription: {
      apiUrl:                       'https://49eu2b04bh.execute-api.us-east-1.amazonaws.com/prod/user',
      unsubscribe:                  'https://49eu2b04bh.execute-api.us-east-1.amazonaws.com/prod/unsubscribe'
    },
    registration:{
      packageSubscriptionUrl:       'https://tx1ckwg0hi.execute-api.us-east-1.amazonaws.com/prod/register',
      checkIfUserREgistered:        'https://tx1ckwg0hi.execute-api.us-east-1.amazonaws.com/prod/checkifregistered'
    },
    UUID_Generation:{
      // For UUID Generator (Have to hide it later.).
      XENKIA_NAMESPACE:             '0b62e9eb-a931-43b5-a20e-823043939846'
    },
    AssetManager:{
      addasset:                     'https://17vpdulb4e.execute-api.us-east-1.amazonaws.com/prod/addasset',
      queryasset:                   'https://17vpdulb4e.execute-api.us-east-1.amazonaws.com/prod/queryasset',
      removeasset:                  'https://17vpdulb4e.execute-api.us-east-1.amazonaws.com/prod/removeasset',
      updateSettings:               'https://17vpdulb4e.execute-api.us-east-1.amazonaws.com/prod/updatesettings',
      updateFriendlyAssetName:      'https://17vpdulb4e.execute-api.us-east-1.amazonaws.com/prod/updatefriendlyassetname'
    },
    viewer3d:{
      // url:                          'https://www.3d-viewer.media/'
      url:                          'https://viewer.xenkia.com/'
    },
    packages:{
      url:                          'https://tx1ckwg0hi.execute-api.us-east-1.amazonaws.com/prod/getpackageinfo'
    },
    discount:{
      verifyCoupon:                 'https://dyoxtibws7.execute-api.us-east-1.amazonaws.com/prod/discounttoken'
    }
  };


  export default XenkiaAWSconfig;

