
import {React, Component, useState, useEffect} from "react";
import { Button, Container, Row, Col, Modal, Image, Card, OverlayTrigger, Tooltip, Form, Badge, Jumbotron, InputGroup, FormControl, Alert, Spinner} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import '../../styling/uploadFile.css';


import config from '../../config'

import warning from '../../icons/warning.png'


/**
 * This function will allow the user to delete existing Asset from Asset Manager.
 * @param {*} props 
 * @returns 
 */
export default function DeleteAssetModal(props) {


    return (

      <Modal
          {...props}
          aria-labelledby ="contained-modal-title-vcenter"
          centered
          //keyboard={false}
        >
        <Modal.Header>
          <Modal.Title style={{textAlign: 'justify', fontSize: '1.2rem'}}> <Image height='24px' src={warning}/>&nbsp; Are you sure that want to delete this asset? </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <p className="foot-note" style={{textAlign: 'justify', color: "black", fontSize: '1rem'}}> Clicking Confirm will permanently delete the Asset from your library. </p>

          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button id="create_button"  variant='danger'  onClick={props.onConfirm}>  <b>Confirm</b> </Button>
            <Button id="close_button"   variant='dark'    onClick={props.onHide}>     <b>Cancel</b> </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

