export const pricing_plan_list = [
    [   {
            "plan":                         "Starter",
            "cost":                         "FREE",
            "yearly_cost":                  "FREE",
            "monthly_cost_in_usd":          "0",
            "yearly_cost_in_usd":           "0",
            "good_for":                     "<i>This free starter package is good for hobbysist and maker community, or anyone who wants to get familialized with our platform.</i>",
            "max_num_of_uploads":           "5",
            "max_num_of_views":             "50",
            "max_file_size":                "50",
            "max_storage_space":            "1",
            "feature1":                     "- Upto <b> 5 Uploads/Month </b> ",
            "feature2":                     "- Upto <b> 50 Views or Downloads/Month<b style={{color: 'red'}}> ***</b></b>",
            "feature3":                     "- Upto <b> 50 MB </b> per 3d model",
            "feature4":                     "- <b> 1 GB </b> storage space",
            "feature5":                     "- Interactive <b> 3d Model viewer </b> with <b> AR capability </b>",
            "feature6":                     "- <b>Asset Management</b> Dashboard",
            "feature7":                     "- Secured <b>Cloud server</b>",
            "feature8":                     "- <b>Unlimited</b> bandwidth",
            "feature9":                     "- <del><b>Personalized</b> 3D viewer with logo</del>",
            "feature10":                    "- <del> Remote <b>Technical Support</b></del>"
        },

        {
            "plan":                         "Personal",
            "cost":                         "$8 USD / month",
            "yearly_cost":                  "<del>$96</del> $67 USD / year",
            "monthly_cost_in_usd":          "8",
            "yearly_cost_in_usd":           "67",
            "good_for":                     "<i>Suitable for 3d Artists, Product designers, Architects and Engineers who often share their work with their clients or showcase in their portfolio.</i>",
            "max_num_of_uploads":           "30",
            "max_num_of_views":             "1000",
            "max_file_size":                "50",
            "max_storage_space":            "3",
            "feature1":                     "- Upto <b> 30 Uploads/Month </b> ",
            "feature2":                     "- Upto <b> 1000 Views or Downloads/Month<b style={{color: 'red'}}> ***</b></b>",
            "feature3":                     "- Upto <b> 50 MB </b> per 3d model",
            "feature4":                     "- <b> 3 GB </b> storage space",
            "feature5":                     "- Interactive <b> 3d Model viewer </b> with <b> AR capability </b>",
            "feature6":                     "- <b>Asset Management</b> Dashboard",
            "feature7":                     "- Secured <b>Cloud server</b>",
            "feature8":                     "- <b>Unlimited</b> bandwidth",
            "feature9":                     "- <del><b>Personalized</b> 3D viewer with logo</del>",
            "feature10":                    "- <del> Remote <b>Technical Support</b></del>"
        },

        {
            "plan":                         "Professional",
            "cost":                         "$43 USD / month",
            "yearly_cost":                  "<del>$516</del> $361 USD / year",
            "monthly_cost_in_usd":          "43",
            "yearly_cost_in_usd":           "361",
            "good_for":                     "<i>Professionals who requires large number of 3d models to be served through our platform can get benefitted from the large storage space and higher Views/Month quota. </i>",
            "max_num_of_uploads":           "150",
            "max_num_of_views":             "3000",
            "max_file_size":                "100",
            "max_storage_space":            "30",
            "feature1":                     "- Upto <b> 150 Uploads/Month </b>",
            "feature2":                     "- Upto <b> 3000 Views or Downloads/Month<b style={{color: 'red'}}> ***</b></b>",
            "feature3":                     "- Upto <b> 100 MB </b> per 3d model",
            "feature4":                     "- <b> 30 GB </b> storage space",
            "feature5":                     "- Interactive <b> 3d Model viewer </b> with <b> AR capability </b>",
            "feature6":                     "- <b>Asset Management</b> Dashboard",
            "feature7":                     "- Secured <b>Cloud server</b>",
            "feature8":                     "- <b>Unlimited</b> bandwidth",
            "feature9":                     "- <b>Personalized</b> 3D viewer with logo",
            "feature10":                    "- Remote <b>Technical Support</b>"
        }
    ],



    [
        {
            "plan":                         "Small Business",
            "cost":                         "$141 USD / month",
            "yearly_cost":                  "<del>$1,692</del> $1,184 USD / year",
            "monthly_cost_in_usd":          "141",
            "yearly_cost_in_usd":           "1184",
            "good_for":                     "<i>This package is most suitable for small retails stores and boutique industry.</i>",
            "max_num_of_uploads":           "150",
            "max_num_of_views":             "10000",
            "max_file_size":                "100",
            "max_storage_space":            "30",
            "feature1":                     "- Upto <b> 150 Uploads/Month </b> ",
            "feature2":                     "- Upto <b> 10,000 Views or Downloads/Month<b style={{color: 'red'}}> ***</b></b>",
            "feature3":                     "- Upto <b> 100 MB </b> per 3d model",
            "feature4":                     "- <b> 30 GB </b> storage space",
            "feature5":                     "- Interactive <b> 3d Model viewer </b> with <b> AR capability </b>",
            "feature6":                     "- <b>Asset Management</b> Dashboard",
            "feature7":                     "- Secured <b>Cloud server</b>",
            "feature8":                     "- <b>Unlimited</b> bandwidth",
            "feature9":                     "- <b>Personalized</b> 3D viewer with logo",
            "feature10":                    "- Remote <b>Technical Support</b>"
        },
        
        {
            "plan":                         "Medium Business",
            "cost":                         "$539 USD / month",
            "yearly_cost":                  "<del>$6,468</del> $4,527 USD / year",
            "monthly_cost_in_usd":          "539",
            "yearly_cost_in_usd":           "4527",
            "good_for":                     "<i>Any business with medium customer base will be benefitted from this package.</i>",
            "max_num_of_uploads":           "350",
            "max_num_of_views":             "50000",
            "max_file_size":                "100",
            "max_storage_space":            "69",
            "feature1":                     "- Upto <b> 350 Uploads/Month </b> ",
            "feature2":                     "- Upto <b> 50,000 Views or Downloads/Month<b style={{color: 'red'}}> ***</b></b>",
            "feature3":                     "- Upto <b> 100 MB </b> per 3d model",
            "feature4":                     "- <b> 69 GB </b> storage space",
            "feature5":                     "- Interactive <b> 3d Model viewer </b> with <b> AR capability </b>",
            "feature6":                     "- <b>Asset Management</b> Dashboard",
            "feature7":                     "- Secured <b>Cloud server</b>",
            "feature8":                     "- <b>Unlimited</b> bandwidth",
            "feature9":                     "- <b>Personalized</b> 3D viewer with logo",
            "feature10":                    "- Remote <b>Technical Support</b>"
        },

        {
            "plan":                         "Large Business",
            "cost":                         "$2101 USD / month",
            "yearly_cost":                  "<del>$25,212</del> $17,648 USD / year",
            "monthly_cost_in_usd":          "2101",
            "yearly_cost_in_usd":           "17648",
            "good_for":                     "<i>This packages is suitable for large companies with wider varieties of products and a huge customer base.</i>",
            "max_num_of_uploads":           "1000",
            "max_num_of_views":             "150000",
            "max_file_size":                "100",
            "max_storage_space":            "196",
            "feature1":                     "- Upto <b> 1000 Uploads/Month </b> ",
            "feature2":                     "- Upto <b> 150,000 Views or Downloads/Month<b style={{color: 'red'}}> ***</b></b>",
            "feature3":                     "- Upto <b> 100 MB </b> per 3d model",
            "feature4":                     "- <b> 196 GB </b> storage space",
            "feature5":                     "- Interactive <b> 3d Model viewer </b> with <b> AR capability </b>",
            "feature6":                     "- <b>Asset Management</b> Dashboard",
            "feature7":                     "- Secured <b>Cloud server</b>",
            "feature8":                     "- <b>Unlimited</b> bandwidth",
            "feature9":                     "- <b>Personalized</b> 3D viewer with logo",
            "feature10":                    "- Remote <b>Technical Support</b>"
        }
    ]
]