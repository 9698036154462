export const demo_items_list = [
    [   {
            "item_name":                    "Lounge Barstool",
            "src_name":                     "Source",
            "src_address":                  "https://sketchfab.com/3d-models/lounge-barstool-1664efa8decf449d92aa0c2227ea0492",
            "model_address":                "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/lounge_barstool.glb",
            "viewer_address":               "https://viewer.xenkia.com/#/&uuid=8fe618fc-11cf-5df5-95b1-a5467d080e0f",
            "poster_address":               "https://xenkias3bucket202528-dev.s3-accelerate.dualstack.amazonaws.com/protected/us-east-1%3A254b07e0-880e-4705-9d38-be5f2884c3a4/Lounge_Barstool/POSTER_Lounge_Barstool.png",
            "qr_code_address":              "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/qr/qr_lounge_barstool.png",
            "youtube_video_address":        "https://www.youtube.com/watch?v=7YajaHP50SQ",
            "exposure":                     "3",
            "animated":                     "1",
            "camera_field_of_view":         "45deg",
            "simple_hashtag":               "#Animated, #Furniture, #Stool",
            "uuid":                         "8fe618fc-11cf-5df5-95b1-a5467d080e0f"
        },

        {
            "item_name":                    "Generator",
            "src_name":                     "Source",
            "src_address":                  "https://sketchfab.com/3d-models/generator-firman-3650-42204b2b8ee64e50b6772fd2a427916b",
            "model_address":                "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/generator.glb",
            "viewer_address":               "https://viewer.xenkia.com/#/&uuid=e8fe4c2f-e273-55d0-9e93-2fd787262243",
            "poster_address":               "https://xenkias3bucket202528-dev.s3-accelerate.dualstack.amazonaws.com/protected/us-east-1%3A254b07e0-880e-4705-9d38-be5f2884c3a4/Generator/POSTER_Generator.png",
            "qr_code_address":              "https://xenkia55844-vscode.s3.amazonaws.com/3d-models/qr/qr_generator.png",
            "youtube_video_address":        "https://www.youtube.com/watch?v=xBRGSu3bQy8&feature=youtu.be",
            "exposure":                     "3",
            "animated":                     "0",
            "hashtags":                     ["#animated", "#furniture"],
            "camera_field_of_view":         "40deg",
            "simple_hashtag":               "#Mechanical",
            "uuid":                         "e8fe4c2f-e273-55d0-9e93-2fd787262243"
        },

        {
            "item_name":                    "Pneumatic Engine",
            "src_name":                     "Source",
            "src_address":                  "https://sketchfab.com/3d-models/3d-printable-radial-pneumatic-engine-3cbddbecd6c5462391e9936a3ccd7d32",
            "model_address":                "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/pneumatic_engine.glb",
            "viewer_address":               "https://viewer.xenkia.com/#/&uuid=dbcccafa-5e2d-56a9-a34f-479a1da7d4b6",
            "poster_address":               "https://xenkias3bucket202528-dev.s3-accelerate.dualstack.amazonaws.com/protected/us-east-1%3A254b07e0-880e-4705-9d38-be5f2884c3a4/Pneumatic_Engine/POSTER_Pneumatic_Engine.png",
            "qr_code_address":              "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/qr/qr_pneumatic_engine.png",
            "youtube_video_address":        "https://www.youtube.com/watch?v=tKt_Uu9Cr6U",
            "exposure":                     "2",
            "animated":                     "1",
            "hashtags":                     ["#animated", "#furniture"],
            "camera_field_of_view":         "50deg",
            "simple_hashtag":               "#Engineering, #Education, #3dprinting",
            "uuid":                         "dbcccafa-5e2d-56a9-a34f-479a1da7d4b6"
        },

        {
            "item_name":                    "Leather Sofa",
            "src_name":                     "Source",
            "src_address":                  "https://sketchfab.com/3d-models/leather-chair-1a4b8572f8b04723898c9d16351c3a4e",
            "model_address":                "https://xenkiaassetbucket.s3-accelerate.amazonaws.com/protected/us-east-1%3A008a3f2b-57b4-4a55-ab16-c7b4c2c01c85/asset/4a3adbbf-a79c-5b9d-ac63-327866beba58/4a3adbbf-a79c-5b9d-ac63-327866beba58.glb",
            "viewer_address":               "https://viewer.xenkia.com/#/&uuid=4a3adbbf-a79c-5b9d-ac63-327866beba58",
            "poster_address":               "https://xenkias3bucket202528-dev.s3-accelerate.dualstack.amazonaws.com/protected/us-east-1%3A254b07e0-880e-4705-9d38-be5f2884c3a4/Black_Leather_Chair/POSTER_Black_Leather_Chair.png",
            "qr_code_address":              "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/qr/qr_black-leather-chair.png",
            "youtube_video_address":        "https://www.youtube.com/watch?v=rdz3MHebvpE",
            "exposure":                     "3",
            "animated":                     "0",
            "hashtags":                     ["#animated", "#furniture"],
            "camera_field_of_view":         "45deg",
            "simple_hashtag":               "#Furniture, #Leather",
            "uuid":                         "4a3adbbf-a79c-5b9d-ac63-327866beba58"
        }
    ],



    [
        {
            "item_name":                    "Modern Chair",
            "src_name":                     "Source",
            "src_address":                  "https://sketchfab.com/3d-models/modern-chair-2f982d1d80b64da1a81d32498897d599",
            "model_address":                "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/modern_chair.glb",
            "viewer_address":               "https://viewer.xenkia.com/#/params=1&uuid=10b19017-aa05-50ca-89d2-4764ced2ceaf",
            "poster_address":               "https://xenkias3bucket202528-dev.s3-accelerate.dualstack.amazonaws.com/protected/us-east-1%3A254b07e0-880e-4705-9d38-be5f2884c3a4/Modern_Chair/POSTER_Modern_Chair.png",
            "qr_code_address":              "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/qr/qr_modern_chair.png",
            "youtube_video_address":        "https://www.youtube.com/watch?v=MmnZXvGmnPw&feature=youtu.be",
            "exposure":                     "2",
            "animated":                     "0",
            "hashtags":                     ["#animated", "#furniture"],
            "camera_field_of_view":         "45deg",
            "simple_hashtag":               "#Furniture, #Office",
            "uuid":                         "990efcde-6074-5d86-aeb2-801f37e99f45"
        },

        {
            "item_name":                    "Fabrics Chair",
            "src_name":                     "Source",
            "src_address":                  "https://sketchfab.com/3d-models/dining-chair-241120-a5aa4613bc5b46baba4d9254d6c9c038",
            "model_address":                "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/chair.glb",
            "viewer_address":               "https://viewer.xenkia.com/#/params=1&uuid=10g165017-aa05-20ca-89d2-4764ced2ceaf",
            "poster_address":               "https://xenkias3bucket202528-dev.s3-accelerate.dualstack.amazonaws.com/protected/us-east-1%3A254b07e0-880e-4705-9d38-be5f2884c3a4/Chair/POSTER_Chair.png",
            "qr_code_address":              "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/qr/qr_chair.png",
            "youtube_video_address":        "https://www.youtube.com/watch?v=EZlhlTGfvz8",
            "exposure":                     "3",
            "animated":                     "0",
            "hashtags":                     ["#animated", "#furniture"],
            "camera_field_of_view":         "40deg",
            "simple_hashtag":               "#Furniture, #Chair",
            "uuid":                         "11ae113b-10e0-52fa-b093-09b38b591b98"
        },

        {
            "item_name":                    "Dining Table",
            "src_name":                     "Source",
            "src_address":                  "https://sketchfab.com/3d-models/simple-dining-table-a6deba91a7f9435082369e33f8db0dd6",
            "model_address":                "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/dining_table.glb",
            "viewer_address":               "https://viewer.xenkia.com/#/params=1&uuid=10g165017-aa05-30ca-89d2-4764ced2ceaf",
            "poster_address":               "https://xenkias3bucket202528-dev.s3-accelerate.dualstack.amazonaws.com/protected/us-east-1%3A254b07e0-880e-4705-9d38-be5f2884c3a4/Dining_Table/POSTER_Dining_Table.png",
            "qr_code_address":              "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/qr/qr_dining-table.png",
            "youtube_video_address":        "https://www.youtube.com/watch?v=EZlhlTGfvz8",
            "exposure":                     "2",
            "animated":                     "0",
            "hashtags":                     ["#animated", "#furniture"],
            "camera_field_of_view":         "35deg",
            "simple_hashtag":               "#Furniture, #Dining",
            "uuid":                         "66a32595-8987-5a6a-8f8d-3241c47a5ad7"
        },
        
        {
            "item_name":                    "Residence",
            "src_name":                     "Source",
            "src_address":                  "https://sketchfab.com/3d-models/residence-exported-from-unreal-engine-573cb74c24654a07bf802be88ba6b8e6",
            "model_address":                "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/Residence.glb",
            "viewer_address":               "https://viewer.xenkia.com/#/params=1&uuid=10g165017-aa05-40ca-89d2-4764ced2ceaf",
            "poster_address":               "https://xenkias3bucket202528-dev.s3-accelerate.dualstack.amazonaws.com/protected/us-east-1%3A254b07e0-880e-4705-9d38-be5f2884c3a4/Residence/POSTER_Residence.png",
            "qr_code_address":              "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/qr/qr_residence.png",
            "youtube_video_address":        "https://www.youtube.com/watch?v=xBRGSu3bQy8&feature=youtu.be",
            "exposure":                     "2",
            "animated":                     "0",
            "hashtags":                     ["#animated", "#furniture"],
            "camera_field_of_view":         "20deg",
            "simple_hashtag":               "#Architect, #Realestate, #Proptech",
            "uuid":                         "dba1f505-ea17-5ed3-bed6-b199bdd4a013"
        }
    ],

    [
        {
            "item_name":                    "Vintage Camera",
            "src_name":                     "Source",
            "src_address":                  "https://sketchfab.com/3d-models/om-1-f6431dc1670b46b295e57304ed02f654",
            "model_address":                "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/VintageCamera.glb",
            "viewer_address":               "https://viewer.xenkia.com/#/params=1&uuid=10g165017-aa05-60ca-89d2-4764ced2ceaf",
            "poster_address":               "https://xenkias3bucket202528-dev.s3-accelerate.dualstack.amazonaws.com/protected/us-east-1%3A254b07e0-880e-4705-9d38-be5f2884c3a4/Vintage_Camera/POSTER_Vintage_Camera.png",
            "qr_code_address":              "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/qr/qr_vintage-camera.png",
            "youtube_video_address":        "https://www.youtube.com/watch?v=xBRGSu3bQy8&feature=youtu.be",
            "exposure":                     "2",
            "animated":                     "0",
            "hashtags":                     ["#animated", "#furniture"],
            "camera_field_of_view":         "35deg",
            "simple_hashtag":               "#Electronics, #Camera",
            "uuid":                         "89ab42b3-70d3-5a5d-98e3-c1d4bac9ba7f"
        },
        
        {
            "item_name":                    "Street Bike",
            "src_name":                     "Source",
            "src_address":                  "https://sketchfab.com/3d-models/three-cylinder-naked-street-bike-0897a975845647919728095d117a6255",
            "model_address":                "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/three_cylinder_naked_street_bike.glb",
            "viewer_address":               "https://viewer.xenkia.com/#/params=1&uuid=10g165017-aa05-70ca-89d2-4764ced2ceaf",
            "poster_address":               "https://xenkias3bucket202528-dev.s3-accelerate.dualstack.amazonaws.com/protected/us-east-1%3A254b07e0-880e-4705-9d38-be5f2884c3a4/Street_Bike/POSTER_Street_Bike.png",
            "qr_code_address":              "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/qr/qr_street-bike.png",
            "youtube_video_address":        "https://www.youtube.com/watch?v=EZlhlTGfvz8",
            "exposure":                     "3",
            "animated":                     "0",
            "hashtags":                     ["#animated", "#furniture"],
            "camera_field_of_view":         "35deg",
            "simple_hashtag":               "#Mechanical, #Transport, #Bike",
            "uuid":                         "44233495-5aa2-5b49-857f-5f6b37424423"
        },

        {
            "item_name":                    "Robotic Arm",
            "src_name":                     "Source",
            "src_address":                  "https://sketchfab.com/3d-models/robotic-arm-8b6996825f634fcd8aed2c0d8273a27c",
            "model_address":                "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/robotic-arm.glb",
            "viewer_address":               "https://viewer.xenkia.com/#/params=1&uuid=10g165017-aa05-80ca-89d2-4764ced2ceaf",
            "poster_address":               "https://xenkias3bucket202528-dev.s3-accelerate.dualstack.amazonaws.com/protected/us-east-1%3A254b07e0-880e-4705-9d38-be5f2884c3a4/Robotic_Arm/POSTER_Robotic_Arm.png",
            "qr_code_address":              "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/qr/qr_robotic-arm.png",
            "youtube_video_address":        "https://www.youtube.com/watch?v=EZlhlTGfvz8",
            "exposure":                     "1",
            "animated":                     "1",
            "hashtags":                     ["#animated", "#furniture"],
            "camera_field_of_view":         "45deg",
            "simple_hashtag":               "#Animated, #Robotics, #Industry",
            "uuid":                         "c0429c50-1bb3-5744-8d8a-4724f0bd2519"
        },

        {
            "item_name":                    "Timex Expedition",
            "src_name":                     "Source",
            "src_address":                  "https://mobel-copenhagen.com/en/product/lili-lounge-table",
            "model_address":                "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/Mobel-LILI_lounge_table_90cm.glb",
            "viewer_address":               "https://viewer.xenkia.com/#/params=1&uuid=10g165017-aa05-90ca-89d2-4764ced2ceaf",
            "poster_address":               "https://xenkias3bucket202528-dev.s3-accelerate.dualstack.amazonaws.com/protected/us-east-1%3A254b07e0-880e-4705-9d38-be5f2884c3a4/Lili_Lounge_Table/POSTER_Lili_Lounge_Table.png",
            "qr_code_address":              "https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/qr/qr_lili-lounge-table.png",
            "youtube_video_address":        "https://www.youtube.com/watch?v=xBRGSu3bQy8&feature=youtu.be",
            "exposure":                     "3",
            "animated":                     "0",
            "hashtags":                     ["#animated", "#furniture"],
            "camera_field_of_view":         "45deg",
            "simple_hashtag":               "#Furniture, #Interior",
            "uuid":                         "5ede3865-10df-5ac7-8490-e454bd110a2b"
        }
    ]
]