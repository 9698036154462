/* eslint-disable no-lone-blocks */

import {React, Component, useState, useEffect} from "react";
import { Button, Container, Row, Col, Modal, Image, Card, OverlayTrigger, Tooltip, Form, DropdownButton, Dropdown, Badge, InputGroup, FormControl, Alert, Toast, ToastContainer, Spinner, ButtonGroup, Accordion} from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.min.css';


import {s3Delete, s3Upload, __s3FileStreamDownload} from '../../helper_classes/AWSS3'

import hexToRGBA from '../../helper_classes/hexToRGBA'

import styled from 'styled-components';
// import { Auth, Storage } from 'aws-amplify';
// import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import * as QrCode from 'qrcode.react'

import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import config from '../../config'


import copy from "copy-to-clipboard";

import Expire from "react-expire";

import canvasToBlob from 'async-canvas-to-blob';

//import fileGlb from '../black_leather_chair.gltf' // GLTF FILE
//import no_image from './no-image-icon-23487.png';
import '../../styling/asset-editor.css';

//import s3_base_directory from '../pages/upload'
//import asset_url_list from './assetmanager'
//import hdr_background from '../abandoned_factory_canteen_02_1k.hdr'
import environment_hdr from '../../environment_hdr/sidekix-media-BAVam-y_9Wg-unsplash.jpg'

import photo_capture from '../../icons/photo_capture.png'
import save from '../../icons/icons8-save-96.png'
import brightness from '../../icons/icons8-brightness-96.png'
import goto from '../../icons/icons8-linking-96.png'
import colorwheel from '../../icons/icons8-color-wheel-96.png'
import blackdot from '../../icons/black-dot.png'
import blacksquare from '../../icons/icons8-black-large-square-96.png'
import xenkia_logo_32x32 from '../../icons/xenkia32x32.png'
import brightness_icon from '../../icons/brightness.png'
import center_focus_icon from '../../icons/re-center.png'
import variants from '../../icons/variants-1.png'
import play_pause_button from '../../icons/play.png'
import redsquare from '../../icons/icons8-red-square-96.png'
import greensquare from '../../icons/icons8-green-square-96.png'
import yellowsquare from '../../icons/icons8-yellow-square-96.png'
import scanQR from '../../icons/icons8-scan-96.png'


var user_id = "";
var full_address = "";
var qr_code_address = "";
var poster_address ="";
var qr_code="";

var selected_file_name ="";

var iFrame_Code="";
var button_code="";


export default function AssetEditorModal(props) {

    const [key, setKey]                                 = useState(0);
    const [exposure, setExposure]                       = useState(2);
    const [modelURL, setModelURL]                       = useState("");
    const [posterURL, setPosterURL]                     = useState("");

    const [modelLoaded, setModelLoaded]                 = useState(false);

    const [availableVariants, setAvailableVariants]           = useState([]);
    const [disableListOfVariants, setDisableListOfVariants]   = useState(false);

    const [exposureCntrlVisible, setExposureCntrlVisible]           = useState(false);
    const [settingsSavedMsgShow, setSettingsSavedMsgShow]           = useState(false);
    const [settingsSavedBtnEnabled, setSettingsSavedBtnEnabled]     = useState(false);
    const [toastContent,  setToastContent]                          = useState({
      header:   "",
      body:     "",
      image:    "",
      show:     false
    });

    const [updatedAssetName, setUpdatedAssetName]                   = useState('');
    
    // These are the 3d viewer settings the user will update in the remote DB. These values can be edited from the Management Panel and will alter be used by the www.3dviewer.media.
    const [viewerSettings, setViewerSettings]           = useState({
        enableAR:           "true",
        enableAnimation:    "true",
        enableZoom:         "true",
        displayName:        "true",
        displayTagline:     "true",
        bkgrndColor:        "#F2F6F9",
        defaultVariant:     "",
        defaultExposure:    2,
        enableDepth:        'true',
        depthColor:         'rgba(220, 228, 234, 0.3)',
        enableQRCode:       'true'
    });
    
    // Generate the address of the model to load based on the Base URL + User ID + File Name.
    selected_file_name            = props.fileName;
    full_address                  = config.s3.BUCKET_ACCELERATED + props.userId + "/asset/" + props.fileName + "/" + props.fileName + ".glb";
    // console.log(full_address);

    // These two variables allows us to form the QRCode on the fly (neuropac28@gmail.com, @Waterfall28 - 17-11-2020)
    qr_code_address               = "https://viewer.xenkia.com/#/&uuid=" + props.asset_uuid;
    //console.log(qr_code_address);

    // Address of the Poster Image of the selected 3D Model. It will be displayed while the actual 3D model is being downloaded.
    poster_address                = config.s3.BUCKET_ACCELERATED + props.userId + "/" + props.fileName + "/POSTER_" + props.fileName + ".png";
    
    // This is the <iframe> code for the currently selected model.
    iFrame_Code                   = "<iframe src=https://viewer.xenkia.com/#/&uuid=" + props.asset_uuid + " height='480' width='640' </iframe>";

    button_code                   = "https://viewer.xenkia.com/#/&uuid=" + props.asset_uuid;

    qr_code                       = config.s3.BUCKET_ACCELERATED + props.userId + "/asset/" + props.asset_uuid + "/QR_" + props.asset_uuid + ".png";


    // Note: This function will convert anyutypedArray to URL. we will use this function to read 3d models and convert to URL.
    // Link: https://developer.mozilla.org/en-US/docs/Web/API/Blob
    // Link2: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
    // Link3: https://www.iana.org/assignments/media-types/media-types.xhtml#text
    function typedArrayToURL(typedArray, mimeType) {
      return URL.createObjectURL(new Blob([typedArray.buffer], {type: mimeType}))
    }


    // This runs once at the beginning. Source: https://stackoverflow.com/questions/53464595/how-to-use-componentwillmount-in-react-hooks
    useEffect(() => {

      if(props.asset_uuid)
      {        
        // Get the Friendly Asset Name from Remote DB first.
        setUpdatedAssetName(props.assetName);

        // Download the GLB file from remote server.
        __s3FileStreamDownload({
            bucketName:   'xenkiaassetbucket',
            subPath:      "asset/" + props.asset_uuid + "/" + props.asset_uuid + ".glb"
        }).then(async(dataStream) => {



            //console.log(dataStream.data.Body);

            // Note:
            // Link1: https://developer.mozilla.org/en-US/docs/Web/API/Blob
            // Link2: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
            // Link3: https://www.iana.org/assignments/media-types/media-types.xhtml#text
            {
              //url = typedArrayToURL(dataStream.data.Body, 'text/plain');
              const url = typedArrayToURL(dataStream.data.Body, 'model/gltf-binary');

              setModelURL(url);

              setModelLoaded(true);
            }
        }).catch((e) => {
            console.log("error", e);
        });


        // All Model-Viewer specific event listeners will go here.
        {
          //Reference to Model-Viewer.
          const modelViewer = document.querySelector('#model');

          // MATERIAL VARIANTS.
          if(modelViewer)
          {
              modelViewer.addEventListener('load', () => {
            
              const names = modelViewer.availableVariants;
              setAvailableVariants(modelViewer.availableVariants);
              //console.log(names);

              // The 'Variant' list should be shown when there are more than 1 material for the 3d model.
              if (names.length > 1) setDisableListOfVariants(false);
              else setDisableListOfVariants(true);
            })

            // If anyone in the Model Viewer has been clicked, then do the followings.
            modelViewer.addEventListener("click", () => {
                    
              setExposureCntrlVisible(false);            // Hide the Brightness Bar.
            });
          }
        }
      }

      // Get the Default 3d-viewer settings for this 3d model.
      {
        getViewerSettings(props.asset_uuid);
      }

    }, []);


    // Open the Selected Model in full screen in a new browser tab.
    function enterFullScreenMode() 
    {
        window.open("https://www.3d-viewer.media/" + props.asset_uuid);
    }

    // Copy to Clipboard.
    function CopyIframeToClipboard() {
      copy(iFrame_Code);

      // Say from a click handler when you again want to show your component
      setKey(key+1);
    }

    // Copy to Clipboard.
    function CopyWeblinkToClipboard() {
      copy(button_code);

      // Say from a click handler when you again want to show your component
      setKey(key+1);
    }

    // Copy to Clipboard.
    function CopyQRcodeToClipboard() {
      copy(qr_code);

      // Say from a click handler when you again want to show your component
      setKey(key+1);
    }


    // Exposure control based on a slider.
    function exposureControl (changeEvent)
    {
        console.log((changeEvent/10));

        // Scale is [0-10].
        setExposure((changeEvent/10));
        setViewerSettings({ ...viewerSettings, defaultExposure: (changeEvent/10)});
    }

    // This will upload the generated QRcode to the AWS S3 Bucket.
    // Note: I had to use this source for toblob() conversion related errors: https://www.npmjs.com/package/async-canvas-to-blob?activeTab=readme.
    async function updateViewerSettings(asset_uuid, settings) {
        
      // Add info related to this file to the DB.
      {
        //console.log(viewerSettings);

        setSettingsSavedBtnEnabled(true);

        // Add the User to the database.
        var res = await fetch(config.AssetManager.updateSettings, {
            method: 'POST',
            /* mode: 'no-cors', */
            body: JSON.stringify({
                request:            "updateViewerSettings",
                asset_uuid:         asset_uuid,
                settings:           settings
            })
        });

        // Get the response from the Backend.
        var response = await res.json();
        //console.log(response);

        // A toast appears stating that the settings were successfuly saved into the remote DB.
        if(response.statusCode === 200)
        {
          setToastContent( prevState=> {return{...prevState, show: true, header: 'Saved', body: 'Settings were successfully updated!', image: greensquare}} );
          //setSettingsSavedMsgShow(true);
          setSettingsSavedBtnEnabled(false);
        }
        else setToastContent( prevState=> {return{...prevState, show: true, header: 'Error', body: 'Could not update the settings. Please try again.', image: redsquare}} );
      }
    }

    // This function will update the 'Friendly Asset Name' in the Remote DB.
    async function updateFriendlyAssetName(email, asset_uuid, newName) {
        
      // Add info related to this file to the DB.
      {

        //setSettingsSavedBtnEnabled(true);

        // Add the User to the database.
        var res = await fetch(config.AssetManager.updateFriendlyAssetName, {
            method: 'POST',
            /* mode: 'no-cors', */
            body: JSON.stringify({
                request:            "updateFriendlyAssetName",
                email:              email,
                asset_uuid:         asset_uuid,
                friendlyAssetName:  newName
            })
        });

        // Get the response from the Backend.
        var response = await res.json();
        //console.log(response, email, asset_uuid, newName);

        // A toast appears stating that the settings were successfuly saved into the remote DB.
        if(response.statusCode === 200) 
        {
          setToastContent( prevState=> {return{...prevState, show: true, header: 'Updated', body: 'Asset Name was successfully changed!', image: greensquare}} );
          //setSettingsSavedMsgShow(true);
          //setSettingsSavedBtnEnabled(false);
        }
        else setToastContent( prevState=> {return{...prevState, show: true, header: 'Error', body: 'Could not update the Asset Name. Please try again.', image: redsquare}} );
      }
    }


    // This will capture the Poster for AssetManager, and for our Customers to display in their webpage when the 3D Model is loading in Model Viewer.
    async function createPoster(email, asset_uuid) {
    
      //let posterUrl = '';
      const viewer = document.getElementById('model');
      const blob = await viewer.toBlob({ mimeType: 'image/png', idealAspect: true });


      // Upload POSTER.
      {
          // Upload the profile picture to the DB.
          s3Upload({
            bucketName:   'xenkiaassetbucket',
            object:       blob,
            subPath:      "asset/" + asset_uuid + "/POSTER_" + asset_uuid + ".png"
          }).then(async(response) => {

              // Add info related to this POSTER to the DB.
              {
                // Add the User to the database.
                var res = await fetch(config.AssetManager.addasset, {
                    method: 'POST',
                    /* mode: 'no-cors', */
                    body: JSON.stringify({
                        email:              email,
                        request:            "PosterQRCodeStatusUpdate",
                        asset_uuid:         asset_uuid,
                        type:               "POSTER"
                    })
                });

                // Get the response from the Backend.
                var response = await res.json();
                //console.log(response);
              }

              setToastContent( prevState=> {return{...prevState, show: true, header: 'Captured', body: 'Poster has been captured successfully.', image: greensquare}} );
              //alert("Success: POSTER uploaded!");
          })
        .catch(err=>{
          //console.log('Error uploading QRCode!', err);
          setToastContent( prevState=> {return{...prevState, show: true, header: 'Error', body: 'Poster could not be captured!', image: redsquare}} );
          //alert('Error uploading poster');
        })
      }
    }


    // This will upload the generated QRcode to the AWS S3 Bucket.
    // Note: I had to use this source for toblob() conversion related errors: https://www.npmjs.com/package/async-canvas-to-blob?activeTab=readme.
    async function upload_qr_code(email, asset_uuid) {
        
      let posterUrl = '';
      const canvas = document.getElementById('assetQRCode');
      //console.log(canvas);
      URL.revokeObjectURL(posterUrl);
      const blob = await canvasToBlob(canvas);
      posterUrl = URL.createObjectURL(blob);


      // Upload POSTER.
      {
        // Upload the profile picture to the DB.
        s3Upload({
          bucketName:   'xenkiaassetbucket',
          object:       blob,
          subPath:      "asset/" + asset_uuid + "/QR_" + asset_uuid + ".png"
        }).then(async(response) => {

            // Add info related to this POSTER to the DB.
            {
              // Add the User to the database.
              var res = await fetch(config.AssetManager.addasset, {
                  method: 'POST',
                  /* mode: 'no-cors', */
                  body: JSON.stringify({
                      email:              email,
                      request:            "PosterQRCodeStatusUpdate",
                      asset_uuid:         asset_uuid,
                      type:               "QRCODE"
                  })
              });

              // Get the response from the Backend.
              var resp = await res.json();
              // console.log(response, resp);
            }

            setToastContent( prevState=> {return{...prevState, show: true, header: 'Uploaded', body: 'QR code has been captured successfully.', image: greensquare}} );
        })
        .catch(err=>{
          //console.log('Error uploading QRCode!', err);
          setToastContent( prevState=> {return{...prevState, show: true, header: 'Error', body: 'QR code could not be captured!', image: redsquare}} );
        })
      }
    }
    

    // This will upload the generated QRcode to the AWS S3 Bucket.
    // Note: I had to use this source for toblob() conversion related errors: https://www.npmjs.com/package/async-canvas-to-blob?activeTab=readme.
    async function getViewerSettings(asset_uuid) {
        
      // Add info related to this file to the DB.
      {
        // Add the User to the database.
        var res = await fetch(config.AssetManager.updateSettings, {
            method: 'POST',
            /* mode: 'no-cors', */
            body: JSON.stringify({
                request:            "getViewerSettings",
                asset_uuid:         asset_uuid
            })
        });

        // Get the response from the Backend.
        var response = await res.json();
        
        if(response.statusCode === 200)
        {
          setViewerSettings({ ...viewerSettings, 
            enableAR:         response.body.settings.enableAR,
            enableAnimation:  response.body.settings.enableAnimation,
            enableZoom:       response.body.settings.enableZoom,
            displayName:      response.body.settings.displayName,
            displayTagline:   response.body.settings.displayTagline,
            bkgrndColor:      response.body.settings.bkgrndColor,
            defaultVariant:   response.body.settings.defaultVariant,
            defaultExposure:  response.body.settings.defaultExposure,
            enableDepth:      response.body.settings.enableDepth,
            enableQRCode:     response.body.settings.enableQRCode
          });

          // Set the Checkbox states and other settings based on the saved state of those checkboxes in the server.
          {
            if(response.body.settings.enableAR === 'true')            document.getElementById("enableARChck").defaultChecked = true;
            else document.getElementById("enableARChck").defaultChecked = false;

            if(response.body.settings.enableAnimation === 'true')     document.getElementById("enableAnimationChck").defaultChecked = true;
            else document.getElementById("enableAnimationChck").defaultChecked = false;

            if(response.body.settings.enableZoom === 'true')          document.getElementById("enableZoomChck").defaultChecked = true;
            else document.getElementById("enableZoomChck").defaultChecked = false;

            if(response.body.settings.displayName === 'true')         document.getElementById("displayNameChck").defaultChecked = true;
            else document.getElementById("displayNameChck").defaultChecked = false;

            if(response.body.settings.displayTagline === 'true')      document.getElementById("displayTaglineChck").defaultChecked = true;
            else document.getElementById("displayTaglineChck").defaultChecked = false;

            if(response.body.settings.enableQRCode === 'true')      document.getElementById("enableQRCodeChck").defaultChecked = true;
            else document.getElementById("enableQRCodeChck").defaultChecked = false;

            // Note: Settings up box-shadow from one direction onplay. src: https://stackoverflow.com/questions/4756316/css3-box-shadows-in-one-direction-only
            if(response.body.settings.enableDepth === 'true')
            {
              // document.getElementById("model").style.boxShadow = 'inset 0 0 400px grey';
              // document.getElementById("model").style.boxShadow = 'inset 0px -400px 400px -400px red, inset 3px 0 olive, inset -50px 0px 50px -50px blue';
              document.getElementById("model").style.boxShadow = 'inset 0px -400px 400px -400px grey';
              //document.getElementById("model").style.boxShadow = 'inset 0 0 400px ' + viewerSettings.depthColor;
              document.getElementById("enableDepth").defaultChecked = true;
            }
            else {
              document.getElementById("model").style.boxShadow = 'none';
              document.getElementById("enableDepth").defaultChecked = false;
            }
            
            //setViewerSettings({...viewerSettings, bkgrndColor: response.body.settings.bkgrndColor});

            // Load the default background color for the color-input button.
            document.getElementById('colorInput').value = response.body.settings.bkgrndColor;

            {// Select the Default Variant of the Model based on the saved values on the Remote DB.
              const modelViewer         = document.querySelector('#model');
              modelViewer.variantName   = response.body.settings.defaultVariant;
            }

            // Load Default Exposure value.
            if(response.body.settings.defaultExposure) setExposure(response.body.settings.defaultExposure);
          }

          //console.log(response.body.settings);
        }
      }
    }

    // Movel viewer reference.
    const modelViewer = document.querySelector('#model');
 
    return (
      <Modal
        {...props}
        size ="xl"
        aria-labelledby ="contained-modal-title-vcenter"
        centered
      >

        <Modal.Header closeButton>
          <Modal.Title style={{flex: '1', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <b>Asset Editor</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {/* // This is how we have made the model viewer scale based on the screen size.
          // Source: https://www.w3schools.com/howto/howto_css_aspect_ratio.asp */}
          <div style={{position: 'relative', width: '100%', paddingTop: '56.25%'}}>
            <OverlayTrigger delay={{ show: 250, hide: 500 }} overlay={<Tooltip id="tooltip-disabled">Mockup of your 3D viewer page</Tooltip>}>
                  <model-viewer 
                        id='model'
                        environment-image={environment_hdr}
                        shadow-intensity="1" 
                        shadow-softness="1" 
                        //style={{ width: '1024px', height: '768px', backgroundColor: '#f7f7f7', justifyContent: 'center'}}

                        // This is how we have made the model viewer scale based on the screen size.
                        // Source: https://www.w3schools.com/howto/howto_css_aspect_ratio.asp
                        //style={{position: 'absolute', top: 0, left: '50%', bottom: 0, right: 0, width: '90%', height: 'auto', transform: 'translate(-50%, 0%)', backgroundColor: viewerSettings.bkgrndColor, boxShadow: 'inset 0 0 400px grey'}}
                        style={{position: 'absolute', top: 0, left: '50%', bottom: 0, right: 0, width: '90%', height: 'auto', transform: 'translate(-50%, 0%)', backgroundColor: viewerSettings.bkgrndColor}}
                        // src={full_address}
                        src={modelURL}
                        //poster={poster_address}
                        alt="3D model" 
                        auto-rotate 
                        camera-controls 
                        exposure={(exposure*10)/25}
                        autoplay
                        ar 
                        ar-modes="webxr scene-viewer quick-look"> 


                      {viewerSettings.displayName === 'true'
                        ? <h3 style={{fontFamily: 'Open Sans, sans-serif, Arial, Helvetica', fontSize: '1.5rem', textShadow: "1px 1px 5px #C7C7C7"}}>
                            <br/>
                            <b >
                                {updatedAssetName}
                            </b>
                          </h3>
                        : <> </>
                      }

                        {viewerSettings.displayTagline === 'true'
                          ? <h6 style={{position: 'absolute', left: '3%', bottom: '3%', color: 'grey', fontFamily: 'Open Sans, sans-serif, Arial, Helvetica'}}> <br/> <Image src={xenkia_logo_32x32}/> Powered by <a href="https://www.xenkia.com/" target="_blank" rel="noreferrer"> Xenkia, Inc.</a>  &#169;{new Date().getFullYear()} </h6>
                          : <> </>
                        }

                        {modelURL === ""
                        ?
                          <Spinner animation="border" variant="success" style={{position: 'absolute', top:  '50%', left: '50%'}}/>
                        :
                          <></>
                        }

                        {/* // This is how we have made the model viewer scale based on the screen size.
                        // Source: https://www.w3schools.com/howto/howto_css_aspect_ratio.asp */}
                        <div style={{position: 'absolute', maxWidth: '90%', width: '80%', top: '1%', left: '50%', transform: 'translate(-50%, 0%)', display: exposureCntrlVisible? 'block' : 'none'}}>
                          <RangeSlider size="lg" type="range" value={exposure*10} defaultValue={exposure*10} onChange={changeEvent => exposureControl(changeEvent.target.value)} disabled = {!modelLoaded}/>
                        </div>

                      <div className="fake-panel">
                          <img className="panelButton" src={brightness_icon} alt="brightness"/>
                          <img className="panelButton" src={center_focus_icon} alt="center-focus"/>
                          <img className="panelButton" src={variants} alt="variants"/>
                          <img className="panelButton" src={play_pause_button} alt="play-pause"/>
                      </div>

                      
                        {viewerSettings.enableQRCode === 'true'
                          ?
                            <div style={{position: 'absolute', bottom: '5%', right: '3%'}}>
                              {/* Note: https://zpao.github.io/qrcode.react/ */}
                              <QrCode 
                                  value={qr_code_address}
                                  size={64} 
                                  id="qrCode" 
                                  includeMargin={true} 
                                  level='M'
                                  renderAs={"svg"}
                                  imageSettings={{
                                      src:      scanQR,
                                      x:        null,
                                      y:        null,
                                      height:   24,
                                      width:    24,
                                      excavate: true,
                                  }}
                              />
                              <p style={{color: '#006EFF', fontSize: '8px', textShadow: '1px 1px 3px #4d9aff'}}> Scan to view in AR </p>
                            </div>
                          : <> </>
                        }
                  </model-viewer>
            </OverlayTrigger>
          </div>

          <br/>

          <div style={{display: 'flex', justifyContent: 'center'}}>
            {/* <Form.Group>
              <Form.Control type="name" value={props.assetName} />
              <Form.Text className="text-muted" style={{textAlign: 'justify'}}>
                You can edit the name of your Asset here.
              </Form.Text>
            </Form.Group> */}

            <InputGroup className="mb-3" style={{maxWidth: '50%'}}>
              <FormControl
                value={updatedAssetName}
                aria-describedby="basic-addon2"
                onChange={(e) => {
                  //console.log(e.target.value);
                  setUpdatedAssetName(e.target.value);
                }}
              />
              <Button variant="outline-secondary" id="button-addon2" onClick={() => updateFriendlyAssetName(props.email, props.asset_uuid, updatedAssetName)}>
                Change
              </Button>
            </InputGroup>
          </div>

          <div className="panel" style={{marginTop: '1%'}}>
            <OverlayTrigger delay={{show: 100}} overlay={<Tooltip id="tooltip-disabled">Save poster</Tooltip>}>
              <Button className="btn-margin" variant="outline-light" size="sm" style={{boxShadow: '1px 1px 3px grey', margin: '5px'}} onClick={() => {
                createPoster(props.email, props.asset_uuid);
              }} disabled = {!modelLoaded}> <Image height='32px' src={photo_capture}/> </Button>
            </OverlayTrigger>
            <OverlayTrigger delay={{show: 100}} overlay={<Tooltip id="tooltip-disabled">Adjust brightness</Tooltip>}>
              <Button className="btn-margin" variant="outline-light" size="sm" style={{boxShadow: '1px 1px 3px grey', margin: '5px'}} onClick={() => setExposureCntrlVisible(true)} disabled = {!modelLoaded}> <Image height='32px' src={brightness}/> </Button>
            </OverlayTrigger>
            <OverlayTrigger delay={{show: 100}} overlay={<Tooltip id="tooltip-disabled">Change background color</Tooltip>}>
              <Button className="btn-margin" variant="outline-light" size="sm" style={{padding: '0px',boxShadow: '1px 1px 3px grey', margin: '5px'}} disabled = {!modelLoaded}>
                  <Form.Control
                    type="color"
                    id="colorInput"
                    defaultValue="#F2F6F9"
                    title="Choose your color"
                    onChange={(updatedColor)=> {
                      //setViewerSettings({...viewerSettings, bkgrndColor: updatedColor.target.value.toString()});
                      
                      // Generate Depth Color based on the Background Color.
                      hexToRGBA(updatedColor.target.value.toString(), 0.3)
                        .then(async(rgba) => {
                          //console.log(rgba);
                          setViewerSettings({ ...viewerSettings, bkgrndColor: updatedColor.target.value.toString(), depthColor: rgba});
                          document.getElementById("model").style.boxShadow = 'inset 0 0 400px ' + rgba;
                        });
                    }}
                  />
                </Button>
            </OverlayTrigger>
            
            <OverlayTrigger delay={{show: 100}} overlay={<Tooltip id="tooltip-disabled">Go to 3D Viewer</Tooltip>}>
              <Button className="btn-margin" variant="outline-light" size="sm" style={{boxShadow: '1px 1px 3px grey', margin: '5px'}} onClick={() => window.open(button_code)} disabled = {!modelLoaded}> <Image height='32px' src={goto}/> </Button>
            </OverlayTrigger>
          </div>


          <div style={{display: 'flex', justifyContent: 'left', paddingLeft: '5%', paddingTop: '1%'}}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Enabled AR"       id="enableARChck" style={{textAlign: 'justify', paddingBottom: '0.5rem'}}            disabled = {!modelLoaded} onClick={(checkState) => setViewerSettings({ ...viewerSettings, enableAR: checkState.target.checked.toString()})}/>
              <Form.Check type="checkbox" label="Enable AR Zoom"   id="enableZoomChck" style={{textAlign: 'justify', paddingBottom: '0.5rem'}}          disabled = {!modelLoaded} onClick={(checkState) => setViewerSettings({ ...viewerSettings, enableZoom: checkState.target.checked.toString()})}/>
              <Form.Check type="checkbox" label="Enable Animation" id="enableAnimationChck" style={{textAlign: 'justify', paddingBottom: '0.5rem'}}     disabled = {!modelLoaded} onClick={(checkState) => setViewerSettings({ ...viewerSettings, enableAnimation: checkState.target.checked.toString()})}/>
              <Form.Check type="checkbox" label="Display Name"     id="displayNameChck" style={{textAlign: 'justify', paddingBottom: '0.5rem'}}         disabled = {!modelLoaded} onClick={(checkState) => setViewerSettings({ ...viewerSettings, displayName: checkState.target.checked.toString()})}/>
              <Form.Check type="checkbox" label="Display Tagline"  id="displayTaglineChck" style={{textAlign: 'justify', paddingBottom: '0.5rem'}}      disabled = {!modelLoaded} onClick={(checkState) => setViewerSettings({ ...viewerSettings, displayTagline: checkState.target.checked.toString()})}/>
              <Form.Check type="checkbox" label="Enable Depth"     id="enableDepth" style={{textAlign: 'justify', paddingBottom: '0.5rem'}}             disabled = {!modelLoaded} onClick={(checkState) => {
                                                                                                                                                                                                                setViewerSettings({ ...viewerSettings, enableDepth: checkState.target.checked.toString()});
                                                                                                                                                                                                                // if(checkState.target.checked === true) document.getElementById("model").style.boxShadow = 'inset 0 0 400px grey';
                                                                                                                                                                                                                if(checkState.target.checked === true) document.getElementById("model").style.boxShadow = 'inset 0px -400px 400px -400px grey';
                                                                                                                                                                                                                else document.getElementById("model").style.boxShadow = 'none';
                                                                                                                                                                                                            }}
              />
              <Form.Check type="checkbox" label="Display QRCode"   id="enableQRCodeChck"   style={{textAlign: 'justify', paddingBottom: '0.5rem'}}    disabled = {!modelLoaded} onClick={(checkState) => setViewerSettings({ ...viewerSettings, enableQRCode: checkState.target.checked.toString()})}/>

              <DropdownButton
                  // as        =   {ButtonGroup}
                  //key       =   'up'
                  id        =   'variant-list'
                  drop      =   'end'
                  variant   =   "secondary"
                  title     =   "Available Variants"
                  disabled  =   {disableListOfVariants || !modelLoaded}
                  // disabled  =   {this.state.matVariantListShow==='none' ? true : false}
                  //style     =   {{display: this.state.matVariantListShow}}
              >
                {availableVariants.map(val => (
                  // Note: https://blog.logrocket.com/react-icons-comprehensive-tutorial-examples/
                  <Dropdown.Item onClick={() => {modelViewer.variantName = val; setViewerSettings({ ...viewerSettings, defaultVariant: val})}}> {val} </Dropdown.Item>
                ))}
              </DropdownButton>
            </Form.Group>
          </div>

          <div style={{display: 'flex', justifyContent: 'right', marginRight: '3%'}}>
            <OverlayTrigger delay={{show: 100}} overlay={<Tooltip id="tooltip-disabled">Save settings</Tooltip>}>
              <Button className="btn-margin" variant="outline-dark" size="lg" style={{boxShadow: '1px 1px 3px grey', margin: '5px', paddingLeft: '2rem', paddingRight: '2rem'}} onClick={() => updateViewerSettings(props.asset_uuid, viewerSettings)} disabled = {!modelLoaded || settingsSavedBtnEnabled}> <Image height='40px' src={save}/> </Button>
            </OverlayTrigger>
          </div>


          {/* <OverlayTrigger delay={{ show: 100, hide: 50 }} overlay={<Tooltip id="tooltip-disabled">Click to download the file.</Tooltip>}>
            <h6> <b>Download model:  </b> <u><a style={{color: 'blue'}} href={full_address}> {props.selected_model}</a></u>  , 
                                          <u><a style={{color: 'blue'}} href={full_address}> {props.selected_model.split('.').slice(0, -1).join('.') + ".usdz"} </a></u> 
            </h6>
          </OverlayTrigger> */}
        
          <br/>
          {/* <OverlayTrigger delay={{ show: 100, hide: 50 }} overlay={<Tooltip id="tooltip-disabled">View the 3D Model in full screen mode in a separate tab.</Tooltip>}>
            <Button className="btn-margin" variant="info" size="lg" onClick={enterFullScreenMode}> <b>Full screen mode</b> </Button>
          </OverlayTrigger> */}
               

          {// Sources: https://reactjs.org/docs/dom-elements.html, https://stackoverflow.com/questions/44103804/inline-style-is-not-working-reactjs, https://www.w3schools.com/html/html_css.asp, https://modelviewer.dev/examples/augmentedreality/
          } <Accordion defaultActiveKey={['0']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Links</Accordion.Header>
            <Accordion.Body>
              <div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '1%', marginBottom: '1%', minWidth: '400px', maxWidth: '1024px', justifyContent: 'center'}}> 
                    {/*<Expire delay="1000" key={key}>
                      <Alert variant='info' size="col-md-10"> Copied to Clipboard!</Alert>                  
                    </Expire>*/
                    }

                    <Card>
                      <Card.Header className='links-header' style={{textAlign: 'justify'}}><b>iFrame Code</b></Card.Header>
                      <Card.Body>
                        <textarea readOnly className='links-body' style={{width: '100%'}} id="iframe_code"  rows="2" cols="100">
                            {iFrame_Code}
                        </textarea>
                        <br/>
                        <Button variant="outline-primary" style={{float: 'right'}} onClick={() => {
                            CopyIframeToClipboard();
                            setToastContent( prevState=> {return{...prevState, show: true, header: 'Copied', body: 'iFrame code was successfully copied.', image: greensquare}} );
                        }}>Copy to clipboard</Button>
                      </Card.Body>
                    </Card>

                    <br/>

                    <Card>
                      <Card.Header className='links-header' style={{textAlign: 'justify'}}> <b>Weblink</b> </Card.Header>
                      <Card.Body>
                        <textarea readOnly className='links-body' style={{width: '100%'}} id="button_code"  rows="2" cols="100">
                            {button_code}
                        </textarea>
                        <br/>
                        <Button variant="outline-primary" style={{float: 'right'}} onClick={() => {
                            CopyWeblinkToClipboard();
                            setToastContent( prevState=> {return{...prevState, show: true, header: 'Copied', body: 'WebLink was successfully copied.', image: greensquare}} );
                        }}>Copy to clipboard</Button>
                      </Card.Body>
                    </Card>

                    <br/>

                    {/* <Card>
                      <Card.Header className='links-header' style={{textAlign: 'justify'}}><b>QR Code</b></Card.Header>
                      <Card.Body>
                        <Row>
                          <Col xl={10}> 
                            <textarea readOnly className='links-body' style={{width: '100%'}} id="qr_code"  rows="4" cols="200">
                                {qr_code}
                            </textarea>
                            <br/>
                            <Button variant="outline-primary"style={{float: 'left'}} onClick={() => {
                              CopyQRcodeToClipboard();
                              setToastContent( prevState=> {return{...prevState, show: true, header: 'Copied', body: 'QR code was successfully copied.', image: greensquare}} );
                              }}>Copy to clipboard</Button>
                          </Col>
                          <Col>
                            <Row style={{justifyContent: 'center'}}> 
                              <OverlayTrigger delay={{ show: 250}} overlay={<Tooltip id="tooltip-disabled"> Use this QR code in your product page to allow the customers to load the model in their phone and view in AR </Tooltip>}> */}
                                  {/* <QrCode value={qr_code_address} size={128} id="assetQRCode"/> */}
                                  {/* Note: https://zpao.github.io/qrcode.react/ */}
                                  {/* <QrCode 
                                      value={qr_code_address}
                                      size={128} 
                                      id="assetQRCode" 
                                      includeMargin={true} 
                                      level='M'
                                      renderAs={"Canvas"}
                                      imageSettings={{
                                          src:      scanQR,
                                          x:        null,
                                          y:        null,
                                          height:   24,
                                          width:    24,
                                          excavate: false,
                                      }}
                                  />
                              </OverlayTrigger>
                            </Row>
                            <Row style={{justifyContent: 'center'}}> 
                              <OverlayTrigger delay={{ show: 250}} overlay={<Tooltip id="tooltip-disabled">Download and embedded this QR Code in your Product Page</Tooltip>}>
                                  <Button className="btn-margin" variant="warning" size="sm" onClick={() => upload_qr_code(props.email, props.asset_uuid)}> <b>Download QR Code</b></Button>
                              </OverlayTrigger>
                            </Row>
                          </Col>                      
                        </Row>
                      </Card.Body>
                    </Card> */}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="1">
            <Accordion.Header>Accordion Item #2</Accordion.Header>
            <Accordion.Body>

            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>

        {/* <ToastContainer className="p-3" position='top-center'> */}
        <ToastContainer className="p-3" style={{position: 'fixed', top: '5%', right: '3%'}}>
          <Toast onClose={() => setToastContent( prevState=> {return{...prevState, show: false}} )} show={toastContent.show} delay={3000} autohide>
            <Toast.Header>
              <img
                src={toastContent.image}
                height='20px'
                width='20px'
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto"> <b>{toastContent.header}</b> </strong>
              {/* <small>11 mins ago</small> */}
            </Toast.Header>
            <Toast.Body className="toast-body">{toastContent.body}</Toast.Body>
          </Toast>
        </ToastContainer>

        </Modal.Body>
        <br/>
        <Modal.Footer>
            <h6 className="asset-editor-footer" style={{textAlign: 'center'}}> <b> <span style={{color: 'blue'}}>Note:</span></b> In order to view the model in <b><span style={{color: 'red'}}>Augmented Reality</span></b>, the user needs to scan the <b><span style={{color: 'red'}}>QR Code</span></b> using their smartphone camera. </h6>
        </Modal.Footer>

        <script src="./_model-viewer_ Interactive Example_files/focus-visible.js.download" defer=""></script>
      </Modal>
    );
  }


// // This will upload the generated QRcode to the AWS S3 Bucket.
// // Note: I had to use this source for toblob() conversion related errors: https://www.npmjs.com/package/async-canvas-to-blob?activeTab=readme.
// async function updateViewerSettings(asset_uuid, settings) {
    

//     // Add info related to this file to the DB.
//     {
//       // Add the User to the database.
//       var res = await fetch(config.AssetManager.updateSettings, {
//           method: 'POST',
//           /* mode: 'no-cors', */
//           body: JSON.stringify({
//               request:            "updateViewerSettings",
//               asset_uuid:         asset_uuid,
//               settings:           settings
//           })
//       });

//       // Get the response from the Backend.
//       var response = await res.json();
//       console.log(response);
//     }
// }


