/* eslint-disable no-lone-blocks */

import {React, Component, useState, useEffect} from "react";
// import { Auth, Storage } from 'aws-amplify';
// import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { Button, Card, Container, Row, Col, CardDeck, Form, FormControl, Pagination, Spinner, Image, OverlayTrigger, Tooltip, Badge, Toast} from 'react-bootstrap';
import {BrowserRouter as Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styling/assetmanager.css';
import 'w3-css/w3.css';

import AWS from 'aws-sdk';
import {s3DeleteAsset, s3UserBucketSize, s3UserSubFolderSize, __s3FileStreamDownload} from '../../helper_classes/AWSS3'

import * as QrCode from 'qrcode.react'

//import no_image from './no-image-icon-23487.png';
//import s3_base_directory from '../pages/upload';
import config from '../../config'

import AssetEditor                      from './asset-editor'
import CreateNewAsset                   from './createNewAsset'
import UploadFile                       from './uploadFile'
import DeleteAssetModal                 from './DeleteAssetModal'
import Paginate                         from './Pagination'

// Images and Icons.
import no_image from '../../images/no-photos.png'
import loading_error from '../../images/loading-error.png'
import add_asset_icon from '../../icons/add-file.png'
import refresh_icon from '../../icons/refresh.png'
import black_dot_icon from '../../icons/black-dot.png'
import delete_icon from '../../icons/delete.png'
import empty_box_icon from '../../icons/empty-box.png'
import guidelines_icon from '../../icons/handbook.png'
import up_arrow from '../../icons/up-arrow.png'
import unauthorized from '../../icons/unauthorized.png'
import rename from '../../icons/icons8-edit-96.png'
import json_icon from '../../icons/json.png'
import bookmark_unmarked from '../../icons/icons8-bookmark-unmarked-96.png'
import bookmark_marked from '../../icons/icons8-bookmark-marked-96.png'
import settings from '../../icons/icons8-settings-128.png'
import database_error from '../../icons/icons8-database-error-96.png'
import search_result from '../../icons/icons8-search-96.png'
import attention from '../../icons/icons8-attention-96.png'
import scanQR from '../../icons/icons8-scan-96.png'

// Currently logged in user's email address.
import {loggedinUsersEmail as loggedin_user_email} from '../../App.js';


var items = [];
var items_from_DB = [], searched_items_from_DB = [];
export var asset_url_list = [];

// Currently logged in UserID.
var userID = "";
//var loggedin_user_email = "";
var remoteDBResponse;                               // Response from the Remote Lambda function.


/**
   * All the AssetMAnager from related logic will go here.
   * @returns 
   */
 const AssetManagerFunc = () => {

    /**
     * Pagination All Assets.
     */
        const [pagination, setPagination] = useState({
            pages:                          0,
            activePage:                     1,
            assetsPerPage:                  3,
            totalNumberOfAssets:            0,
            maxNumOfPages:                  1
        });
        const indexOfLastAsset  = pagination.activePage * pagination.assetsPerPage;
        const indexOfFirstAsset = indexOfLastAsset - pagination.assetsPerPage;

    /**
     * Pagination Search Assets.
     */
     const [pagination_Search, setPagination_Search] = useState({
        pages:                          0,
        activePage:                     1,
        assetsPerPage:                  3,
        totalNumberOfAssets:            0,
        maxNumOfPages:                  1
    });
    const indexOfLastAsset_Search  = pagination_Search.activePage * pagination_Search.assetsPerPage;
    const indexOfFirstAsset_Search = indexOfLastAsset_Search - pagination_Search.assetsPerPage;


    const [selectedAsset, setSelectedAsset] = useState({
        activeAssetUUID:    "",
        activeAssetName:    "",
        activeFileName:     ""
    });

    const [search, setSearch]       = useState({
        searchText:         "",
        searching:          false,
        foundAssets:        0
    });

    const [assetFound, setAssetFound]                                       = useState(1);
    const [modalShow, setModalShow]                                         = useState(false);
    const [disableEditButton, setDisableEditButton]                         = useState(true);
    const [creatAssetModalShow, setCreatAssetModalShow]                     = useState(false);
    const [uploadFileModalShow, setUploadFileModalShow]                     = useState(false);
    const [deleteAssetModalShow, setDeleteAssetModalShow]                   = useState(false);
    const [assetEditorModalShow, setAssetEditorModalShow]                   = useState(false);
    const [confirmDeletion, setConfirmDeletion]                             = useState(false);
    const [currentlySelectedAssetID, setCurrentlySelectedAssetID]           = useState(-1);
    const [assetDeletedToastVisibility, setAssetDeletedToastVisibility]     = useState(false);
    const [goToTopButton, setGoToTopButton]                                 = useState({
        goToTopVisible:             false,
        goToTopButtonPosY:          0
    });
    const [posterURL, setPosterURL]                                         = useState("");

    

    // Note: This function will convert anyutypedArray to URL. we will use this function to read 3d models and convert to URL.
    // Link: https://developer.mozilla.org/en-US/docs/Web/API/Blob
    // Link2: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
    // Link3: https://www.iana.org/assignments/media-types/media-types.xhtml#text
    function typedArrayToURL(typedArray, mimeType) {
        return URL.createObjectURL(new Blob([typedArray.buffer], {type: mimeType}))
    }


    // This runs once at the beginning. Source: https://stackoverflow.com/questions/53464595/how-to-use-componentwillmount-in-react-hooks
    useEffect(() => {
    
        // Get asset list.
        alternateAssetList();

/*         // Everytime the Window is Resized, thie event gets fired.
        window.addEventListener('resize', (event) =>{
            toggleGoToTopButtonVisibility();
        });

        // Everytime the Window is Scrolled, thie event gets fired.
        window.addEventListener('scroll', (event) =>{
            toggleGoToTopButtonVisibility();
        });

        // Run this at the begining once, so that the GoToTop button appears based on the current window size.
        toggleGoToTopButtonVisibility(); */
      }, []);


    // As soon as the "Confirm Deletion" button has been pressed, delete the Asset from the S3 and any information related to it from the DB.
    // Note: https://www.codegrepper.com/code-examples/javascript/how+to+wait+untill+setState+is+complete+react+hooks
    useEffect(() => {
        if(confirmDeletion === true)
        {
            //console.log("useEffect ", currentlySelectedAssetID, remoteDBResponse);

            setConfirmDeletion(false);
            DeleteFromRemoteDB();
        }
    }, [confirmDeletion, currentlySelectedAssetID]);



        // This function will scroll the page to the top.
    // Note: https://www.coderomeos.org/scroll-to-top-of-the-page-a-simple-react-component
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

    // Show button when page is over a certain width.
    const toggleGoToTopButtonVisibility = () =>{

        //console.log((window.scrollY/window.innerHeight)*100 + "%");

        //console.log(window.innerWidth - 5);

/*         // The "GoToTop" button should appear 150px inside of the current window.innerWidth.
        setGoToTopButton({...goToTopButton, goToTopButtonPosY: (window.innerWidth - 150)}); */

        // The "GoToTop" button should appear when the window.innerWidth is greater than 1024px and 100% of the page height (when its first loaded) has been scrolled down.
        //if ( (window.innerWidth > 1024) && ((window.scrollY/window.innerHeight)*100 > 100)) {
        if ( (window.scrollY/window.innerHeight)*100 > 100) {
            // The "GoToTop" button should appear 150px inside of the current window.innerWidth.
            setGoToTopButton({goToTopVisible: true, goToTopButtonPosY: (window.innerWidth - 150)})
        } else {
            setGoToTopButton({goToTopVisible: false})
        }
    };


    // As soon as the "Confirm Deletion" button has been pressed, delete the Asset from the S3 and any information related to it from the DB.
    const DeleteFromRemoteDB = async() => {

        // Delete from the Database.
        var res = await fetch(config.AssetManager.removeasset, {
            method: 'POST',
            //mode: 'no-cors',
            body: JSON.stringify({
                request:        "deleteItem",
                email:          loggedin_user_email,
                asset_uuid:     currentlySelectedAssetID
            })
        })

        // Get the response from the Backend.
        var response = await res.json();

        // Only update the Upload counter if the remote DB Upload counter was successfully incremented.
        if(response.statusCode === 200)
        {
            // Delete the Asset from S3 Bucket.
            {
                var resp = await s3DeleteAsset({
                    assetUUID: currentlySelectedAssetID
                });

                if(resp === true){
                    
                    // Refresh Asset List or better (faster) delete the Asset from the current list.
                    {
                        //alternateAssetList();                               // Refresh the list.

                        var index=0;

                        items_from_DB.map((asset) => {
                            if( currentlySelectedAssetID === asset[0]) 
                            {
                                //console.log(items_from_DB);
                                items_from_DB.splice(index, 1);
                                // console.log(items_from_DB);
                                // console.log(currentlySelectedAssetID, asset[0], index);
                                return true;
                            }

                            index++;
                        })
                    }

                    setAssetDeletedToastVisibility(true);               // Tell the user that the asset was successfully deleted.
                }
            }

            // Since a folder has been deleted, update the current value of Storage Space used by this user in the DB.
            {
                // We need both of these values to recalculate the Bucket Size of the User. 
                var userBucketSize          = await s3UserBucketSize();
                var userSubFolderSize       = await s3UserSubFolderSize({
                    subPath:    "/asset/" + currentlySelectedAssetID + "/"
                });

                var ret = await fetch("https://oidnlpynvl.execute-api.us-east-1.amazonaws.com/prod/increaseviewcount", {
                    method: 'POST',
                    //mode: 'no-cors',
                    body: JSON.stringify({
                        "email":                loggedin_user_email,
                        "field_to_update":      "storage_space_used",
                        "value":                (userBucketSize-userSubFolderSize).toFixed(2),
                        "inc_dec_reset":        "update"
                    })
                })

                // Get the response from the Backend.
                var response = await ret.json();
            }
        }
    }

    // Source:  https://react-bootstrap.github.io/components/modal/
    const Disable3DViewer = () => {
        setModalShow(false);
    }

    /**
     * Open the Create Item dialog box.
     */
    const EnableItemCreationModal = () => 
    {
        setCreatAssetModalShow(true);
        //console.log("Open AssetManager");
    }


    /**
     * Open the Asset Editor.
     */
    const EnableAssetEditorModal = (friendlyAssetName, uuid, fileName) => 
    {
        //console.log(friendlyAssetName, uuid, fileName);

        // Copy the Currently Active AssetName and UUID, so that it could be passed to the UploadFile Modal.
        //activeAssetName     = fileName;
        //activeAssetUUID     = uuid;
        setSelectedAsset({activeAssetUUID: uuid, activeAssetName: friendlyAssetName, activeFileName: fileName});

        // Turn on the AssetEditor Modal.
        setAssetEditorModalShow(true);
    }


    /**
     * Modal for editing asset.
     */
     const AssetEditingModal =() =>{
        return(
            <AssetEditor
                show        = {assetEditorModalShow}
                onHide={()  => {
                                    setAssetEditorModalShow(false);
                                    alternateAssetList();           // Refresh the list.
                                }}
                title       = "Asset editor"
                email       = {loggedin_user_email}
                userId      = {userID}
                asset_uuid  = {selectedAsset.activeAssetUUID}
                assetName   = {selectedAsset.activeAssetName}
                fileName    = {selectedAsset.activeFileName}
            />
        );
    }


    /**
     * Model for creating a new item.
     */
    const CreateNewItemModal =() =>{
        return(
            <CreateNewAsset
                show        = {creatAssetModalShow}
                onHide={()  => {
                                    setCreatAssetModalShow(false);
                                    alternateAssetList();           // Refresh the list.
                                }}
                title       = "Create Item"
                email       = {loggedin_user_email}
                userId      = {userID}
            />
        );
    }


    /**
     * Open the Upload file.
     */
    const EnableUploadFileModal = (friendlyAssetName, uuid, fileName) => 
    {
        
        //console.log(friendlyAssetName, uuid, fileName);

        // Copy the Currently Active AssetName and UUID, so that it could be passed to the UploadFile Modal.
        //activeAssetName     = fileName;
        //activeAssetUUID     = uuid;
        setSelectedAsset({activeAssetUUID: uuid, activeAssetName: friendlyAssetName, activeFileName: fileName});

        // Turn on the UploadFile Modal.
        setUploadFileModalShow(true);
    }


    /**
     * Confirm when an Asset is being deleted.
     */
     const DeleteConfirmationModal = (e) => 
     {
         
         //console.log(friendlyAssetName, uuid, fileName, button_id);

         currentlySelectedAssetID = e.target.id;
         console.log(currentlySelectedAssetID);

         //console.log("testing");
 
         // Copy the Currently Active AssetName and UUID, so that it could be passed to the UploadFile Modal.
         //activeAssetName     = fileName;
         //activeAssetUUID     = uuid;
         //setSelectedAsset({activeAssetUUID: uuid, activeAssetName: friendlyAssetName, activeFileName: fileName});
 
         // Turn on the UploadFile Modal.
         setDeleteAssetModalShow(true);
     }



    /**
     * Model for uploading files.
     */
    const UploadFileModal =() =>{
        return(
            <UploadFile
                show        = {uploadFileModalShow}
                onHide={()  => {
                                setUploadFileModalShow(false);
                                alternateAssetList();           // Refresh the list.
                            }}
                title       = {selectedAsset.activeAssetName}
                email       = {loggedin_user_email}
                userId      = {userID}
                asset_uuid  = {selectedAsset.activeAssetUUID}
                assetName   = {selectedAsset.activeAssetName}
                fileName    = {selectedAsset.activeFileName}
            />
        );
    }

    /**
     * Model for confirming deletion of Asset.
     */
    const FuncDeleteAssetModal =() =>{
        return(

            <DeleteAssetModal
                show        = {deleteAssetModalShow}
                onHide={()  => {
                                setDeleteAssetModalShow(false);
                            }}
                onConfirm={() => {
                                setDeleteAssetModalShow(false);
                                setConfirmDeletion(true);
                                //RemoveModel();
                                alternateAssetList();           // Refresh the list.
                            }}
                title       = "Delete Asset"
                /* email       = {loggedin_user_email}
                userId      = {userID}
                asset_uuid  = {selectedAsset.activeAssetUUID}
                assetName   = {selectedAsset.activeAssetName}
                fileName    = {selectedAsset.activeFileName} */
            />
        );
    }

    


    /**
     * This is the Menu Bar of the Asset Manager.
     * @returns 
     */
    const AssetManagerMenuBar =() =>{
        return(
            <div>
                <br/>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Create new asset</Tooltip>}>
                        <span className="d-inline-block">
                            <Button variant="outline-dark" onClick={EnableItemCreationModal}> <Image height='48px' src={add_asset_icon}/> </Button>
                        </span>
                    </OverlayTrigger>

                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Refresh list</Tooltip>}>
                        <span className="d-inline-block">
                            <Button variant="outline-dark" onClick={()=> {
                                setSearch( prevState=> {return{...prevState, searching: false, foundAssets: 0, searchText: ''}});
                                alternateAssetList();
                            }}> <Image height='48px' src={refresh_icon}/> </Button>
                        </span>
                    </OverlayTrigger>

                    <OverlayTrigger placement="bottom" overlay={<Tooltip>JSON</Tooltip>}>
                        <span className="d-inline-block">
                            <Button variant="outline-dark" disabled> <Image height='48px' src={json_icon}/> </Button>
                        </span>
                    </OverlayTrigger>

                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Settings</Tooltip>}>
                        <span className="d-inline-block">
                            <Button variant="outline-dark" disabled> <Image height='48px' src={settings}/> </Button>
                        </span>
                    </OverlayTrigger>

                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Help</Tooltip>}>
                        <span className="d-inline-block">
                            <Button variant="outline-dark" disabled> <Image height='48px' src={guidelines_icon}/> </Button>
                        </span>
                    </OverlayTrigger>

                <br/>
            </div>
        );
    }

    /** 
     * Remove the asset from the DB and also delete from the S3.
     * Source:  https://docs.amplify.aws/lib/storage/remove/q/platform/js
    */
    const RemoveModel = async(e) =>{

        // Save the currently selected Asset ID.
        //setCurrentlySelectedAssetID(Number(e.target.id));
        setCurrentlySelectedAssetID(e.target.id);
        //console.log(e.target.id);
        //console.log("Remove Model", Number(currentlySelectedAssetID));

        // Show the Asset Deletion Modal.
        setDeleteAssetModalShow(true);
    }


    /**
     * Get Asset Details based on UUID.
     */
    async function GetAssetDetails(uuid){
    
        // Get Asset details.
        {
            // Incease the upload counter by 1 in the remote database..
            var res = await fetch("https://q5rygp0k2h.execute-api.us-east-1.amazonaws.com/prod/getdetails", {
            method: 'POST',
            //mode: 'no-cors',
            body: JSON.stringify({
                "request" :          "readDetails",
                "uuid"    :          uuid
                })
            });
    
            // Get the response from the Backend.
            var response = await res.json();
    
            // Only update the Upload counter if the remote Upload counter was successfully incremented.
            if(response.statusCode === 200)
            {
                console.log("Response Code: 200, Asset details successfuly read!", response.body.data.Item);
                return response;
            }
        }
    
    }

    // This function will set the current page number.
    const setPageNumber = (pageNumber) =>{
        setPagination( prevState=> {return{...prevState, activePage: pageNumber}} )
    }

    // This function will set the current page number for the Search list.
    const setPageNumber_Search = (pageNumber) =>{
        setPagination_Search( prevState=> {return{...prevState, activePage: pageNumber}} )
    }


    /**
     * This function pulls assets from the DB and forms Cards to be displayed in this page.
     */
    const alternateAssetList = async() =>{

        // While waiting for a response from the remote DB, show a spinner.
        setAssetFound(1);

        // Incease the upload counter by 1 in the remote database..
        var res = await fetch(config.AssetManager.queryasset, {
            method: 'POST',
            //mode: 'no-cors',
            body: JSON.stringify({
                request:    "getAllItems",
                email:      loggedin_user_email
            })
        })

        // Get the response from the Backend.
        remoteDBResponse = await res.json();

        // Only update the Upload counter if the remote Upload counter was successfully incremented.
        if(remoteDBResponse.statusCode === 200)
        {
            var numberOfAssets = 0;

            // RESET Items list to remove old items.
            items_from_DB =[];
            //console.log(remoteDBResponse.body.AssetManagerTableData.Items.length);

            // Create Items card for each items found in the DB.
            remoteDBResponse.body.AssetManagerTableData.Items.forEach(async(res)=> {
                
                    let viewCountRetVal;
                    //console.log(res);

                    // // Download the POSTER belonging to each of the Assets.
                    // __s3FileStreamDownload({
                    //     bucketName:   'xenkiaassetbucket',
                    //     //objectName:   "profile-picture.jpg",
                    //     // subPath:      "asset/" + props.asset_uuid + "/POSTER_" + props.asset_uuid + ".png"
                    //     subPath:      "asset/" + res.asset_uuid + "/POSTER_" + res.asset_uuid + ".png"
                    //     // subPath:      "asset/0c14f6dc-1175-5131-ab6b-8ebea984ec37/POSTER_0c14f6dc-1175-5131-ab6b-8ebea984ec37.png"
                    // }).then((dataStream) => {

                        //console.log(dataStream.data.Body.toString('utf-8'));
                        //console.log(res.asset_uuid);

                        //let url = typedArrayToURL(await dataStream.data.Body, 'image/png');
                        //setPosterURL(url);
                        //window.location = url;

                        items_from_DB.push([res.asset_uuid, res.friendlyAssetName,
                            <Card 
                                className="p-3 card-margin"
                                border="primary"
                                style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '90%', minWidth: '350px'}}
                                bg="light"
                                text='dark'
                            >
                                <div style={{display: 'flex', flexDirection: 'row', marginLeft: '5px'}}>
                                    <Col style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                                        <h4 style={{fontFamily: 'Open Sans, sans-serif, Arial, Helvetica'}}> <b>{res.friendlyAssetName}</b> &nbsp; </h4>
                                        {/* <img className="buttonZoomAssetManager" src={bookmark_marked} alt="bookmark" height='24px'/> */}
                                        {/* <Image className="buttonZoom" id={button_id} style={{background: '#EBEFF5'}} height='24px' width='24px' src={rename}/> */}
                                    </Col>
                                    <Col style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                        <Button className="buttonZoomAssetManager" variant="light" onClick={RemoveModel}> <Image id={res.asset_uuid} height='32px' src={delete_icon}/> </Button>
                                    </Col>
                                </div>
        
                                {/* Image streching problem solved:  https://www.codegrepper.com/code-examples/css/css+image+no+stretch+responsive */}
                                <Card.Img 
                                    variant="top"
                                    style={{objectFit: 'contain', overflow: 'hidden'}}
                                    // Note: New images were not loading even if the image was updated. This will force to download the image every time it loads. Src: https://stackoverflow.com/questions/1077041/refresh-image-with-a-new-one-at-the-same-url/9943419
                                    src={res.posterUploaded?     res.S3Url + "POSTER_" + res.fileName + ".png" + "?" +  new Date().getTime()   :   no_image}
                                    //src={res.posterUploaded?     typedArrayToURL(dataStream.data.Body, 'image/png')   :   no_image}
                                    height='480px'
                                />
        
                                <Card.Body>
                                    <h6 style={{textAlign: 'left', fontFamily: 'Open Sans, sans-serif, Arial, Helvetica'}}> <b>Created:</b> {unixTimeToDataTime(res.uploadedOn)} </h6>
                                    <h6 style={{textAlign: 'left', fontFamily: 'Open Sans, sans-serif, Arial, Helvetica'}}> <b>UUID:</b> {res.asset_uuid} </h6>
                                    {/* <h6 style={{textAlign: 'left'}}> <b>View count:</b>  </h6>
                                    <h6 style={{textAlign: 'left'}}> <b>Size:</b>  </h6> */}
        
                                    <hr/>
        
                                    <Row style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                        <Col style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                            {/* <h6> <a style={{color: 'blue'}} href={res.S3Url + res.fileName + "." + res.fileFormat}> {res.fileFormat}</a> ({res.fileSizeInMB} MB)</h6> */}
                                            {res.glbFileUploaded
                                                ?   <Badge pill bg="primary" style={{fontSize: '1rem'}}> <a href={res.S3Url + res.fileName + ".glb"}> GLB </a> </Badge>
                                                :   <Badge pill bg="secondary" style={{fontSize: '1rem'}}> GLB </Badge>
                                            }
                                            <Image height='8px' src={black_dot_icon} style={{marginLeft: '8px', marginRight: '8px'}}/>
                                            {res.usdzFileUploaded
                                                ?   <Badge pill bg="primary" style={{fontSize: '1rem'}}> <a href={res.S3Url + res.fileName + ".usdz"}> USDZ </a> </Badge>
                                                :   <Badge pill bg="secondary" style={{fontSize: '1rem'}}> USDZ </Badge>
                                            }
                                            <Image height='8px' src={black_dot_icon} style={{marginLeft: '8px', marginRight: '8px'}}/>
                                            <OverlayTrigger placement="bottom" overlay={<Tooltip>Coming soon...</Tooltip>}> 
                                                <Badge pill bg="secondary" style={{fontSize: '1rem'}}> FBX </Badge> 
                                            </OverlayTrigger>
                                            <Image height='8px' src={black_dot_icon} style={{marginLeft: '8px', marginRight: '8px'}}/>
                                            <OverlayTrigger placement="bottom" overlay={<Tooltip>Coming soon...</Tooltip>}> 
                                                <Badge pill bg="secondary" style={{fontSize: '1rem'}}> STL </Badge>
                                            </OverlayTrigger>
                                        </Col>
                                        <Col style={{paddingTop: '1rem',paddingBottom: '1rem'}}>
                                            {/* Note: https://zpao.github.io/qrcode.react/ */}
                                            <QrCode 
                                                value={config.viewer3d.url + "#/&uuid=" + res.asset_uuid}
                                                size={128} 
                                                id="qrCode" 
                                                includeMargin={true} 
                                                level='M'
                                                renderAs={"svg"}
                                                imageSettings={{
                                                    src:      scanQR,
                                                    x:        null,
                                                    y:        null,
                                                    height:   24,
                                                    width:    24,
                                                    excavate: true,
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    
                                    {/* <h6 className="checkbox-text" style={{color: 'grey'}}> <b>View/Download Count:</b> {viewCountRetVal} </h6> */}
                                    
                                    <hr/>
        
                                    <Button variant="warning" style={{boxShadow: '1px 1px 3px grey'}} onClick={() => EnableUploadFileModal(res.friendlyAssetName, res.asset_uuid, res.fileName)}> <b style={{fontFamily: 'Open Sans, sans-serif, Arial, Helvetica'}}>Upload files</b> </Button>
                                    {res.glbFileUploaded
                                        ?   <> 
                                                <Button variant="info" style={{boxShadow: '1px 1px 3px grey'}} onClick={() => window.open("https://viewer.xenkia.com/#/&uuid="+ res.asset_uuid)}> <b style={{fontFamily: 'Open Sans, sans-serif, Arial, Helvetica'}}>View</b> </Button>
                                                <Button variant="success" style={{boxShadow: '1px 1px 3px grey'}} onClick={() => EnableAssetEditorModal(res.friendlyAssetName, res.asset_uuid, res.fileName)}> <b style={{fontFamily: 'Open Sans, sans-serif, Arial, Helvetica'}}>Edit</b> </Button>
                                            </>
                                        :   <>
                                                <Button variant="info" style={{boxShadow: '1px 1px 3px grey'}} disabled> <b>3d Viewer</b> </Button>
                                                <Button variant="success" style={{boxShadow: '1px 1px 3px grey'}} onClick={() => EnableAssetEditorModal(res.friendlyAssetName, res.asset_uuid, res.fileName)} disabled> <b style={{fontFamily: 'Open Sans, sans-serif, Arial, Helvetica'}}>Edit</b> </Button>
                                            </>
                                    }
                                </Card.Body>
                            </Card>
                        ]);
        
        
                        // Keep incrementing the Button ID.
                        numberOfAssets++;

                    // }).catch((e) => {
                    //     console.log("error", e);
                    // });

                    // GetAssetDetails(res.asset_uuid).then(value => {
                    //        viewCountRetVal = value.body.data.Item.viewCount;
                    //        console.log(viewCountRetVal);
                    //    });
                        /*let response;
                        response = await GetAssetDetails(res.asset_uuid);
                        //console.log(response.then(val => console.log(val)));
                        console.log(response); 
                        */
            });

            //console.log(items_from_DB);

            // Add the Pagination Items based on the number of pages required.
            setPagination( prevState=> {return{...prevState, totalNumberOfAssets: numberOfAssets}} );        // Update the state so that the renderers updates.

            // Mark that the Assets were found and ready to be displayed.
            setAssetFound(2);

            // Mark that asset has been loaded from the DB.
            // if (items_from_DB.length > 0) setAssetFound(2);
            // else setAssetFound(0);
        }
    }


    // This function will update how many Assets will be displayed per page.
    function selectAssetPerPage()
    {
        var select = document.getElementById('number-of-assets-per-page');

        //console.log(select.options[select.selectedIndex].value);
        setPagination( prevState => {return{ ...prevState, assetsPerPage: select.options[select.selectedIndex].value, activePage: 1}} );

        setPagination_Search( prevState => {return{...prevState, assetsPerPage: select.options[select.selectedIndex].value, activePage: 1}} );
    }


    if(assetFound === 2)
    {
        return(
            <>
                <AssetManagerMenuBar/>
                <br/>
                <div className="Asset-manager-header">
                    

                    <Container fluid>
                        {/* Note:   https://stackoverflow.com/questions/18746569/force-div-element-to-stay-in-same-place-when-page-is-scrolled, 
                                https://stackoverflow.com/questions/21541259/explain-why-this-element-doesnt-render-in-front,
                                https://react-bootstrap.github.io/components/toasts/ */
                        }

                        {/* {goToTopButton.goToTopVisible
                        ?
                            <Button variant="outline-secondary" onClick={scrollToTop} style={{position: 'fixed', left: goToTopButton.goToTopButtonPosY, top: '85%', width: '96px', borderWidth: '0px', zIndex: '100'}}> 
                                <Image src={up_arrow} style={{width: '90%'}}/> 
                            </Button>
                        :
                            <> </>
                        } */}
    
                        <Toast animation='true' position='top-center' className="d-inline-block m-1" onClose={() => setAssetDeletedToastVisibility(false)} show={assetDeletedToastVisibility} delay={3000} autohide style={{position: 'fixed', top: '15%', zIndex: '100'}}>
                            {/* <Toast.Header>
                                <strong className="me-auto">Deleted</strong>
                                <small>11 mins ago</small>
                            </Toast.Header> */}
                            <Toast.Body> <b style={{color: 'red'}}>Asset has been deleted successfully!</b> </Toast.Body>
                        </Toast>


                        <Row style={{display: 'flex'}}>
                            <Col style={{display: 'flex', justifyContent: 'center', padding: '1rem'}}>
                                    <Form className="d-flex" style={{maxWidth: '30rem', width: '30rem', marginLeft: '3%', marginRight: '3%'}} onSubmit={e => { e.preventDefault(); }}>
                                        <FormControl
                                            id='search-assets'
                                            type="text"
                                            placeholder="Search"
                                            className="me-2"
                                            aria-label="Search"
                                            style={{boxShadow: '1px 1px 3px grey'}}
                                            onChange={(e)=> {
                                                setSearch( prevState=> {return{...prevState, searchText: e.target.value}});
                                                // if(e.target.value.length>0) document.getElementById("submit-search").disabled=false;
                                                // else document.getElementById("submit-search").disabled=true;

                                                // Each time a aharacter is added/removed, restart search.
                                                {
                                                    setSearch( prevState=> {return{...prevState, searching: true}});

                                                    // Clear the list.
                                                    searched_items_from_DB = [];
            
                                                    // If any one the searched name matches anything on the Asset list, then push it onto the new list so that it can be displayed.
                                                    items_from_DB.map((asset) => {
                                                        if (asset[1].toLowerCase().search(e.target.value.toLowerCase()) > -1) 
                                                            searched_items_from_DB.push(asset);
                                                    })
            
                                                    //console.log(searched_items_from_DB.length)
            
                                                    setSearch( prevState=> {return{...prevState, 
                                                        searching: false, 
                                                        foundAssets: searched_items_from_DB.length
                                                    }});
                                                    setPagination_Search( prevState => {return{...prevState, 
                                                        activePage: 1, 
                                                        totalNumberOfAssets: searched_items_from_DB.length
                                                    }});
                                                }

                                            }}
                                        />
                                        <Button variant="outline-success" id="submit-search" style={{boxShadow: '1px 1px 3px grey'}}  onClick={()=> {
                                            
                                            document.getElementById('search-assets').value = '';
                                            setSearch( prevState=> {return{...prevState, searching: false, foundAssets: 0, searchText: ''}});


                                            // setSearch( prevState=> {return{...prevState, searching: true}});

                                            // // Clear the list.
                                            // searched_items_from_DB = [];

                                            // // If any one the searched name matches anything on the Asset list, then push it onto the new list so that it can be displayed.
                                            // items_from_DB.map((asset) => {
                                            //     if ( (asset[1].toLowerCase().search(search.searchText.toLowerCase()) > -1) && (search.searchText !== '')) 
                                            //         searched_items_from_DB.push(asset);
                                            // })

                                            // setSearch( prevState=> {return{...prevState, searching: false, foundAssets: searched_items_from_DB.length}});
                                            // setPagination_Search( prevState => {return{...prevState, activePage: 1, totalNumberOfAssets: searched_items_from_DB.length}});

                                        }}>Clear</Button>
                                    </Form>
                            </Col>
                            <Col style={{display: 'flex', justifyContent: 'end', marginRight: '8%', padding: '1rem'}}>
                                {/* <label for="assetPerPageLabel" style={{fontSize: '1.5rem', textShadow: '0px 0px 2px grey'}}>Asset per page &nbsp; </label> */}
                                <select id="number-of-assets-per-page" name="numberOfAssetsPerPage" style={{fontSize: '1.2rem', minWidth: '3rem', boxShadow: '1px 1px 3px grey'}} onChange={selectAssetPerPage}>
                                    <option value="3" selected>3</option>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                </select>
                            </Col>
                        </Row>

                        <Row>
                            {search.searchText.length > 0
                                ?
                                    <text className="default-font" style={{fontSize: '1.5rem'}}> <img height='24' src={attention} alt="search"/> Number of matches: <b style={{color: 'red'}}>{pagination_Search.totalNumberOfAssets}</b></text>
                                :
                                    <> </>
                            }
                        </Row>

                        <Row style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', alignItems: 'center', maxWidth: '90%'}} xs={1} md={3} lg={6}>                                                                
                            {search.searching
                                ?   <div className="asset-manager-login-error">
                                        <Spinner  style={{fontSize: '1rem'}} animation="border" variant="success"/>
                                    </div>
                                

                                :   (search.searchText.length > 0)
                                    ?    
                                        (search.foundAssets)
                                        ?   searched_items_from_DB.slice(indexOfFirstAsset_Search, indexOfLastAsset_Search).map((asset) => 
                                                // Render only the Card containing Asset related informations.
                                                asset[2]
                                            )
                                        :   
                                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', maxWidth: '90%', width: 'auto'}}>
                                                <text className="default-font" style={{width: '100%', fontSize: '1.5rem'}}> <img height='64px' src={database_error} alt="database-error"/> Your search - &nbsp;"{search.searchText}"&nbsp; - did not match any Asset Name.</text>
                                            </div>
                                    :
                                        items_from_DB.slice(indexOfFirstAsset, indexOfLastAsset).map((asset) => 
                                                // Render only the Card containing Asset related informations.
                                                asset[2]
                                            )
                                        
                            }

                        </Row>

                        <br/>

                        {(search.searchText.length > 0) && (search.foundAssets)
                            ?
                                <Paginate
                                    activePage       = {pagination_Search.activePage}
                                    assetsPerPage    = {pagination_Search.assetsPerPage}
                                    totalAssets      = {pagination_Search.totalNumberOfAssets}
                                    setPageNumber    = {setPageNumber_Search}
                                    type             = 'Search'
                                />
                            :
                                pagination.totalNumberOfAssets > 0
                                ?
                                    <Paginate
                                        activePage       = {pagination.activePage}
                                        assetsPerPage    = {pagination.assetsPerPage}
                                        totalAssets      = {pagination.totalNumberOfAssets}
                                        setPageNumber    = {setPageNumber}
                                        type             = 'Normal'
                                    />
                                :
                                    <> </>
                        }
                    </Container>

                    {// Source: https://www.w3schools.com/react/react_props.asp
                    }
                    <CreateNewItemModal/>
                    <UploadFileModal/>
                    <AssetEditingModal/>
                    <FuncDeleteAssetModal/>
                </div>
            </>
        );
    }
    else if(assetFound ===1){
        return(
            <>
                <AssetManagerMenuBar/>
                <br/>
                <div className="asset-manager-login-error">
                    <Spinner  animation="border" variant="success"/>
                </div>
            </>
        )
    }
    else if(assetFound ===0){
        return(
            <>
                <AssetManagerMenuBar/>
                <br/>
                <div className="asset-manager-login-error">
                    <Image height='64px' src={empty_box_icon}/>
                    <p className="assetmanager-subheader"> <b>Empty Bucket</b> </p>

                    <CreateNewItemModal/>
                    <UploadFileModal/>
                    <AssetEditingModal/>
                    <FuncDeleteAssetModal/>
                </div>
            </>
        )
    }
}



/**
 * AssetManager Class definition.
 */
//class AssetManager extends Component {
function AssetManager(props){

        if (props.loggedIn === true)
        {
            // Get the userID.
            userID = AWS.config.credentials.data.IdentityId;

            return(
                // If the user is logged in, then execute the function.
                <AssetManagerFunc/>
            );

        }else if (props.loggedIn === false){
            return(
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh'}}>
                    <div>
                        <img src={unauthorized} alt="unauthorized" height="96px"></img>
                        <hr/>
                        <h3> Please login to access Asset Manager </h3>
                    </div>
                </div>
            )
        } 
}



/**
 * Converts Unix Time to regular Date&Time.
 * Source: https://www.cloudhadoop.com/reactjs-convert-timestamp-date/
 * @param {*} unix_time 
 * @returns 
 */
    function unixTimeToDataTime(unix_time)
    {
        //console.log(currentTimestamp); // get current timestamp
        let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(unix_time)

        return date;
    }


export default AssetManager;
