


//import * as AWS from 'aws-sdk/global';
const AWS 								= 	require('aws-sdk');
const AmazonCognitoIdentity             =   require('amazon-cognito-identity-js');		/** NOTE: https://www.npmjs.com/package/amazon-cognito-identity-js */


/**
 * Sensitive informations, remove later!!!!!!!
 */
const IdentityPoolId    =   'us-east-1:56eeea78-35fe-4780-b88c-2741471662e2';
const UserPoolId        =   'us-east-1_H5lBHE6l4';
const ClientId          =   'p7gg9eoscug4tuf2m5d1phqr1';
const Region			=   'us-east-1'


/**
 * This function will SignOut the currently logged in user from Cognito.
 */
export function cognitoSignout()
{
	var poolData = {
		UserPoolId: 	UserPoolId, 		// Your user pool id here
		ClientId: 		ClientId, 			// Your client id here
	};
	var userPool 		= new AmazonCognitoIdentity.CognitoUserPool(poolData);
	var cognitoUser 	= userPool.getCurrentUser();

	cognitoUser.signOut();
}



/**
 * This function will check if any user is curently logged in. This is especially handy between browser refresh.
 */
export function cognitoGetCurrentUser()
{
	var email;

	var poolData = {
		UserPoolId: 	UserPoolId, // Your user pool id here
		ClientId: 		ClientId, // Your client id here
	};
	var userPool 		= new AmazonCognitoIdentity.CognitoUserPool(poolData);
	var cognitoUser 	= userPool.getCurrentUser();
	
	
		return new Promise((resolve, reject) => 
		{
			if (cognitoUser != null) 
			{
				cognitoUser.getSession(function(err, session) 
				{
					if (err) {
						//alert(err.message || JSON.stringify(err));
						//return;

						reject({
							status:      "error",
							errMessage:  err.message || JSON.stringify(err)
						});
					}

					//console.log('session validity: ' + session.isValid());
			
					// NOTE: getSession must be called to authenticate user before calling getUserAttributes
					cognitoUser.getUserAttributes(function(err, attributes) {
						if (err) {
							// Handle error
							reject({
								status:      "error",
								errMessage:  err.message || JSON.stringify(err)
							});
						} else {

							// Do something with attributes
							//console.log(attributes);
							email = attributes[4].Value;

							//POTENTIAL: Region needs to be set if not already set previously elsewhere.
							AWS.config.region = 'us-east-1';
					
							AWS.config.credentials = new AWS.CognitoIdentityCredentials({
								IdentityPoolId: IdentityPoolId, 		// your identity pool id here
								Logins: {
									// Change the key below according to the specific region your user pool is in.
									'cognito-idp.us-east-1.amazonaws.com/us-east-1_H5lBHE6l4': session
										.getIdToken()
										.getJwtToken(),
								},
							});
					
							

							//refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
							AWS.config.credentials.refresh( async(error) => {
								if (error) {
									console.error(error);

									reject({
										status:      "error",
										errMessage:  error.message || JSON.stringify(error)
									});

								}
								else {

									//console.log(email);

									// Get the Users name based on the logged in user email.
									// eslint-disable-next-line no-lone-blocks
									{	var res = await fetch("https://oidnlpynvl.execute-api.us-east-1.amazonaws.com/prod/accountstatus", {
											method: 'POST',
											/* mode: 'no-cors', */
											body: JSON.stringify({
												"email":     email
											})
										});

										// Get the response from the Backend.
										var response = await res.json();
										
										//console.log(response);

										//console.log(response.body.response.Item.name);

										// Proceed only if the Response StatusCode=201.
										if(response.statusCode === 201)
										{
											// Login successful.
											// setState({email: refreshedCredentials.email, name: response.body.response.Item.name, loginstatus: 2});
											// loggedinUsersEmail = refreshedCredentials.email;
											resolve({
												status:         "success",
												credentials:	AWS.config.credentials,
												email:			email,
												name:			response.body.response.Item.name
											})
										}
									}
								}
							});
						}
					});

				});

			}
			else{
				reject({
					status:      "error",
					// errMessage:  "err.message || JSON.stringify(err)"
				});
			}
		});
}


/**
 * Create new user. This is temporary, as it exposes the AWS related details. We have tried using Lambda, but it's no working. We will get back to it later.
 **/ 
export function cognitoAuthenticateUser(props)
{
    // This variable will hold the AWS Credentials for the user to acces the AWS services.
    let AWSCredentials;

    //console.log("authenticate");
    
    var authenticationData = {
    	Username:  props.username,
    	Password:  props.password,
    };
    
    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    
    var poolData = {
	    UserPoolId:     UserPoolId,                                 // Your user pool id here.
	    ClientId:       ClientId                                    // Your client id here.
    };
    
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    
    var userData = {
    	Username:   props.username,
    	Pool:       userPool,
    };
    
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) =>
            cognitoUser.authenticateUser(authenticationDetails, {
				// If the provided credentials are correct.
            	onSuccess: function(result) {
            		var accessToken = result.getAccessToken().getJwtToken();
					var login = 'cognito-idp.' + Region + '.amazonaws.com/' + UserPoolId;
            
            		//POTENTIAL: Region needs to be set if not already set previously elsewhere.
            		AWS.config.region = 'us-east-1';
            
            		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            			IdentityPoolId: IdentityPoolId,           // Your identity pool id here.
            			Logins: {
            				// Change the key below according to the specific Region your User Pool is in.
            				//'cognito-idp.us-east-1.amazonaws.com/us-east-1_qYdRYNIjq': result
							'cognito-idp.us-east-1.amazonaws.com/us-east-1_H5lBHE6l4': result
							//login : result
            					.getIdToken()
            					.getJwtToken(),
            			},
            		});
            		
            		//Send back the STRINGIFIED AWS.config.credentials to the Frontend, so that in the Frontend we can use it to access AWS services.
            		//console.log(JSON.stringify(AWS.config.credentials));
            		// resolve(
					// 	// response = {
					// 	// 	statusCode:     200,
					// 	// 	body:           JSON.stringify(AWS.config.credentials)
					// 	//}
					// 	//JSON.stringify(AWS.config.credentials)
                         //console.log(JSON.stringify(AWS.config.credentials))
					// );
            
            		//refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
            		AWS.config.credentials.refresh( async(error) => {
            			if (error) {
            				console.error(error);

							reject({
								// response = {
								// 	statusCode:     400,
								// 	body:           {
								// 		error:	err.message || JSON.stringify(err)
								// 	}
								// }
								status:      "error",
								errMessage:  error.message || JSON.stringify(error),
									
							});

            			} else {
            				// Instantiate aws sdk service objects now that the credentials have been updated.
            				// example: var s3 = new AWS.S3();
							//var s3 = new AWS.S3();
            				//console.log('Successfully logged!', AWS.config.credentials, s3);
							//resolve(JSON.stringify(AWS.config.credentials));
							//resolve(JSON.stringify(s3));

							// resolve(
							// 	response = {
							// 		statusCode:     200,
							// 		body:           JSON.stringify(AWS.config.credentials)
							// 	}
							//JSON.stringify(AWS.config.credentials)
                            //AWSCredentials  =   AWS.config.credentials;
                            //console.log(AWS.config.credentials)

							// Get the Users name based on the logged in user email.
							// eslint-disable-next-line no-lone-blocks
							{	var res = await fetch("https://oidnlpynvl.execute-api.us-east-1.amazonaws.com/prod/accountstatus", {
									method: 'POST',
									/* mode: 'no-cors', */
									body: JSON.stringify({
										"email":     props.username
									})
								});

								// Get the response from the Backend.
								var response = await res.json();

								//console.log(response.body.response.Item.name);

								// Proceed only if the Response StatusCode=201.
								if(response.statusCode === 201)
								{
									// Login successful.
									// setState({email: refreshedCredentials.email, name: response.body.response.Item.name, loginstatus: 2});
									// loggedinUsersEmail = refreshedCredentials.email;
									resolve({
										status:         "success",
										credentials:	AWS.config.credentials,
										email:			props.username,
										name:			response.body.response.Item.name
									})
								}
							}
            			}
            		});
            	},
            
				// If the provided credentials are incorrect.
            	onFailure: function(err) {
            		console.log(err);
            		reject({
						// response = {
						// 	statusCode:     400,
						// 	body:           {
						// 		error:	err.message || JSON.stringify(err)
						// 	}
						// }
						status:      "error",
						errMessage:  err.message || JSON.stringify(err),
							
					});
            	},
            })
    );
}

//export default cognitoAuthenticateUser;