/* eslint-disable no-lone-blocks */
import {React, Component, useState, useEffect} from "react";
import { Button, Navbar, Nav, NavDropdown, Form, FormControl, Badge, InputGroup, Row, Col, Image, Jumbotron, Card, DropdownButton, Dropdown, Modal, Container, Spinner, Alert, ToastContainer, Toast} from 'react-bootstrap';
import { Formik } from "formik";
import * as yup from 'yup';
import { propTypes } from "react-bootstrap/esm/Image";

import '../styling/stripe.css'


import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import AlertDismissible from '../helper_classes/alert.js'
import MsgBox from './ModalMessageBox'
 
import success  from '../icons/check.png'
import cross  from '../icons/cross.png'
import warning from '../icons/warning.png'

// From "https://github.com/yosriady/serverless-stripe-frontend/blob/master/components/PayButton.js"
import config from '../stripe_config.js';
import XenkiaAWSconfig from '../config.js'

import carte_bancaire from '../icons/bankscards/carte-bancaire-480x300.png'
import master_card from '../icons/bankscards/icons8-mastercard-96.png'
import visa_card from '../icons/bankscards/icons8-visa-96.png'
import jcb_card from '../icons/bankscards/icons8-jcb-96.png'
import amex_card from '../icons/bankscards/icons8-american-express-96.png'


// Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51IY42qKaj3wxayY5OjvguoZgoRZfuoie4e6JSmmfrKvSkX0P7hUdLU3BmLSjSS5TqFquCu2vvGf0Oj6AYXP53Jv000ZCTm58wb');




// Stripe form Rendering and Payment processing.
function StripeProcessing(props){

    return(
        <div>
            <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                <StripeForm
                  processPayment        = {props.processPayment}
                  userInfo              = {props.userInfo}
                />
            </Elements>

        </div>
    )
}



// Stripe Form.
function StripeForm(props){

    const stripe                                                = useStripe();
    const elements                                              = useElements();
    const [error, setError]                                     = useState(null);
    const [cardComplete, setCardComplete]                       = useState(false);
    const [processing, setProcessing]                           = useState(false);
    const [paymentMethod, setPaymentMethod]                     = useState(null);
    const [paymentDisabled, setPaymentDisabled]                 = useState(true);
    const [paymentSuccesful, setPaymentSuccesful]               = useState(false);
    
    const [paymentProcessingError, setPaymentProcessingError]   = useState(false);
    const [paymentErrorMsg, setPaymentErrorMsg]                 = useState("");

    const [discountCoupon, setDiscountCoupon]                   = useState("");
    const [verifyingDiscountCoupon, setVerifyingDiscountCoupon] = useState(false);
    const [discountPercentage, setDiscountPercentage]           = useState({
      discountPercentage:   0.0,
      verified:             false
    });
    const [invalidCoupon, setInvalidCoupon]                     = useState(false);
    const [verifyButtonDisabled, setVerifyButtonDisabled]       = useState(true);

    const [selectedPackage, setSelectedPackage]                 = useState({
      accountType:          'Individual',
      pricingPlan:          'Starter',
      billingCycle:         'Monthly',
      packageCost:          0,
      features:             ''
    });

    const [retrievedPkgInfo, setRetrievedPkgInfo]                 = useState({
      packageCost:          0,
      discount:             0,
      features:             '',
      gettingPackageInfo:   false
    });


    const [registering, setStarterPackRegistered]                         = useState(true);

    // Yearly subscriptions have 30% discount on Signup.
    const [yearlyDiscount, setYearlyDiscount]                   = useState({
      applicable:   false,
      percentage:   0
    })



    //console.log(props.userInfo);

    /**
     * Process the payment based on the TOKEN generated by Stripe and then Proceed with User Registration if it was a success.
     * @param {*} token 
     */
    const onToken = async (token) => {// 'token' returned from Stripe.
      
        // Disable payment button.
        setPaymentDisabled(true);

        const res = await fetch(config.stripe.apiUrl, { // Backend API url
          method: 'POST',
          body: JSON.stringify({
              token,
              userInfo:       props.userInfo,
              accountInfo:    {
                accountType:  selectedPackage.accountType,
                pricingPlan:  selectedPackage.pricingPlan,
                billingCycle: selectedPackage.billingCycle
              },
              discountToken:  discountCoupon
            }),
        });
        
        // Get the response from the Backend.
        const response = await res.json();

        //console.log(response);

        // In case of an error, allow the customer to correct it and try paying again.
        if(response.body.status === 'error') setProcessing(false);

        // Based on the status, next step will be taken.
        return response.body;
    }


    /**
     * This function will reset the card.
     */
    const resetCardDetails = () => {
        setError(null);
        setProcessing(false);
        setPaymentMethod(null);
      };



    /**
     * What to do when the Submit button is pressed.
     */
    const HandleSubmit = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      //console.log(props.userInfo.pricingPlan.toLowerCase());
  
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
  
      if (error) {
        elements.getElement("card").focus();
        return;
      }
  
      if (cardComplete) {
        setProcessing(true);
      }
  
      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card);

      if (result.error) {
        // Show error to your customer.
        //console.log(result.error.message);
        console.log("ERROR: Token could not be generated");
      }
      else {
        // Show error to your customer.
        //console.log(result.error.message);
        
        //console.log("SUCCESS: Token generated");

        // Now send the TOKEN to the server and make the payment.
        {
          let retPayment;

          //console.log(result.token);
          retPayment = await onToken(result.token);

          if(retPayment.status === "error")
          {
            //console.log("ERROR: ", retPayment.error);

            // Show Alert() message.
            setPaymentErrorMsg(retPayment.error);
            setPaymentProcessingError(true);            
          }
          else if (retPayment.status === "success")
          {
            //console.log("SUCCESS: ", retPayment.message);

            // Mark that the payment was successful.
            setPaymentSuccesful(true);

            // Reset the Payment details.
            resetCardDetails();
          }
        }
      }
    }



    const SubmitButton = ({ processing, error, children, disabled }) => (
      <div style={{textAlign: 'justify'}}>
        <Button
        className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
        type="submit"
        disabled={processing || disabled || !cardComplete}
        onClick={HandleSubmit}
        style={{padding: 0, boxShadow: '1px 1px 8px grey'}}
      >
        {processing
          ? 
            <Spinner animation="grow" />
          : 
            children
          }
      </Button>
          
      <br/>
      <div style={{marginTop: '5%'}}>
        {yearlyDiscount.applicable
          ?  
            <b style={{textAlign: 'justify',fontSize: '1rem', color: 'red'}}> ** {yearlyDiscount.percentage}% yearly discount on subscription applied</b>
          :
            <></>
        }

        {discountPercentage.verified
          ?  
            <b style={{textAlign: 'justify',fontSize: '1rem', color: 'red'}}> ** {discountPercentage.discountPercentage}% discount on coupon has been applied</b>
          :
            <></>
        }
      </div>
    </div>
   );



  /**
   * This function will register the user if he/she has selected Starter Pack.
   * @param {*} token 
   */
  const registerStarterPackUser = async () => {
      

      const res = await fetch(config.stripe.apiUrl, { // Backend API url
        method: 'POST',
        body: JSON.stringify({
            token:          "FAKE",                   // Dummy Stripe token.
            userInfo:       props.userInfo,
            accountInfo:    {
              accountType:  selectedPackage.accountType,
              pricingPlan:  selectedPackage.pricingPlan,
              billingCycle: selectedPackage.billingCycle
            },
            discountToken:  "FAKE"                    // Dummy Discount Coupon.
          }),
      });

    
      // Get the response from the Backend.
      const response = await res.json();

      console.log(response);

      // In case of an success, allow the customer to correct it and try paying again.
      if(response.body.status.toLowerCase() === 'success') setStarterPackRegistered(false);

      // Based on the status, next step will be taken.
      return response;
  }


  /**
   * Get the Package details.
   */
   const getPackageInfo = async() => {

    setRetrievedPkgInfo( prevState=> {return{...prevState, gettingPackageInfo: true}} );

    const res = await fetch(XenkiaAWSconfig.packages.url, { // Backend API url
        method: 'POST',
        body: JSON.stringify({
              packageName:    selectedPackage.pricingPlan.toLowerCase(),
              billingCycle:   selectedPackage.billingCycle.toLowerCase(),
            }),
      });
      
      // Get the response from the Backend.
      const data = await res.json();

      //console.log(data.body.packageDetails.cost, data.body.packageDetails.features);

      // Update state.
      setRetrievedPkgInfo( prevState=> {return{...prevState, packageCost: data.body.packageDetails.cost, features: data.body.packageDetails.features, gettingPackageInfo: false, discount: data.body.packageDetails.discount}} );

      // Apply any pacakge related discounts.
      if(data.body.packageDetails.discount > 0) setYearlyDiscount(prevState=> {return{...prevState, applicable: true,  percentage: data.body.packageDetails.discount}});
      else setYearlyDiscount(prevState=> {return{...prevState, applicable: false, percentage: 0}});

      // Based on the status, next step will be taken.
      //return data.body.packageDetails.cost;

      //setSelectedPackageCost(data.body.packageDetails.cost);
      
  };

  /**
   * Update the Pacakge info, as new package is selected by the user.
   */
   useEffect(() => {
    // Obtain info about the selected package from the server.
    getPackageInfo();

    //console.log(selectedPackage, retrievedPkgInfo, yearlyDiscount);

  }, [selectedPackage])







  return(
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh'}}>
      {props.userInfo !== null
      ?
          <div>
            {paymentSuccesful
              ?
                <div>
                  <img src={success} alt="success" height="64px"></img>
                  <h1> Payment was successful!</h1>
                  <h4> You have been registered </h4>
                  <hr/>
                  <h5> <i>A verification email was sent to <b>{props.userInfo.email}</b>. Please click the provided link to activate your account.</i> </h5>
                  <hr/>
                  <Button variant="success" onClick={()=> window.location.assign(window.location.origin)}> <b>Return to Home</b> </Button>
                </div>
              :
                <>
                  <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                  <h3 style={{textAlign: 'justify'}}> Select a package</h3>
                  <hr/>

                  <Container fluid="md" style={{paddingLeft: '0px', paddingRigh: '0px'}}>
                    <Row>
                      <Col>
                        <InputGroup className="mb-3" md="4" as={Col} style={{minWidth: '350px'}}>
                            <DropdownButton
                                as={InputGroup.Prepend}
                                variant="outline-success"
                                title="Package type"
                                id="input-group-dropdown-account-type"
                            >
                              <Dropdown.Item id="accountType_Personal" onClick={() => setSelectedPackage( prevState=> {return{...prevState, accountType: 'Individual'}} )}>Individual</Dropdown.Item>
                              <Dropdown.Item id="accountType_Company" onClick={() => setSelectedPackage( prevState=> {return{...prevState, accountType: 'Company'}} )}>Company</Dropdown.Item>
                            </DropdownButton>
                            <Form.Control 
                                aria-describedby="basic-addon1"
                                value={selectedPackage.accountType}
                                // isInvalid={errors.accountType}
                                readOnly
                            />
                        </InputGroup>
                      </Col>
                      

                      <Col>
                        <InputGroup className="mb-3" md="4" as={Col} style={{minWidth: '350px'}}>
                            <DropdownButton
                                as={InputGroup.Prepend}
                                variant="outline-primary"
                                title="Pricing Plan"
                                id="input-group-dropdown-pricing-plan"
                            >
                              <Dropdown.Item id="1" onClick={() => setSelectedPackage( prevState=> {return{...prevState, pricingPlan: 'Starter'}} )}>Starter</Dropdown.Item>
                              <Dropdown.Item id="2" onClick={() => setSelectedPackage( prevState=> {return{...prevState, pricingPlan: 'Personal'}} )}>Personal</Dropdown.Item>
                              <Dropdown.Item id="3" onClick={() => setSelectedPackage( prevState=> {return{...prevState, pricingPlan: 'Professional'}} )}>Professional</Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item id="4" onClick={() => setSelectedPackage( prevState=> {return{...prevState, pricingPlan: 'Small Business'}} )}>Small Business</Dropdown.Item>
                              <Dropdown.Item id="5" onClick={() => setSelectedPackage( prevState=> {return{...prevState, pricingPlan: 'Medium Business'}} )}>Medium Business</Dropdown.Item>
                              <Dropdown.Item id="6" onClick={() => setSelectedPackage( prevState=> {return{...prevState, pricingPlan: 'Large Business'}} )}>Large Business</Dropdown.Item>   
                            </DropdownButton>
                            <Form.Control 
                                aria-describedby="basic-addon1"
                                value={selectedPackage.pricingPlan}
                                // isInvalid={errors.pricingPlan}
                                readOnly
                            />
                        </InputGroup>
                      </Col>

                      <Col>
                        <InputGroup className="mb-3" md="4" as={Col} style={{minWidth: '350px'}}>
                            <DropdownButton
                                as={InputGroup.Prepend}
                                variant="outline-secondary"
                                title="Billing Cycle"
                                id="input-group-dropdown-billing-cycle"
                            >
                              <Dropdown.Item id="1" onClick={() => setSelectedPackage( prevState=> {return{...prevState, billingCycle: 'Monthly'}} )}>Monthly</Dropdown.Item>
                              <Dropdown.Item id="2" onClick={() => setSelectedPackage( prevState=> {return{...prevState, billingCycle: 'Yearly'}} )}>Yearly</Dropdown.Item>
                            </DropdownButton>
                            <Form.Control 
                                aria-describedby="basic-addon1"
                                value={selectedPackage.billingCycle}
                                // //value={values.billingCycle ? values.billingCycle : defaultBillingCycle}
                                // isInvalid={errors.billingCycle}
                                readOnly
                            />
                        </InputGroup>
                      </Col>
                    </Row>

                    <br/>

                    <Row>
                      {retrievedPkgInfo.gettingPackageInfo
                        ?
                          <div style={{paddingLeft: '1rem'}}>
                            <Spinner animation="border" variant="success" style={{fontSize: '1rem'}}/>
                          </div>
                        : 
                        <div style={{backgroundColor: '#F2F5F7', borderRadius: '1rem', boxShadow: '1px 1px 5px #949EA7'}}>
                            <h3> {selectedPackage.pricingPlan} package </h3>
                            <div style={{marginLeft: '3rem'}}>
                              <br/>
                              <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: retrievedPkgInfo.features[8]}} />
                              <br/>
                              <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: retrievedPkgInfo.features[9]}} />
                              <br/>
                              <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: retrievedPkgInfo.features[7]}} />
                              <br/>
                              <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: retrievedPkgInfo.features[0]}} />
                              <br/>
                              <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: retrievedPkgInfo.features[4]}} />
                              <br/>
                              <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: retrievedPkgInfo.features[1]}} />
                              <br/>
                              <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: retrievedPkgInfo.features[6]}} />
                              <br/>
                              <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: retrievedPkgInfo.features[3]}} />
                              <br/>
                              <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: retrievedPkgInfo.features[2]}} />
                              <br/>
                              <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: retrievedPkgInfo.features[5]}} />
                              <br/>
                            </div>
                        </div>
                      }
                    </Row>
                  </Container>


                    {selectedPackage.pricingPlan !== "Starter"
                    ?
                      <div>

                      <br/> <br/>
                      <h3 style={{textAlign: 'justify'}}> Enter card details</h3>
                      <hr/>
                        {/* <Button variant="outline-light" style={{position: 'absolute', left: '60%', top: '30%'}}> <img src={cross}  height='32px'></img> </Button> */}
                        <form className="Form">
                            {paymentProcessingError
                              ?
                                <AlertDismissible
                                    alertHeading="Oh snap! You got an error!"
                                    alertMessage={paymentErrorMsg}
                                    alertVariant="danger"
                                />
                              : <> </>
                            }

                            <div style={{backgroundColor: '#EFFCFF', paddingTop: '5rem', paddingBottom: '3rem', boxShadow: '1px 1px 10px grey', borderRadius: '1rem'}}>
                                <fieldset className="FormGroup" style={{maxWidth: '480px', marginLeft: 'auto', marginRight: 'auto'}}>
                                    <CardField
                                      onChange={(e) => {
                                          setError(e.error);
                                          setCardComplete(e.complete);
                                        }
                                      }
                                    />
                                </fieldset>
                                <div>
                                  <img src={master_card} alt='mastercard' height='32' style={{marginLeft: '0.5rem', marginRight: '0.5rem'}}/>
                                  <img src={visa_card} alt='mastercard' height='32' style={{marginLeft: '0.5rem', marginRight: '0.5rem'}}/>
                                  <img src={amex_card} alt='mastercard' height='32' style={{marginLeft: '0.5rem', marginRight: '0.5rem'}}/>
                                  <img src={jcb_card} alt='mastercard' height='32' style={{marginLeft: '0.5rem', marginRight: '0.5rem'}}/>
                                  <img src={carte_bancaire} alt='mastercard' width='32' style={{marginLeft: '0.5rem', marginRight: '0.5rem'}}/>
                                </div>
                                <br/>
                                <text className="default-font" style={{color: '#F44336', margin:  0, padding: 0, fontSize: '1rem'}}> <b>Note:</b> <i>ZIP is your the postal code registered with your bank against this card.</i> </text>
                            </div>


                            <br/><br/>

                            <div style={{textAlign: "justify", marginLeft: '3rem'}}>
                              <b style={{fontSize: '1.2rem'}}> Discount Coupon&nbsp;&nbsp;</b>
                              <Form className="d-flex" style={{display: 'flex', alignItems: 'center', maxWidth: '30rem', width: '30rem'}} onSubmit={e => { e.preventDefault(); }}>
                                  <Form.Control
                                    id = 'discount-coupon-textbox'
                                    type="text"
                                    placeholder="Enter coupon code"
                                    name="password"
                                    value={discountCoupon}
                                    style={{maxWidth: '60%'}}
                                    onChange={(e) => {
                                      setDiscountCoupon(e.target.value);
                                      if(e.target.value.length > 0) setVerifyButtonDisabled(false);
                                      else setVerifyButtonDisabled(true);

                                      // This is to make sure that if the user changes/deletes the coupon after verification, it won't show that the discount was applied, when it actualy wasn't.
                                      setDiscountPercentage({verified: false, discountPercentage: 0});
                                      setInvalidCoupon(false);
                                    }}
                                  />
                                  <Button id='coupon-verify-button' variant="outline-success" disabled={verifyButtonDisabled} onClick={async()=> {

                                        // Spinner disappears as soon as the response appears.
                                        setVerifyingDiscountCoupon(true);

                                        const res = await fetch(XenkiaAWSconfig.discount.verifyCoupon, { // Backend API url
                                          method: 'POST',
                                          body: JSON.stringify({
                                              token:                              document.getElementById('discount-coupon-textbox').value
                                            }),
                                        });
                                        
                                        // Get the response from the Backend.
                                        const response = await res.json();
                                
                                        // Spinner disappears as soon as the response appears.
                                        setVerifyingDiscountCoupon(false);
                                
                                        //console.log(response);
                                
                                        // In case of an error, allow the customer to correct it and try paying again.
                                        if(response.statusCode === 202){
                                          //setEmailAlreadyRegistered(false);
                                          alert(response.body.msg);

                                          // <MsgBox
                                          //   title= 'Success'
                                          //   body= 'Test'
                                          // />

                                          setDiscountPercentage({verified: true, discountPercentage: response.body.discountPercentage});
                                          setInvalidCoupon(false);
                                        }
                                        else 
                                        {
                                          //setEmailAlreadyRegistered(true);
                                          alert(response.body.msg);

                                          setDiscountPercentage({verified: false, discountPercentage: 0});
                                          setInvalidCoupon(true);
                                        }
                                      }
                                  }> 
                                    {verifyingDiscountCoupon
                                      ?
                                        <Spinner animation="border" variant="success" style={{fontSize: '1rem'}}/>
                                      :
                                        <>Apply</>
                                    }
                                  </Button> &nbsp; &nbsp; &nbsp;

                                  {(invalidCoupon) 
                                    ? <img src={warning} alt='warning-message' height='24px'/>
                                    : <></>
                                  }
                              </Form>
                            </div>

                            <div style={{justifyContent: 'center', marginTop: '1%', marginLeft: 'auto', marginRight: 'auto', minWidth: '350px', maxWidth: '30%'}}>
                              {error && <ErrorMessage>{error.message}</ErrorMessage>}
                              <SubmitButton processing={processing} error={error} disabled={!stripe && retrievedPkgInfo.packageCost}>
                                  Pay {!retrievedPkgInfo.gettingPackageInfo
                                      ?
                                          yearlyDiscount.applicable
                                          ?
                                            <>
                                              {discountPercentage.verified
                                                ?
                                                  <b style={{fontSize: '135%'}}> &nbsp;<s style={{color: '#FF5722'}}> ${retrievedPkgInfo.packageCost}</s> &nbsp;
                                                    ${ ((Number(retrievedPkgInfo.packageCost) * (1 - Number(discountPercentage.discountPercentage)/100)) * (1- Number(yearlyDiscount.percentage)/100)).toFixed(2) } USD
                                                  </b>
                                                :
                                                  <b style={{fontSize: '135%'}}> <s style={{color: '#FF5722'}}> ${retrievedPkgInfo.packageCost}</s> &nbsp; ${ (Number(retrievedPkgInfo.packageCost) * (1- Number(yearlyDiscount.percentage)/100)).toFixed(2) } USD</b>
                                              }
                                            </>
                                          :
                                            <>
                                              {discountPercentage.verified
                                                ?
                                                  <b style={{fontSize: '135%'}}> &nbsp;<s style={{color: '#FF5722'}}> ${retrievedPkgInfo.packageCost}</s> &nbsp; 
                                                      ${ (Number(retrievedPkgInfo.packageCost) * (1 - Number(discountPercentage.discountPercentage)/100)).toFixed(2) } USD
                                                  </b>
                                                :
                                                  <b style={{fontSize: '135%'}}> ${retrievedPkgInfo.packageCost} USD</b>
                                              }
                                        </>
                                      :
                                        <Spinner animation="border" variant="success"/>
                                      }
                              </SubmitButton>
                            </div>
                        </form>
                        <br/><br/>
                      </div>
                    :
                      // If STARTER package was selected by the user.
                      <div>
                        {registering
                        ? <div style={{marginTop: '3rem'}}> 
                            {/* <Spinner animation="border" variant="success"/>
                            <p style={{fontSize: '200%'}}>Registering, please wait... </p> */}
                            <Button variant='success' size='lg' onClick={() => registerStarterPackUser()}> Subscribe </Button>
                          </div>
                        :
                          <>
                            <img src={success} alt="success" height="64px"></img>
                            <h4> You have been registered </h4>
                            <hr/>
                            <h5> <i>A verification email was sent to <b>{props.userInfo.email}</b>. Please click the provided link to activate your account.</i> </h5>
                            <hr/>
                            <Button variant="success" onClick={()=> window.location.assign(window.location.origin)}> <b>Return to Home</b> </Button>
                          </>
                        }
                      </div>
                    }
                </>
            }
          </div>
        :
          <div>
            <p style={{fontSize: '200%'}}>Loading...</p>
          </div>
        }
    </div>
    );
  }






const ELEMENTS_OPTIONS = {
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
      }
    ]
  };

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#fff",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883"
        },
        "::placeholder": {
          color: "#87bbfd"
        }
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee"
      }
    }
  };


  const CardField = ({ onChange }) => (
    <div className="FormRow">
      <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
  );
  
  const Field = ({
    label,
    id,
    type,
    placeholder,
    required,
    autoComplete,
    value,
    onChange
  }) => (
    <div className="FormRow">
      <label htmlFor={id} className="FormRowLabel">
        {label}
      </label>
      <input
        className="FormRowInput"
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
      />
    </div>
  );
  
  
  const ErrorMessage = ({ children }) => (
    <div className="ErrorMessage" role="alert">
      <svg width="16" height="16" viewBox="0 0 17 17">
        <path
          fill="#FFF"
          d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
        />
        <path
          fill="#6772e5"
          d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
        />
      </svg>
      {children}
    </div>
  );
  
  const ResetButton = ({ onClick }) => (
    <button type="button" className="ResetButton" onClick={onClick}>
      <svg width="32px" height="32px" viewBox="0 0 32 32">
        <path
          fill="#FFF"
          d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
        />
      </svg>
    </button>
  );



export default StripeProcessing;