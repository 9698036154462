
import {React, Component, useState, useEffect} from "react";
import { Alert} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';


// Show error via an Alert message.
function AlertDismissible(props) {
  const [show, setShow] = useState(true);

/*   useEffect(() => {
    if (message) {
      let timer = setTimeout(() => {
        setAlert(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]); */

    return (
      <Alert show={show} variant={props.alertVariant}>
        <Alert.Heading>{props.alertHeading}</Alert.Heading>
        <p>
          {props.alertMessage}
        </p>
      </Alert>
    );

}

export default AlertDismissible;