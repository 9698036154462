
import {React, Component} from "react";
// import { Auth, autoShowTooltip, Storage} from 'aws-amplify';
// import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { Button, ProgressBar, Card, Container, Row, Col, Jumbotron} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styling/unsubscribe.css';
import XenkiaAWSconfig from '../config'


// Delete the user from the Newsletter Subscription Database.
async function unsubscribe(email){
    let res = await fetch(XenkiaAWSconfig.newsletterSubscription.unsubscribe, {
        method: 'POST',
        /* mode: 'no-cors', */
        body: JSON.stringify({
            "email":  email
        })
    })

    // Get the response from the Backend.
    //let response = await res.json();
    //console.log(response);
}


// Main class.
class Unsubscribe extends Component {
    
    state ={
        email:              ""
    };

    // Get the email to unsubscribe, from the url.
    UNSAFE_componentWillMount(){
        this.setState({ email:   this.props.match.params.email });
    }




    render() {

        // Unsubscribe it from the database.
        unsubscribe(this.props.match.params.email);

        return(
            <div className="upload-login-error">
                <img height='128px' width='128px' src="https://xenkia-web-assets.s3.amazonaws.com/sad.png" alt="sad"></img>
                <h1> <u><i> {this.state.email} </i></u> have been <i>unsubscribed</i> from our newsletter service. </h1>
                <h3 style={{color: 'grey'}}> Xenkia is sorry to see you go! </h3>
            </div>
        );
    }    
}


export default Unsubscribe;
