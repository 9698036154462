/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-lone-blocks */
import {React, Component, useState, useEffect, useRef} from "react";
// import { Auth, Storage} from 'aws-amplify';
import { Button, ProgressBar, Card, Container, Row, Col, Jumbotron, Toast, Spinner, Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
//import './upload.css';

// import entire SDK
import AWS from 'aws-sdk'
import {s3Delete, s3Upload, __s3FileStreamDownload} from '../helper_classes/AWSS3'


import {Buffer} from 'buffer';

import config from '../config'

import check from '../icons/check.png'
import error from '../icons/cancel_1.png'
import warning from '../icons/warning.png'
import individual from '../icons/individual.png'
import company from '../icons/enterprise.png'
import unauthorized from '../icons/unauthorized.png'


// Currently logged in user's email address.
import {loggedinUsersEmail as loggedin_user_email} from '../App.js';



/**
   * All the Upload from related logic will go here.
   * @returns 
   */
 const UserProfile = () => {
 
    // This State variable will store the Profile Picture in Base64 format. As soon as it gets updated, the picture gets displayed.
    const [profilePictureBase64, setProfilePictureBase64] = useState(null);

    // This State variable will hold all the user details.
    const [accountStatus, setAccountStatus] = useState({
            email:                  "",
            name:                   "",
            address:                "",
            phone_number:           "",
            secondary_email:        "",
            pricing_package:        "",
            billing_cycle:          "",
            package_cost:           "",
            expiry_date:            "",
            max_view_allowed:       "",
            max_uploads_allowed:    "",
            max_storage_space:      "",
            max_file_size:          "",
            download_counter:       "",
            upload_counter:         "",
            storage_space_used:     "",
            subscriptionDate:       "",
            lastActive:             "",
            account_type:           "",
            image_icon_uploaded:    ""
    });

    // Related to the 'Toast'.    
    const [show, setShow] = useState(true);

    // Upload button Enable/Disable.
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);

    // This flag marks whether the User Account Status values have been loaded from remote server.
    const [userAccountStatusLoaded, setUserAccountStatusLoaded] = useState(false);

    // Currently logged in User Identity (Cognito Identity Pool).
    let user_id = "";
    const [userId, setUserId] = useState("");

    var b64IMG;


    // Convert byte array to file.
    // Note: https://stackoverflow.com/questions/23451726/saving-binary-data-as-file-using-javascript-from-a-browser
    var saveByteArray = (function () {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        return function (data, name) {
            var blob = new Blob(data, {type: "text/html"}),
                url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = name;
            a.click();
            window.URL.revokeObjectURL(url);
        };
    }());


    /**
     * This runs once at the beginning. Source: https://stackoverflow.com/questions/53464595/how-to-use-componentwillmount-in-react-hooks
     */
     useEffect(() => {
        
        getUserAccountStatus();         // Get User Account Status.

/*         // Get the User Identity. Based on this we will generate the QR Code so that the App can know in which folder it can find the models.
        Auth.currentUserInfo().then((userInfo) => {
            if (userInfo) 
            {
                user_id = encodeURIComponent(userInfo.id);
                setUserId(user_id);
            }
        }); */

        //console.log(0.79*Number(accountStatus.max_uploads_allowed));
      }, []);


    
    /** 
     * Get use account status. 
     */
    const getUserAccountStatus = async() =>
    {
        //console.log(loggedin_user_email);

        // Add the User to the database.
        var res = await fetch("https://oidnlpynvl.execute-api.us-east-1.amazonaws.com/prod/accountstatus", {
            method: 'POST',
            /* mode: 'no-cors', */
            body: JSON.stringify({
                "email":     loggedin_user_email
            })
        });

        // Get the response from the Backend.
        var response = await res.json();

        //console.log(response.body);

        // Proceed only if the Response StatusCode=201.
        if(response.statusCode === 201)
        {
            setAccountStatus({
                email:                  response.body.response.Item.email,
                name:                   response.body.response.Item.name,
                address:                response.body.response.Item.address,
                phone_number:           response.body.response.Item.phone_number,
                secondary_email:        response.body.response.Item.secondary_email,
                pricing_package:        response.body.response.Item.pricing_package,
                billing_cycle:          response.body.response.Item.billing_cycle,
                package_cost:           response.body.response.Item.package_cost,
                expiry_date:            response.body.response.Item.expiry_date,
                max_view_allowed:       response.body.response.Item.max_view_allowed,
                max_uploads_allowed:    response.body.response.Item.max_uploads_allowed,
                max_storage_space:      response.body.response.Item.max_storage_space,
                max_file_size:          response.body.response.Item.max_file_size,
                download_counter:       response.body.response.Item.download_counter,
                upload_counter:         response.body.response.Item.upload_counter,
                storage_space_used:     response.body.response.Item.storage_space_used,
                subscriptionDate:       response.body.response.Item.subscriptionDate,
                lastActive:             response.body.response.Item.lastActive,
                account_type:           response.body.response.Item.account_type,
                image_icon_uploaded:    response.body.response.Item.image_icon_uploaded
            });


            // If the Icon was uploaded by the user, then display it in the browser.
            if(response.body.response.Item.image_icon_uploaded === true)
            {
                // Download the profile picture to the DB.
                __s3FileStreamDownload({
                    bucketName:   'xenkiaassetbucket',
                    subPath:      "profilePicture/profile-picture.jpg"
                }).then(async(dataStream) => {

                    //console.log(dataStream.data.Body.toString('utf-8'));

                    //b64IMG = Buffer.from(dataStream.data.Body).toString('base64');

                    // Save the Profile Picture as Base64 stream, sot hat it can be displayed in the browser.
                    // Note: https://www.w3docs.com/snippets/html/how-to-display-base64-images-in-html.html
                    setProfilePictureBase64(Buffer.from(dataStream.data.Body).toString('base64'));

                    //saveByteArray(dataStream.data.Body.toString('utf-8'), 'profile-picture.jpg');
                }).catch((e) => {
                    console.log("error", e);
                });
            }

            // Mark that the information related to User Account has been successfully loaded.
            setUserAccountStatusLoaded(true);
        }
    }


    /**
     * This function will mark in the remote database that the "Profile Picture" of the user has been uploaded.
     */
    const updateProfilePictureUploadedDB = async() => {

        // Incease the upload counter by 1 in the remote database..
        var res = await fetch("https://oidnlpynvl.execute-api.us-east-1.amazonaws.com/prod/increaseviewcount", {
            method: 'POST',
            //mode: 'no-cors',
            body: JSON.stringify({
                "email":                loggedin_user_email,
                "field_to_update":      "image_icon_uploaded",
                "value":                true,
                "inc_dec_reset":        "update"
            })
        })

        // Get the response from the Backend.
        var response = await res.json();

        // Only update the Upload counter if the remote Upload counter was successfully incremented.
        if(response.statusCode === 201)
        {
            // Update the Storage Space Used value after uploading.
            console.log("Remote DB flag was set successfully.");
        }
    }


    /**
     * File selection for upload.
     * @param {*} e 
     */
    const selectProfilePicture = (e) => {

        // Before doing anything, first make sure that a file was selected.
        if(e.target.files.length > 0)
        {
            // Get the file name selected by the user.
            const file = e.target.files[0];

            // while the Profile Picture is uploading, disable the upload button.
            setUploadButtonDisabled(true);

            // Upload the profile picture to the DB.
            // s3Upload({
            //     bucketName:   'xenkiaassetbucket',
            //     objectName:   "profile-picture." + file.name.split('.').pop(),
            //     object:       file,
            //     subPath:      "profilePicture"
            // }).then(async(response) => {
            s3Upload({
                bucketName:   'xenkiaassetbucket',
                //objectName:   "profile-picture." + file.name.split('.').pop(),
                object:       file,
                subPath:      "profilePicture/profile-picture." + file.name.split('.').pop()
            }).then(async(response) => {

                //setProfilePicture({...profilePicture, fileUrl: '', file:'', filename: ''});

                // Mark in the remote DB that the Profile Picture was successfully uploaded.
                updateProfilePictureUploadedDB();

                alert("Success: Profile picture uploaded!");

                // If Profile Picture upload was succesful, then load the new profile picture.
                {
                    // Download the profile picture to the DB.
                    __s3FileStreamDownload({
                        bucketName:   'xenkiaassetbucket',
                        //objectName:   "profile-picture.jpg",
                        subPath:      "profilePicture/profile-picture.jpg"
                    }).then(async(dataStream) => {

                        //console.log(dataStream.data.Body.toString('utf-8'));

                        //b64IMG = Buffer.from(dataStream.data.Body).toString('base64');

                        // Save the Profile Picture as Base64 stream, sot hat it can be displayed in the browser.
                        // Note: https://www.w3docs.com/snippets/html/how-to-display-base64-images-in-html.html
                        setProfilePictureBase64(Buffer.from(dataStream.data.Body).toString('base64'));

                        //saveByteArray(dataStream.data.Body.toString('utf-8'), 'profile-picture.jpg');
                    }).catch((e) => {
                        console.log("error", e);
                    });
                }

                // As soon as the Profile Picture gets uploaded, enable the upload button.
                setUploadButtonDisabled(false);
            })
        }

        // // Get the User Identity. Based on this we will generate the QR Code so that the App can know in which folder it can find the models.
        // Auth.currentUserInfo().then((userInfo) => {
        //     if (userInfo) 
        //     {
        //         user_id = encodeURIComponent(userInfo.id);
        //         setUserId(user_id);
        //     }
        // });

        // // Upload progress: https://stackoverflow.com/questions/55075608/how-do-i-send-progress-of-s3-upload-in-aws-amplify-react-native
        // Storage.put("profile_picture." + file_extension, file, {
        //     level: 'protected'})
        // .then( () => {  

        //         //setProfilePicture({...profilePicture, fileUrl: '', file:'', filename: ''});

        //         // Mark in the remote DB that the Profile Picture was successfully uploaded.
        //         updateProfilePictureUploadedDB();

        //         alert("Success: Profile picture uploaded!");

        //         // Enable the Profile Picture selection button.
        //         setUploadButtonDisabled(false);
        //         //UploadConfirmationToast();
        //     })
        // .catch(err=>{
        //     console.log('Error uploading file!', err)
        // });
    }


    /**
     * When the profile picture is uploaded, notify the user.
     * @returns 
     */
    function UploadConfirmationToast(){

        return (
            <Col xs={6}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Header>
                    {/* <img
                        src="holder.js/20x20?text=%20"
                        className="rounded mr-2"
                        alt=""
                    /> */}
                    <strong className="mr-auto">Bootstrap</strong>
                    {/* <small>11 mins ago</small> */}
                    </Toast.Header>
                    <Toast.Body>Upload successful!</Toast.Body>
                </Toast>
            </Col>
        );
      }


    /**
     * Converts Unix Time to regular Date&Time.
     * Source: https://www.cloudhadoop.com/reactjs-convert-timestamp-date/
     * @param {*} unix_time 
     * @returns 
     */
    function unixTimeToDataTime(unix_time)
    {
        //console.log(currentTimestamp); // get current timestamp
        let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(unix_time)

        return date;
    }




    return (
        <div style={{width: '90%', maxWidth: '60%'}}>
            <br/> <br/>
                <div>
                    {accountStatus.image_icon_uploaded
                    ?
                        //<Image src={config.s3.BUCKET_ACCELERATED + AWS.config.credentials.data.IdentityId + '/profilePicture/' + "profile-picture.jpg"} alt="profile-picture" height="192" rounded style={{marginRight: '10px', marginLeft: '10px'}}/>
                        <Image src={'data:image/jpg;base64,'+profilePictureBase64} alt="profile-picture" height="192" rounded style={{marginRight: '10px', marginLeft: '10px'}}/>
                    :
                        (accountStatus.account_type === 'Individual')
                        ? <Image src={individual} alt="icon" height="192" rounded style={{marginRight: '10px', marginLeft: '10px'}}/>
                        : <Image src={company} alt="icon" height="192" rounded style={{marginRight: '10px', marginLeft: '10px'}}/>
                    }
                    <br/> <br/>
                    <input  
                        className="btn-outline-success btn-lg"
                        style={{
                            width: "40%",
                            minWidth: '350px',
                            paddingLeft: "10px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingRight: "10px",                
                            border: "2px solid grey",
                            outline: "0px",
                            marginLeft: 'auto', 
                            marginRight: 'auto'
                        }}
                        // type='file' accept="image/*" onChange={selectProfilePicture} disabled={uploadButtonDisabled}
                        type='file' accept=".jpg" onChange={selectProfilePicture} disabled={uploadButtonDisabled}
                    />
                </div>

                <div style={{textAlign: 'left'}}>
                    <br/>
                    {userAccountStatusLoaded === true
                        ?  <>
                                <h2 style={{color: '#2196F3'}}><i>Basic user information</i></h2>
                                <hr/>
                                <Container fluid>
                                    <Row>
                                        <Col xs={4}>
                                            <p className="upload-subtext"> <b>Name:</b></p>
                                        </Col>
                                        <Col xs={6}>
                                            <p className="upload-subtext"> {accountStatus.name}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <p className="upload-subtext"> <b>Email:</b></p>
                                        </Col>
                                        <Col xs={6}>
                                            <p className="upload-subtext"> {accountStatus.email}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <p className="upload-subtext"> <b>Address:</b></p>
                                        </Col>
                                        <Col xs={6}>
                                            <p className="upload-subtext"> {accountStatus.address}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <p className="upload-subtext"> <b>Phone number:</b></p>
                                        </Col>
                                        <Col xs={6}>
                                            <p className="upload-subtext"> {accountStatus.phone_number}</p>
                                        </Col>
                                    </Row>
                                </Container>
                                <br/>

                                <h2 style={{color: '#2196F3'}}><i>Package</i></h2>
                                <hr/>
                                <Container fluid>
                                    <Row>
                                        <Col xs={4}>
                                            <p className="upload-subtext"> <b>Account type:</b></p>
                                        </Col>
                                        <Col xs={6}>
                                            <p className="upload-subtext"> {accountStatus.account_type}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <p className="upload-subtext"> <b>Package:</b></p>
                                        </Col>
                                        <Col xs={6}>
                                            <p className="upload-subtext"> {accountStatus.pricing_package}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <p className="upload-subtext"> <b>Last bill amount:</b></p>
                                        </Col>
                                        <Col xs={6}>
                                            <p className="upload-subtext"> ${accountStatus.package_cost} USD</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <p className="upload-subtext"> <b>Billing cycle:</b></p>
                                        </Col>
                                        <Col xs={6}>
                                            <p className="upload-subtext"> {accountStatus.billing_cycle}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <p className="upload-subtext"> <b>Registration date:</b></p>
                                        </Col>
                                        <Col xs={6}>
                                            <p className="upload-subtext"> {unixTimeToDataTime(accountStatus.subscriptionDate)}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <p className="upload-subtext"> <b>Subscription expiry date:</b></p>
                                        </Col>
                                        <Col xs={6}>
                                            <p className="upload-subtext"> {unixTimeToDataTime(accountStatus.expiry_date)}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <p className="upload-subtext"> <b>Max file size allowed:</b></p>
                                        </Col>
                                        <Col xs={6}>
                                            <p className="upload-subtext"> {accountStatus.max_file_size} MB</p>
                                        </Col>
                                    </Row>
                                </Container>
                                <br/>

                                <h2 style={{color: '#2196F3'}}><i>Usage status</i></h2>
                                <hr/>
                                <br/>
                                <Col>
                                    <Row style={{justifyContent: 'center', display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto', maxWidth: '90%'}}>
                                        {Math.round((accountStatus.upload_counter / accountStatus.max_uploads_allowed)*100) > 80
                                            ? <ProgressBar animated variant="danger" now={Math.round((accountStatus.upload_counter / accountStatus.max_uploads_allowed) * 100)} label={`${Math.round((accountStatus.upload_counter / accountStatus.max_uploads_allowed) * 100)}%`}/>
                                            : <ProgressBar animated variant="success" now={Math.round((accountStatus.upload_counter / accountStatus.max_uploads_allowed) * 100)} label={`${Math.round((accountStatus.upload_counter / accountStatus.max_uploads_allowed) * 100)}%`}/>
                                        }
                                    </Row>
                                    <Row style={{justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                                        <p className="upload-subtext" style={{textAlign: 'center'}}> <b>Upload Counter</b><i>({accountStatus.upload_counter} out of {accountStatus.max_uploads_allowed}/Month)</i></p>
                                    </Row>
                                </Col>
                                <br/>
                                <Col>
                                    <Row style={{justifyContent: 'center', display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto', maxWidth: '90%'}}>
                                        {Math.round((accountStatus.download_counter / accountStatus.max_view_allowed)*100) > 80
                                            ? <ProgressBar animated variant="danger" now={Math.round((accountStatus.download_counter / accountStatus.max_view_allowed) * 100)} label={`${Math.round((accountStatus.download_counter / accountStatus.max_view_allowed) * 100)}%`}/>
                                            : <ProgressBar animated variant="success" now={Math.round((accountStatus.download_counter / accountStatus.max_view_allowed) * 100)} label={`${Math.round((accountStatus.download_counter / accountStatus.max_view_allowed) * 100)}%`}/>
                                        }
                                    </Row>
                                    <Row style={{justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                                        <p className="upload-subtext" style={{textAlign: 'center'}}> <b>Download/View Counter</b><i>({accountStatus.download_counter} out of {accountStatus.max_view_allowed}/Month)</i></p>
                                    </Row>
                                </Col>
                                <br/>
                                <Col>
                                    <Row style={{justifyContent: 'center', display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto', maxWidth: '90%'}}>
                                        {Math.round(((accountStatus.storage_space_used/1024) / accountStatus.max_storage_space)*100) > 80
                                            ? <ProgressBar animated variant="danger" now={Math.round(((accountStatus.storage_space_used/1024) / accountStatus.max_storage_space) * 100)} label={`${Math.round(((accountStatus.storage_space_used/1024) / accountStatus.max_storage_space) * 100)}%`}/>
                                            : <ProgressBar animated variant="success" now={Math.round(((accountStatus.storage_space_used/1024) / accountStatus.max_storage_space) * 100)} label={`${Math.round(((accountStatus.storage_space_used/1024) / accountStatus.max_storage_space) * 100)}%`}/>
                                        }
                                    </Row>
                                    <Row style={{justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                                        {accountStatus.storage_space_used > 1024
                                            ?   <p className="upload-subtext" style={{textAlign: 'center'}}> <b>Storage used</b><i>({Math.round(accountStatus.storage_space_used/1024)} GB out of {accountStatus.max_storage_space} GB)</i></p>
                                            :   <p className="upload-subtext" style={{textAlign: 'center'}}> <b>Storage used</b><i>({accountStatus.storage_space_used} MB out of {accountStatus.max_storage_space} GB)</i></p>
                                        }
                                    </Row>
                                </Col>

                                
                            </>
                        :   
                            <div style={{display: 'flex', justifyContent: 'center', height: '80vh'}}>
                                <Spinner animation="border" variant="success" style={{fontSize: '1rem'}}/>
                            </div>
                    }
                </div>
            <br/>
        </div>
    );
}


/**
 * This is the 'Profile' Class definition and the entry point.
 */
//class Profile extends Component {
function Profile(props){

    //state = {login_status: 1};

/*     UNSAFE_componentWillMount(){

        // Check if any user is currently logged in. If yes, only then allow them to upload.
        Auth.currentUserInfo().then((userInfo) => {

          // Store the currently logged in users email address for use in this page.
          loggedin_user_email = userInfo.attributes.email;

          // Set the Login Status.
          if (userInfo) 
          {
            this.setState({login_status: 1});
          }
          else{
            this.setState({login_status: 0});
          }
        });  
    } */



    //const {uploadPercentage} = state.percentage;
    if (props.loggedIn === true)
    {
        return(
            <header className="upload-header">
                <UserProfile/>
            </header>
        )
    }
    else if (props.loggedIn === false){
        return(
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh'}}>
                <div>
                    <img src={unauthorized} alt="unauthorized" height="96px"></img>
                    <hr/>
                    <h3> Please login to access your profile </h3>
                </div>
            </div>
        )
    }
}


export default Profile;
