
import {React, Component, useState, useEffect} from "react";
import { Button, Container, Row, Col, Modal, Image, Card, OverlayTrigger, Tooltip, Form, Badge, Jumbotron, InputGroup, FormControl, Alert, Spinner} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import '../../styling/uploadFile.css';

import { v5 as uuidv5 } from 'uuid';

import config from '../../config'

import add from '../../icons/add-96.png'


/**
 * This function will allow the user to create a new Asset.
 * @param {*} props 
 * @returns 
 */
export default function CreateNewAsset(props) {

    const [updating, setUpdating] = useState(false);
    const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
    const [itemName, setItemName] = useState("");
    const [itemUserFriendlyName, setItemUserFriendlyName] = useState("");


    useEffect(() => {
      // https://stackoverflow.com/questions/155188/trigger-a-button-click-with-javascript-on-the-enter-key-in-a-text-box/155193#155193
      if(document.getElementById("asset_name") && document.getElementById("create_button"))
        document.getElementById("asset_name").addEventListener("keyup", function(event) {
            event.preventDefault();
            if (event.keyCode === 13) {
                document.getElementById("create_button").click();
                document.getElementById("create_button").disabled = true;
            }
        });
    }, []);

    /**
     * Creates an Asset in the remote DB.
     */
    const createAssetInDB = async() => {     
      
        // Generate the UUID for the asset being uploaded.
        let asset_uuid = uuid_generator(props.email, itemName);

        //console.log(props.email, props.userId, asset_uuid);

        // Add the User to the database.
        var res = await fetch(config.AssetManager.addasset, {
            method: 'POST',
            /* mode: 'no-cors', */
            body: JSON.stringify({
                email:              props.email,
                request:            "create",
                asset_uuid:         asset_uuid,
                //fileName:           itemName,
                fileName:           asset_uuid,
                friendlyAssetName:  itemUserFriendlyName,
                fileFormat:         "",
                //S3Url:              config.s3.BUCKET_ACCELERATED + props.userId + '/' + itemName + '/',
                S3Url:              config.s3.BUCKET_ACCELERATED + props.userId + '/asset/' + asset_uuid + '/',
                fileSizeInMB:       0,
                glbFileUploaded:    false,
                usdzFileUploaded:   false,
                posterUploaded:     false,
                qrCodeUploaded:     false
            })
        });

        // Get the response from the Backend.
        var response = await res.json();
        
        if(response.statusCode === 201){
          alert("Asset successfully created!");
          props.onHide();
        }
    }



    return (

        <Modal
          {...props}
          aria-labelledby ="contained-modal-title-vcenter"
          centered
          //keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{textAlign: 'justify', fontSize: '1.2rem'}}> <Image height='24px' src={add}/>&nbsp; {props.title} </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3" style={{textAlign: 'justify', fontSize: '1rem'}}>
                  Asset Name
                </InputGroup.Text>
                <FormControl 
                        id="asset_name"
                        type="username" 
                        placeholder="Enter desired asset name"
                        value={itemUserFriendlyName}
                        onChange={e => {
                          setItemUserFriendlyName(e.target.value);
                          // Note: https://stackoverflow.com/questions/441018/replacing-spaces-with-underscores-in-javascript
                          setItemName(e.target.value.replace(/ /g,"_"));
                          // Needed to check the length of the Name before we enable the create button. The name cannot be empty.
                          let itemNameVar = e.target.value;
                          if( (itemNameVar.length >= 4) && (itemNameVar.length <= 22) ) {
                            setCreateButtonDisabled(false);
                            //document.getElementById("create_button").focus();
                          }
                          else setCreateButtonDisabled(true);
                        }}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                  />
            </InputGroup>
            
            <p className="foot-note" style={{textAlign: 'justify', color: "black", fontSize: '1rem'}}> <b style={{color:'#FF5722'}}>*</b> Asset Name has to be at least 4 characters long and less than 23 characters. </p>

            <Row style={{justifyContent: 'center'}}>
              {updating
              ?
                <Spinner animation="border" variant="info"/>
              :
                <> </>
              }
            </Row>
            <br/>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button id="create_button" variant='success' disabled={createButtonDisabled} onClick={()=> createAssetInDB()}> <b>Create</b> </Button>
              <Button id="close_button" variant='dark' onClick={props.onHide}> <b>Cancel</b> </Button>
            </div>
          </Modal.Body>
        </Modal>
    );
  }


/**
 * This function will return an UUID for each item uploaded in Xenkia.
 * Note: Use the Currently logged in users email, Selected filename and Currently
 *       date&time to generate a unique UUID for the uploaded asset. WE EVENTUIALLY
 *       HAVE TO IMPLEMENT A LAMBDA FUNCTION FOR THIS, IN ORDER TO HIDE THE UUID GENERATION
 *       PROCESS.
 * */ 
function uuid_generator(email, filename)
{
    //return uuidv5(email+filename+Date.now().toString(), XenkiaAWSconfig.UUID_Generation.XENKIA_NAMESPACE);

    // Lets not use the Date(). This way the UUID will be the same every time the user re-upload the file.
    return uuidv5(email+filename, config.UUID_Generation.XENKIA_NAMESPACE);
}

