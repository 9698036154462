/* eslint-disable no-lone-blocks */

import './styling/App.css';
// import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

// import entire SDK
import AWS from 'aws-sdk'
import {s3Delete, s3Upload, __s3FileStreamDownload} from './helper_classes/AWSS3'

// Xenkia configuration.
import config from './config'

import {Buffer} from 'buffer';

// Reactjs.
import React, {Component, useState} from 'react';
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import ReactDOM from "react-dom";
import { Button, Navbar, Nav, NavDropdown, Form, FormControl, Badge, InputGroup, Row, Col, Image, Container, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

//import useGaTracker from './useGaTracker'
import ReactGA from "react-ga";

// import {Auth, Storage} from 'aws-amplify'

// Logo.
import xenkia_logo from './icons/xenkia32x32.png'
import individual from './icons/individual.png'
import company from './icons/enterprise.png'

// Javascript files of other /pages.
import Login from "./pages/signup";
import Signup from "./pages/signup_v1";
import UploadModel from './pages/upload';
import AssetManager from './pages/assetmanager/assetmanager.js';
import Profile from './pages/profile';
import Home from './pages/home';
import ChangeTemporaryPassword from './pages/ChangeTemporaryPassword'
import Unsubscribe from './pages/unsubscribe'

import {cognitoAuthenticateUser, cognitoGetCurrentUser, cognitoSignout}  from './helper_classes/identity'
//import cognitoGetCurrentUser  from './helper_classes/identity'



// Configure AWS.
// Amplify.configure(awsconfig);


// User details needed for account operations obtained from the remote server.
export var loggedInUsersName    = "";
export var loginToken           = "";
export var loggedinUsersEmail   = "";

var username = "";
var password = "";

// User ID. Export it.
var user_id = "";






// Class defintion.
class App extends Component{

  // Constructor.
  constructor(props) {
    super(props);

    this.stateUpdate = this.stateUpdate.bind(this);
  }

  // State variable.
  state = {
    loggedIn:           false
  }

  // This handler will be send to the SigninForm, so that it can update the loggedIn state.
  // Note: https://stackoverflow.com/questions/35537229/how-can-i-update-the-parents-state-in-react
  stateUpdate(loggedInState) {
    this.setState({
      loggedIn:   loggedInState
    })
  }


  UNSAFE_componentWillMount()
  {
    ReactGA.initialize("UA-220179500-1");
  }

  // Do these things before loading anyting else.
  UNSAFE_componentDidMount()
  {
    //useGaTracker();
    
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  

    
  render(){
    return(
      <div className="App">    
        <BrowserRouter>
            <Navbar bg="dark" variant="dark" sticky="top" expand="lg">
                <Image src={xenkia_logo} style={{paddingLeft: '1rem', paddingRight: '1rem'}}/>
                <Navbar.Brand as={Link} to="/" >Xenkia</Navbar.Brand>
                <Navbar.Toggle/>

                    {
                      // How to Pass, Access & Update Parent State from Child Components in React:
                      // 1. https://stackoverflow.com/questions/35537229/how-can-i-update-the-parents-state-in-react
                      // 2. https://www.techiediaries.com/access-update-parent-state-child-component-react/
                      
                    }
                <SigninForm
                    stateUpdate = {this.stateUpdate}
                /> 
            </Navbar>


          {// A <Routes> looks through its children <Route>s and renders the first one that matches the current URL.
          }
              <Routes>
                <Route path="/assetmanager" 
                    element={<AssetManager
                        loggedIn = {this.state.loggedIn}
                      />}
                >    
                </Route>
                <Route path="/profile"
                    element={<Profile
                        loggedIn = {this.state.loggedIn}
                    />}
                >
                </Route>
                {/* <Route path="/signupform/:plan?/:cycle?/:cost?" */}
                <Route path="/signupform"
                    element={<Signup/>}>
                </Route>
                <Route path="/changetemporarypassword/:email?/:pass?"
                    element={<ChangeTemporaryPassword/>}>
                </Route>
                <Route path="/unsubscribe/:email?"
                    element={<Unsubscribe/>}>
                </Route>
                <Route path="/" 
                  element={<Home/>}>
                  {/* <Home/> */}
                </Route>
            </Routes>
        </BrowserRouter>        
      </div>

    )
  }
}


/**
   * All the signin from related logic will go here.
   * @returns 
   */
 const SigninForm = (props) => {

    const [state, setState] = useState({
      email: null,
      name: null,
      loginstatus: 1        // '0' means Signed Out, '1' means currently Signing In, '2' means Signed In and '3' means Currently Signing Out.
    });

    // This State variable will store the Profile Picture in Base64 format. As soon as it gets updated, the picture gets displayed.
    const [profilePictureBase64, setProfilePictureBase64] = useState(null);

    // Use this state to mark whether the App checked for any pre-existing users before showing the SignIn form.
    const [firstLoginCheck, setFirstLoginCheck] = useState(true);

    // Username and Password references.
    username   = React.createRef();
    password   = React.createRef();

    // This flag will mark if the Image/Icon was uploaded by the user.
    const [image_icon_uploaded, setImage_icon_uploaded]       = useState(false);
    // Is this user an Individual or Company, regardless of which package was selected during Signup.
    const [individual_or_company, setIndividual_or_company]   = useState("Individual");

    // Get currently logged in user (if any). Got help from here: https://stackoverflow.com/questions/31867229/how-can-i-execute-a-javascript-function-on-the-first-page-load
    window.onload = async function () 
    {

      // Currently Login in.
      setState({email: null, name: null, loginstatus: 1});

      // Check if any user is already logged in.
      cognitoGetCurrentUser()
      .then( async(refreshedCredentials) => {

          //console.log(refreshedCredentials);

          // The credentials can be accessed either via the returned variable (refreshedCredentials) locally, or via AWS.config.credentials globally.
          //console.log("Returned Credentials: ", refreshedCredentials.credentials, AWS.config.credentials);
          //console.log(AWS.config.credentials);

          // Login successful.
          setState({email: refreshedCredentials.email, name: refreshedCredentials.name, loginstatus: 2});
          loggedinUsersEmail = refreshedCredentials.email;
          //console.log(refreshedCredentials.email, refreshedCredentials.name);

          // Set it to true, since the other pages needs to know if a user has been logged in or not in order to show the content.
          // Note: https://stackoverflow.com/questions/35537229/how-can-i-update-the-parents-state-in-react
          props.stateUpdate(true);

          // check if the Profile Picture was uploaded by the user. If yes, then load and display it.
          displayProfilePicture();
      })
      .catch( resp => {
          setState({loginstatus: 0});
          // alert(resp.errMessage);
      })

      //console.log("test");

      /* try {
          await Auth.currentUserInfo().then(async(userInfo) => 
          {
              if(userInfo)
              {          

                  user_id = encodeURIComponent(userInfo.id);
                  //console.log(userInfo);

                  // Update the state variable, so that it gets displayed in the App.
                  setState({email: userInfo.attributes.email, name: userInfo.attributes.name, loginstatus: 2});

                  // Add the User to the database.
                  var res = await fetch("https://oidnlpynvl.execute-api.us-east-1.amazonaws.com/prod/accountstatus", {
                      method: 'POST',
                      // mode: 'no-cors',
                      body: JSON.stringify({
                          "email":     userInfo.attributes.email
                      })
                  });

                  // Get the response from the Backend.
                  var response = await res.json();

                  // Proceed only if the Response StatusCode=201.
                  if(response.statusCode === 201)
                  {
                    setImage_icon_uploaded(response.body.response.Item.image_icon_uploaded);
                    setIndividual_or_company(response.body.response.Item.individual_or_company);
                  }
              }

              // Mark that pre-existing logged in user was checked before rendering the signin form.
              setFirstLoginCheck(true);
          });
        } catch (error) {
            console.log('ERROR: ', error.message);
  
            // Login unsuccessful.
            //setState({email: null, name: null, loginstatus: 0});
        } */       
    }

    


    /**
     * Asynchronous SignIn() function. 
     * Note: Solve how to change the state variable externally based on this article: https://stackoverflow.com/questions/37513440/set-react-component-state-from-outside-of-component, 
     * and https://twitter.com/dan_abramov/status/824308413559668744/photo/2
     */
    async function signIn() {

      //console.log("check credentials");

      // Currently Login in.
      setState({email: null, name: null, loginstatus: 1});

      try {
          //const user = await Auth.signIn(username.current.value, password.current.value);

          // // Verify credentials against the database.
          // var res = await fetch("https://dmomfne4b7.execute-api.us-east-1.amazonaws.com/prod/verifycredentials", {
          //     method: 'POST',
          //     // mode: 'no-cors',
          //     body: JSON.stringify({
          //         email:     username.current.value,
          //         //email:     "neuropac28@hotmail.com",
          //         password:  password.current.value
          //         //password:  "123456789"
          //     })
          // });


          // // Convert the response from the Backend to JSON.
          // var response = await res.json();
          
            


          /**
           *  Get a valid AWS Credential.
           */
          cognitoAuthenticateUser({
              username: username.current.value,
              password: password.current.value
            }).then( async(refreshedCredentials) => {

              // The credentials can be accessed either via the returned variable (refreshedCredentials) locally, or via AWS.config.credentials globally.
              //console.log("Returned Credentials: ", refreshedCredentials.credentials, AWS.config.credentials);
              //console.log(AWS.config.credentials);

              // Login successful.
              setState({email: refreshedCredentials.email, name: refreshedCredentials.name, loginstatus: 2});
              loggedinUsersEmail = refreshedCredentials.email;
              //console.log(refreshedCredentials.email, refreshedCredentials.name);

              // Set it to true, since the other pages needs to know if a user has been logged in or not in order to show the content.
              // Note: https://stackoverflow.com/questions/35537229/how-can-i-update-the-parents-state-in-react
              props.stateUpdate(true);

              // check if the Profile Picture was uploaded by the user. If yes, then load and display it.
              displayProfilePicture();
          })
          .catch( resp => {
              setState({loginstatus: 0});
              alert(resp.errMessage);
          })

          

      //   let res = await fetch("https://1ocjsd3q3f.execute-api.us-east-1.amazonaws.com/prod/user-pool-management", {
      //                   method: 'POST',
      //                   body: JSON.stringify({
      //                     request_type:   "authenticateUser",
      //                     username:       username.current.value,
      //                     password:       password.current.value
      //                   })
      //               })
          
      //     // Get the response from the Backend.
      //     const response = await res.json();

          

      //     // Proceed only if the user exists and the password was correct.
      //     if(response.statusCode === 200)
      //     {
      //         AWS.config.credentials = JSON.parse(response.body);
      //         //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
              // AWS.config.credentials.refresh(error => {
              //     if (error) {
              //       console.error(error);
              //     } else {
              //       // Instantiate aws sdk service objects now that the credentials have been updated.
              //       //var s3 = new AWS.S3();
              //       console.log(AWS.config.credentials);
              //     }
              // });

      //         // Login successful.
      //         setState({email: response.body.email, name: response.body.name, loginstatus: 2});

      //         // Save the email address of the currently logged in user, so that this can be used by other *.js files to extract data from the server.
      //         loggedinUsersEmail  = response.body.email;
      //         loginToken          = response.body.token;

      //         //console.log(loggedinUsersEmail);

      //         // Save the LoginToken for later use.
      //         //loginToken = response.body.
      //     }
      } catch (error) {
          console.log('ERROR: ', error.message);

          // Login unsuccessful.
          setState({email: null, name: null, loginstatus: 0});
      }
    }




    /**
     * Signout the currently logged user from Cognito.
     */
    async function signOut() {

      // Currently Logging out.
      //setState({email: null, name: null, loginstatus: 3});

      // This function will SignOut the currently logged in user from Cognito.
      cognitoSignout();

      // Successfully logged out.
      setState({email: null, name: null, loginstatus: 0});

      // Set it to true, since the other pages needs to know if a user has been logged in or not in order to not to show the content.
      props.stateUpdate(false);

      // try {
      //     //await Auth.signOut();
      //     //console.log("signout");

      //     // Successfully logged out.
      //     setState({email: null, name: null, loginstatus: 0});

      //     // Set it to true, since the other pages needs to know if a user has been logged in or not in order to not to show the content.
      //     props.stateUpdate(false);

      //     // Go to Home page. 
      //     //console.log(window.location.href);
      //     if (window.location.href !== window.location.origin)
      //     {
      //         window.location.assign(window.location.origin);
      //     }
      // } catch (error) {
      //     console.log('ERROR: ', error.message);

      //     // Successfully logged out.
      //     setState({email: null, name: null, loginstatus: 2});
      // }
    }


    /** 
    * Display profile picture, if any was uploaded.
    */
    const displayProfilePicture = async() =>
    {
        //console.log(loggedin_user_email);

        // Add the User to the database.
        var res = await fetch("https://oidnlpynvl.execute-api.us-east-1.amazonaws.com/prod/accountstatus", {
            method: 'POST',
            /* mode: 'no-cors', */
            body: JSON.stringify({
                "email":     loggedinUsersEmail
            })
        });

        // Get the response from the Backend.
        var response = await res.json();

        // Proceed only if the Response StatusCode=201.
        if(response.statusCode === 201)
        {
            // If the Icon was uploaded by the user, then display it in the browser.
            if(response.body.response.Item.image_icon_uploaded === true)
            {
                // Download the profile picture to the DB.
                __s3FileStreamDownload({
                    bucketName:   'xenkiaassetbucket',
                    //objectName:   "profile-picture.jpg",
                    subPath:      "profilePicture/profile-picture.jpg"
                }).then(async(dataStream) => {
                    //console.log(dataStream.data.Body.toString('utf-8'));

                    //b64IMG = Buffer.from(dataStream.data.Body).toString('base64');

                    // Save the Profile Picture as Base64 stream, sot hat it can be displayed in the browser.
                    // Note: https://www.w3docs.com/snippets/html/how-to-display-base64-images-in-html.html
                    // There was an issue with Buffer, it was solved following: 
                    // https://stackoverflow.com/questions/70420479/react-uncaught-referenceerror-buffer-is-not-defined/70484554
                    setProfilePictureBase64(Buffer.from(dataStream.data.Body).toString('base64'));
                    //console.log(Buffer.from(dataStream.data.Body).toString('base64'));

                    // Mark that the user had uploaded the profile picture.
                    setImage_icon_uploaded(true);

                    //saveByteArray(dataStream.data.Body.toString('utf-8'), 'profile-picture.jpg');
                }).catch((e) => {
                    console.log("error", e);
                });
            }
        }
    }



    /* if(firstLoginCheck === false)
    { 
        return(
          <> 
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link as={Link} to="/" >Home</Nav.Link>
                <NavDropdown title="Dashboard" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/assetmanager" disabled>Asset Manager</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/profile" disabled>Profile</NavDropdown.Item>
                </NavDropdown>                  
              </Nav>
            </Navbar.Collapse>

            <Spinner animation="border" variant="success"/>
          </>
        )
    }
    else{ */
        // Based on the current 'loginstatus', display message and buttons in the NavBar.
        if(state.loginstatus === 0) 
        {
            return (
              <>
                <Navbar.Collapse id="navbarScroll">
                  <Nav
                    className="me-auto my-2 my-lg-0"
                    style={{ maxHeight: '100px' }}
                    navbarScroll
                  >
                    <Nav.Link as={Link} to="/" >Home</Nav.Link>
                    <Nav.Link href="/assetmanager" disabled>Asset Manager</Nav.Link>
                    <Nav.Link href="/profile" disabled>Profile</Nav.Link>
                  </Nav>
                </Navbar.Collapse>

                <Navbar.Collapse className="navbarScroll justify-content-end">
                    <Form style={{paddingRight: '1rem'}}>
                      <Row style={{display: 'flex', justifyContent: 'center'}}>
                        <Col xs="auto">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control
                                  className="mb-2"
                                  ref={username}
                                  type="email" 
                                  placeholder="Email"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group controlId="formBasicPassword">
                                <FormControl 
                                  className="mb-2"
                                  ref={password}
                                  placeholder="Password" 
                                  type="password"
                                />
                          </Form.Group>
                        </Col>
                        <Col xs="auto">
                          <Button type='submit' variant="success" onClick={signIn}> 
                            <b>SignIn</b>
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                </Navbar.Collapse>
              </>       
            );
        }
        else if(state.loginstatus === 1)
        {
            return (
              <>
                <Navbar.Collapse id="navbarScroll">
                  <Nav
                    className="me-auto my-2 my-lg-0"
                    style={{ maxHeight: '100px' }}
                    navbarScroll
                  >
                    <Nav.Link as={Link} to="/" >Home</Nav.Link>
                    <Nav.Link as={Link} to="/assetmanager" disabled>Asset Manager</Nav.Link>
                    <Nav.Link as={Link} to="/profile" disabled>Profile</Nav.Link>
                    {/* <NavDropdown title="Dashboard" id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to="/assetmanager" disabled>Asset Manager</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/profile" disabled>Profile</NavDropdown.Item>
                    </NavDropdown>                   */}
                  </Nav>
                </Navbar.Collapse>

                <Navbar.Collapse className="justify-content-end" style={{paddingRight: '1rem'}}>
                    <Spinner
                          as="span"
                          animation="border"
                          variant="success"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                    <Navbar.Text style={{marginLeft: '5px'}}>
                        Signing in
                    </Navbar.Text>
                    <Button variant="danger" onClick={signOut} disabled> <b>SignOut</b> </Button>
                </Navbar.Collapse>
              </>
            );
        }
        else if(state.loginstatus === 2)
        {
            return (
              <>
                <Navbar.Collapse id="navbarScroll">
                  <Nav
                    className="me-auto my-2 my-lg-0"
                    style={{ maxHeight: '100px' }}
                    navbarScroll
                  >
                    <Nav.Link as={Link} to="/" >Home</Nav.Link>
                    <Nav.Link as={Link} to="/assetmanager" >Asset Manager</Nav.Link>
                    <Nav.Link as={Link} to="/profile" >Profile</Nav.Link>
                    {/* <NavDropdown title="Dashboard" id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to="/assetmanager">Asset Manager</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/profile">Profile</NavDropdown.Item>
                    </NavDropdown> */}                  
                  </Nav>
                </Navbar.Collapse>

                <Navbar.Collapse className="justify-content-end" style={{paddingRight: '1rem'}}>
                    {image_icon_uploaded
                    ? <Image src={'data:image/jpg;base64,'+profilePictureBase64} alt="profile-picture" height="32" rounded style={{marginRight: '10px', marginLeft: '10px'}}/>
                    : (individual_or_company === 'Individual')
                      ? <Image src={individual} alt="icon" height="32" rounded style={{marginRight: '10px', marginLeft: '10px'}}/>
                      : <Image src={company} alt="icon" height="32" rounded style={{marginRight: '10px', marginLeft: '10px'}}/>
                    }
                        
                    <Navbar.Text>
                        Logged in as, <a href="#login"> {state.name} </a>
                    </Navbar.Text>
                    <Button variant="danger" onClick={signOut}> <b>SignOut</b> </Button>
                </Navbar.Collapse>
              </>
            );
        }
        else if(state.loginstatus === 3)
        {
            return (
              <>
                <Navbar.Collapse id="navbarScroll">
                  <Nav
                    className="me-auto my-2 my-lg-0"
                    style={{ maxHeight: '100px' }}
                    navbarScroll
                  >
                    <Nav.Link as={Link} to="/" >Home</Nav.Link>
                    <Nav.Link as={Link} to="/assetmanager" >Asset Manager</Nav.Link>
                    <Nav.Link as={Link} to="/profile" >Profile</Nav.Link>
                    {/* <NavDropdown title="Dashboard" id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to="/assetmanager" disabled>Asset Manager</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/profile" disabled>Profile</NavDropdown.Item>
                    </NavDropdown> */}                  
                  </Nav>
                </Navbar.Collapse>

                <Navbar.Collapse className="justify-content-end" style={{paddingRight: '1rem'}}>
                    <Spinner
                          as="span"
                          animation="border"
                          variant="danger"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                    <Navbar.Text style={{marginLeft: '5px'}}>
                        Signing in
                    </Navbar.Text>
                    <Button variant="danger" onClick={signOut} disabled> <b>SignOut</b> </Button>
                </Navbar.Collapse>
              </>
            );
        }
}


export default App;

