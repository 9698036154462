
import {React, Component, useState, useEffect} from "react";
import { Button, Container, Row, Col, Modal, Image, Card, OverlayTrigger, Tooltip, Form, Badge, Jumbotron, InputGroup, FormControl, Alert} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

//import './3d-viewer.css';




export default function ModalMessageBox(props) {


    return (

        <Modal
          {...props}
          aria-labelledby ="contained-modal-title-vcenter"
          centered
          //keyboard={false}
        >
          <Modal.Header>
            <Modal.Title> <b>{props.title}</b> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {props.body}
          </Modal.Body>
        </Modal>
    );
  }

