// Reading Form values: https://github.com/react-bootstrap/react-bootstrap/issues/2418
// Running a Background thread:  https://medium.com/@inkdrop/a-simple-way-to-run-js-in-background-thread-on-react-native-8fff345576da, https://www.newline.co/fullstack-react/articles/introduction-to-web-workers-with-react/

import React, { Component, Fragment, useState, useEffect} from "react";
import { Button, Navbar, Nav, NavDropdown, Form, FormControl, Badge, InputGroup, Row, Col, Image, Jumbotron, Card, DropdownButton, Dropdown, Modal, Container, Spinner} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styling/signup.css';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { Formik, Field } from "formik";
import * as yup from 'yup';

import individual from '../icons/individual.png'

import ModalMessageBox from '../helper_classes/ModalMessageBox'

import { pricing_plan_list } from "../pricing_plans.js";

import XenkiaAWSconfig from '../config'

import StripeProcessing from '../helper_classes/stripe'

import config from '../stripe_config.js';

import {s3Delete, s3Upload} from '../helper_classes/AWSS3'


  /**
   * All the User Details collection related logic will go here.
   * @returns 
   */
  const SignupForm = (props) => {

    // This variable will be used 
    const [processPayment, setProcessPayment]                                       = useState(false);
    const [userInfo, setUserInfo]                                                   = useState(null);

    // Package related informations passed through the URL.
    const [defaultAccountType, setDefaultAccountType]                               = useState("");
    const [defaultPricingPlan, setDefaultPricingPlan]                               = useState("");
    const [defaultBillingCycle, setDefaultBillingCycle]                             = useState("");

    const [checkingIfEmailAlreadyRegistered, setCheckingIfEmailAlreadyRegistered]   = useState(false);
    const [emailAlreadyRegistered, setEmailAlreadyRegistered]                       = useState(false);

    let userEmail;


    /**
     * This runs once at the beginning. Source: https://stackoverflow.com/questions/53464595/how-to-use-componentwillmount-in-react-hooks 
     * */
    useEffect(() => {
          
      //console.log(window.location.href);

      // Logic for extracting the parameters. Check the following link to parse URL parameters.
      // Note: https://www.sitepoint.com/get-url-parameters-with-javascript/, https://stackoverflow.com/questions/8486099/how-do-i-parse-a-url-query-parameters-in-javascript
      //"http://localhost:3000/params=1&uuid=65f7132e-0aeb-54b8-9ec0-34f6200332d8&DisplayName=false&AREnabled=true&Animate=true&DefaultExposure=50&ShowTagLine=true"

        // Extract the parameters from the URL.
        const urlParams = new URLSearchParams(window.location.href);


        if(urlParams.has('pricingPlan'))
        {
          setDefaultPricingPlan(urlParams.get('pricingPlan'));
          console.log(urlParams.get('pricingPlan'));
        }
        if(urlParams.has('accountType'))
        {
          setDefaultAccountType(urlParams.get('accountType'));
          console.log(urlParams.get('accountType'));
        }
        if(urlParams.has('billingCycle'))
        {
          setDefaultBillingCycle(urlParams.get('billingCycle'));
          console.log(urlParams.get('billingCycle'));
        }
    }, []);


  //   /**
  //    * File selection for upload.
  //    * @param {*} e 
  //    */
  //    const selectProfilePicture = (e) => {

  //     // Disable the Profile Picture selection button.
  //     //setUploadButtonDisabled(true);

  //     // Get the file name selected by the user.
  //     const file = e.target.files[0];
  //     const file_extension = file.name.split('.').pop();

      

  //     s3Upload({
  //       bucketName:   'xenkiaassetbucket',
  //       objectName:   file.name,
  //       object:       file,
  //       subPath:      "profilePicture"    // Do not add any '/' as prefix or postfix.
  //     });

  //     // // Get the User Identity. Based on this we will generate the QR Code so that the App can know in which folder it can find the models.
  //     // Auth.currentUserInfo().then((userInfo) => {
  //     //     if (userInfo) 
  //     //     {
  //     //         user_id = encodeURIComponent(userInfo.id);
  //     //         setUserId(user_id);
  //     //     }
  //     // });

  //     // // Upload progress: https://stackoverflow.com/questions/55075608/how-do-i-send-progress-of-s3-upload-in-aws-amplify-react-native
  //     // Storage.put("profile_picture." + file_extension, file, {
  //     //     level: 'protected'})
  //     // .then( () => {  

  //     //         //setProfilePicture({...profilePicture, fileUrl: '', file:'', filename: ''});

  //     //         // Mark in the remote DB that the Profile Picture was successfully uploaded.
  //     //         updateProfilePictureUploadedDB();

  //     //         alert("Success: Profile picture uploaded!");

  //     //         // Enable the Profile Picture selection button.
  //     //         setUploadButtonDisabled(false);
  //     //         //UploadConfirmationToast();
  //     //     })
  //     // .catch(err=>{
  //     //     console.log('Error uploading file!', err)
  //     // });
  // }


    // This function will check if the Email that the new user have enter, is already registered or not.
    const checkIfUserExists = async(email) => {
        
        // While waiting a Spinner is appear.
        setCheckingIfEmailAlreadyRegistered(true);

        const res = await fetch(XenkiaAWSconfig.registration.checkIfUserREgistered, { // Backend API url
          method: 'POST',
          body: JSON.stringify({
              request_type:                       "checkIfRegistered",
              email:                              email
            }),
        });
        
        // Get the response from the Backend.
        const response = await res.json();

        // Spinner disappears as soon as the response appears.
        setCheckingIfEmailAlreadyRegistered(false);

        //console.log(response);

        // In case of an error, allow the customer to correct it and try paying again.
        if(response.body.message === 'User does not exist!'){
          //setEmailAlreadyRegistered(false);
          setProcessPayment(true);
        }
        else 
        {
          //setEmailAlreadyRegistered(true);
          alert("Email already registered. Please use a different email address.");
        }
    }

    
    // Formik Schema.
    const schema = yup.object().shape(
    {
      firstName:            yup.string()
                            .required('Required'),
      lastName:             yup.string()
                            .nullable(),
      // username:             yup.string()
      //                       .test('no-space','Username cannot contain any <space>', async (value) => {
      //                           if(value) return (value.search(" ") < 0);
      //                           else return true;
      //                         })
      //                       .min(5, 'Must be longer than 4 characters!')
      //                       .max(30, 'Must be shorter than 30 characters!')
      //                       .required('Required'),
      city:                 yup.string()
                            .required('Required'),
      state:                yup.string()
                            .nullable(),
      zip:                  yup.string()
                            .required('Required'),
      country:              yup.string()
                            .required('Required'),
      address:              yup.string()
                            .required('Required'),
      email:                yup.string()
                            .email()
                            .required('Required'),
      confirmEmail:         yup.string()
                            // .email()
                            .required('Required')
                            .when("email", {
                              is: val => (val && val.length > 0 ? true : false),
                              then: yup.string().oneOf(
                                [yup.ref("email")],
                                "Emails do not match!"
                              )
                            }),
      password:             yup.string()
                            .test('min-pass-length','Minimum password length is 8 characters', async (value) => {
                              if(value)
                              {
                                if(value.length >= 8) return true;
                                else return false;
                              }
                            })
                            .required('Required'),
      confirmPassword:      yup.string()
                            .when("password", {
                              is: val => (val && val.length > 0 ? true : false),
                              then: yup.string().oneOf(
                                [yup.ref("password")],
                                "Passwords do not match!"
                              )
                            })
                            .required('Required'),
      phoneNumber:          yup.string()
                            .required('Required')
      //file:       yup.mixed().required(),
      //terms:      yup.bool().required().oneOf([true], 'Terms must be accepted')
    });


    

    return (
      <div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '50px'}}>
          {processPayment
          ? 
              <div>
                    <StripeProcessing
                      processPayment    =   {processPayment}
                      userInfo          =   {userInfo}
                    />
              </div>
          :
              <Formik
                validationSchema={schema}

                onSubmit={(values, { setSubmitting }) => {
                  setUserInfo(values);
                  checkIfUserExists(values.email);
                  //setProcessPayment(true);
                }}
                
                initialValues={{
                  firstName: '',
                  lastName: '',
                  // username: '',
                  city: '',
                  state: '',
                  zip: '',
                  country: '',
                  address: '',
                  password: '',
                  confirmPassword:  '',
                  email: '',
                  confirmEmail: '',
                  phoneNumber:  ''
                  //file: null,
                  //terms: false,
                }}
              >
                {({
                  handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, setFieldValue, submitForm
                }) => (

                    <Form noValidate onSubmit={handleSubmit}>
                      <h3 style={{textAlign: 'justify'}}> User details </h3>
                      <hr/>

                      {/* {console.log(values.accountType)} */}
                      {/* {console.log(defaultAccountType, defaultPricingPlan, defaultBillingCycle)} */}

                      {/* <Row className="mb-3">
                          <Image style={{marginLeft: 'auto', marginRight: 'auto'}} src={individual} height='96px' alt="picture" rounded />
                      </Row>


                      <Row className="mb-3" style={{textAlign: 'justify'}}>
                        <Form.Group className="position-relative mb-3" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                          <Form.Control
                            type="file"
                            required
                            name="file"
                            //onChange={handleChange}
                            accept=".jpg"
                            onChange={selectProfilePicture}
                            isInvalid={errors.file}
                          />
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors.file}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      <br/> */}

                      <Row className="mb-3" style={{textAlign: 'justify'}}>
                        <Form.Group as={Col} md="6" controlId="validationFormik101" className="position-relative"
                        >
                          <Form.Label>First name</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstName"
                            placeholder="John / Jane"
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={errors.firstName}
                          />
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationFormik102" className="position-relative"
                        >
                          <Form.Label>Last name</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastName"
                            placeholder="Doe"
                            value={values.lastName}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Row>

                      <Row className="mb-3" style={{textAlign: 'justify'}}>
                        <Form.Group
                            as={Col}
                            //md="3"
                            controlId="validationFormikAddress"
                            className="position-relative"
                          >
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="12345 Street, Building, Floor, Suit"
                              name="address"
                              value={values.address}
                              onChange={handleChange}
                              isInvalid={errors.address}
                            />
              
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.address}
                            </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      <Row className="mb-3" style={{textAlign: 'justify'}}>
                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormikCity"
                          className="position-relative"
                        >
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="New York"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            isInvalid={errors.city}
                          />
            
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors.city}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik104"
                          className="position-relative"
                        >
                          <Form.Label>State</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="New York"
                            name="state"
                            value={values.state}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik105"
                          className="position-relative"
                        >
                          <Form.Label>Zip/Postal Code</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="10001"
                            name="zip"
                            value={values.zip}
                            onChange={handleChange}
                            isInvalid={errors.zip}
                          />
            
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors.zip}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationFormik106"
                          className="position-relative"
                        >
                          <Form.Label>Country</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="USA"
                            name="country"
                            value={values.country}
                            onChange={handleChange}
                            isInvalid={errors.country}
                          />
            
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors.country}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="6" controlId="validationFormikPhoneNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <InputGroup hasValidation>
                                <PhoneInput
                                    name='phone'
                                    required= {true}
                                    country='us'
                                    placeholder='+1202-555-0932'
                                    value={values.phoneNumber}
                                    /* onChange={handleChange} */
                                    onChange={phone => {
                                      setFieldValue('phoneNumber', phone, submitForm)
                                    }}
                                    /* onChange={phone => this.setState({ phone })} */
                                    isValid={(value, country) => {
                                      if(value.length > 1) return true;
                                      else return false;
                                    }}
                                />
                            </InputGroup>
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.phoneNumber}
                            </Form.Control.Feedback>
                        </Form.Group>
                      </Row>



                      <br/> <br/>

                      <h3 style={{textAlign: 'justify'}}> Login details </h3>
                      <hr/>

                        <Row className="mb-3" style={{textAlign: 'justify'}}>
                          {/* <Form.Group as={Col} md="6" controlId="validationFormikUsername2">
                            <Form.Label>Username</Form.Label>
                            <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                              <Form.Control
                                type="text"
                                placeholder="myusername"
                                aria-describedby="inputGroupPrepend"
                                name="username"
                                value={values.username}
                                onChange={handleChange}
                                isInvalid={errors.username}
                              />
                              <Form.Control.Feedback type="invalid" tooltip>
                                {errors.username}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group> */}

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationFormikEmail"
                            className="position-relative"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="your@email.address"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              isInvalid={errors.email}
                            />
              
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationFormikEmailConfirmation"
                            className="position-relative"
                          >
                            <Form.Label>Confirm email</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="confirm-your@email.address"
                              name="confirmEmail"
                              value={values.confirmEmail}
                              onChange={handleChange}
                              isInvalid={errors.confirmEmail}
                            />
              
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.confirmEmail}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>

                        <Row className="mb-3" style={{textAlign: 'justify'}}>
                          <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationFormikPassword"
                              className="position-relative"
                            >
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="!23flGH@13"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                isInvalid={errors.password}
                              />
                
                              <Form.Control.Feedback type="invalid" tooltip>
                                {errors.password}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationFormikConfirmPassword"
                              className="position-relative"
                            >
                              <Form.Label>Confirm password</Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="!23flGH@13"
                                name="confirmPassword"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                isInvalid={errors.confirmPassword}
                              />
                
                              <Form.Control.Feedback type="invalid" tooltip>
                                {errors.confirmPassword}
                              </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <br/> <br/>

                        <Button type="submit" size='lg' variant="success" disabled={!isValid || checkingIfEmailAlreadyRegistered}>
                          {checkingIfEmailAlreadyRegistered
                            ? <Spinner animation="grow" />
                            : <b>Next</b>
                          }
                        </Button>
                      {/* {values.pricingPlan === 'Starter'
                      ?
                        <Button type="submit" size='lg' variant="success" disabled={!isValid}>
                          <b>Signup</b>
                        </Button>
                      :
                        <Button type="submit" size='lg' variant="success" disabled={!isValid}>
                          <b>Pay and Signup</b>
                        </Button>
                      } */}
                      <br/><br/>
                    </Form>
                )}
              </Formik>
          }

          <br/> <br/>
      </div>
    );
  }




class Signup extends Component {

    render() {
        return (
            <div style={{maxWidth: '70%', marginLeft: 'auto', marginRight: 'auto'}}>
                <SignupForm
                  testProp="imon"
                />
            </div>
        );
    }    
}


export default Signup;
