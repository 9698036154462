// Enable TRANSFER ACCELERATION based on this link: https://github.com/aws/aws-sdk-js/issues/1639

const AWS = require('aws-sdk');
const AmazonCognitoIdentity             =   require('amazon-cognito-identity-js');		/** NOTE: https://www.npmjs.com/package/amazon-cognito-identity-js */

/**
 * This function will delete any S3 object.
 * @param {objectName} props 
 */
export async function s3Delete(props)
{
    // Create AWS s3 object.
    var s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: "xenkiaassetbucket" },
        useAccelerateEndpoint: true
      });

    // Object to delete from S3.
    var params = {
        Bucket:   "xenkiaassetbucket",
        Key:      "protected/" + AWS.config.credentials.data.IdentityId + "/" + props.objectName
       };

    // Delete an object from the s3 Bucket.
    s3.deleteObject(params, function(err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else     console.log(data);           // successful response
        /*
        data = {
        }
        */
    });
}


/**
 * This function will get/download a file from the DB.
 * @param {objectName, object, subPath} props 
 */
 export async function __s3FileStreamDownload(props)
 {
     // Create AWS s3 object.
     var s3 = new AWS.S3({
         apiVersion: "2006-03-01",
         params: { Bucket: "xenkiaassetbucket" },
         useAccelerateEndpoint: true
       });
 
       //console.log(s3, AWS.config.credentials);
 
     var params = {
         Body:                       props.object, 
         Bucket:                     "xenkiaassetbucket", 
         Key:                        "protected/" + AWS.config.credentials.data.IdentityId + "/" + props.subPath,
        };
 
     // Upload the file.
     return new Promise((resolve, reject) => 
         s3.getObject(params, function(err, data) {
             if (err) {
                 console.log(err, err.stack);       // An error occurred.
                 reject({
                     status: 'ERROR'
                 });
             }
             else {
                 //console.log(data);               // Successful response.
                 resolve({
                     data:      data
                 });
             }
         })
     );
 }


/**
 * This function will upload a file into the DB.
 * Note: https://stackoverflow.com/questions/14375895/aws-s3-node-js-sdk-uploaded-file-and-folder-permissions (Granting public-read access)
 * @param {objectName, object, subPath} props 
 */
export async function s3Upload(props)
{
    //console.log(props.subPath + "/" + props.objectName, props.object);

    // Create AWS s3 object.
    var s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: "xenkiaassetbucket" },
        useAccelerateEndpoint: true
      });

      //console.log(s3, AWS.config.credentials);

    var params = {
        Body:                       props.object, 
        Bucket:                     "xenkiaassetbucket", 
        // Key:                        "protected/" + AWS.config.credentials.data.IdentityId + "/" + props.subPath + "/" + props.objectName,
        Key:                        "protected/" + AWS.config.credentials.data.IdentityId + "/" + props.subPath,
        //ServerSideEncryption: "AES256", 
        //StorageClass: "STANDARD_IA"
        ACL:                        'public-read'
       };

    // Upload the file.
    return new Promise((resolve, reject) => 
        s3.upload(params, function(err, data) {
            if (err) {
                console.log(err, err.stack); // an error occurred
                reject({
                    status: 'ERROR'
                });
            }
            else {
                //console.log(data);           // successful response
                resolve({
                    status: 'SUCCESS'
                });
            }
        }).on('httpUploadProgress', function(progress) {
            let progressPercentage = Math.round(progress.loaded / progress.total * 100);
            //console.log(progressPercentage);
            props.prog(progressPercentage);
        })
    );
}



/**
 * This function will calculate the S3 Folder Size.
 * @param {objectName, object, subPath} props 
 */
export async function s3UserBucketSize(props)
 {
     //console.log(props.subPath + "/" + props.objectName, props.object);
 
     // Create AWS s3 object.
     var s3 = new AWS.S3({
         apiVersion: "2006-03-01",
         params: { Bucket: "xenkiaassetbucket" },
         useAccelerateEndpoint: true
       });

 
    //console.log("Save File!");

     // Upload the file.
     return new Promise(async(resolve, reject) => {

            var totalSize = 0, ContinuationToken, resp;

            do {
                resp = await s3.listObjectsV2({
                    Bucket: "xenkiaassetbucket",
                    Prefix: "protected/" + AWS.config.credentials.data.IdentityId + "/",
                    ContinuationToken
                }).promise().catch((e)=> { })

                //console.log(resp);
                resp.Contents.forEach(o => totalSize+=o.Size)
                ContinuationToken = resp.NextContinuationToken
            } while (ContinuationToken)
        
            //console.log(totalSize/(1024*1024))      // In MB

            resolve(totalSize/(1024*1024));
         });
 }


 /**
 * This function will calculate the S3 Folder Size.
 * @param {subPath} props 
 */
export async function s3UserSubFolderSize(props)
{
    //console.log(props.subPath + "/" + props.objectName, props.object);

    // Create AWS s3 object.
    var s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: "xenkiaassetbucket" },
        useAccelerateEndpoint: true
      });


   //console.log("Save File!");

    // Upload the file.
    return new Promise(async(resolve, reject) => {

           var totalSize = 0, ContinuationToken, resp;

           do {
               resp = await s3.listObjectsV2({
                   Bucket: "xenkiaassetbucket",
                   Prefix: "protected/" + AWS.config.credentials.data.IdentityId + props.subPath,
                   ContinuationToken
               }).promise().catch((e)=> { })

               //console.log(resp);
               resp.Contents.forEach(o => totalSize+=o.Size)
               ContinuationToken = resp.NextContinuationToken
           } while (ContinuationToken)
       
           //console.log(totalSize/(1024*1024))      // In MB

           resolve(totalSize/(1024*1024));
        });
}

 
/**
 * This function will calculate the S3 Folder Size.
 * @param {assetUUID} props 
 */
 export async function s3DeleteAsset(props)
 {
     //console.log(props.subPath + "/" + props.objectName, props.object);
 
     // Create AWS s3 object.
     var s3 = new AWS.S3({
         apiVersion: "2006-03-01",
         params: { Bucket: "xenkiaassetbucket" },
         useAccelerateEndpoint: true
       });

 
    //console.log("Deleting Asset!");

     // Upload the file.
     return new Promise(async(resolve, reject) => {

            var ContinuationToken, resp;

            do {
                resp = await s3.listObjectsV2({
                    Bucket: "xenkiaassetbucket",
                    Prefix: "protected/" + AWS.config.credentials.data.IdentityId + "/asset/"+props.assetUUID + "/",
                    ContinuationToken
                }).promise().catch((e)=> { })

                //console.log(resp);
                resp.Contents.forEach(res => {
                    //console.log(res.Key);

                    // Object to delete from S3.
                    // eslint-disable-next-line no-lone-blocks
                    {
                        var params = {
                            Bucket:   "xenkiaassetbucket",
                            Key:      res.Key
                        };

                        // Delete an object from the s3 Bucket.
                        s3.deleteObject(params, function(err, data) {
                            if (err) console.log(err, err.stack);       // An error occurred
                            //else     console.log(data);                 // Successful response
                            reject(false);
                        });
                    }
                })
                ContinuationToken = resp.NextContinuationToken
            } while (ContinuationToken)

            //resolve(totalSize/(1024*1024));
            resolve(true);
         });
 }


/**
 * HELPFUL LINKS:
 * 1. https://docs.aws.amazon.com/AmazonS3/latest/userguide/ManageCorsUsing.html
 * 2. https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/s3-browser-examples.html
 * 3. https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/s3-example-photo-album.html
 * 4. https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#putObject-property
 * 5. https://stackoverflow.com/questions/60526580/using-aws-sdk-how-to-find-total-size-of-a-folder-stored-in-amazon-s3
 * 6. https://stackoverflow.com/questions/23835252/aws-sdk-javascript-how-display-upload-progress-of-aws-s3-putobject
 * 7. https://docs.aws.amazon.com/AmazonS3/latest/API/API_PutObject.html
 * 8. https://github.com/TTLabs/EvaporateJS/wiki/Configuring-The-AWS-S3-Bucket
 */
