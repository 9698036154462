
import {React, Component, useState, useEffect} from "react";
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";

import { Button, Card, Container, Row, Col, CardDeck, Image, OverlayTrigger, Tooltip, Figure, Alert, Accordion, ContextAwareToggle, Form, FormControl, Badge, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'w3-css/w3.css';

// Don't remove these, otherwise text becomes funky.
// import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';

import '../styling/home.css';

// import windowSize from 'react-window-size';

import { demo_items_list } from "../demo_items.js";
import { pricing_plan_list } from "../pricing_plans.js";

import '@google/model-viewer/dist/model-viewer';

import * as QrCode from 'qrcode.react'

import config from '../config'

// import 'rc-footer/assets/index.css'; // import 'rc-footer/asssets/index.less';

// Browser icons.
import chrome_icon from '../icons/chrome.png';
import safari_icon from '../icons/safari.png';
import firefox_icon from '../icons/firefox.png';
import edge_icon from '../icons/edge.png';

// Social media.
import youtube_icon from '../icons/youtube.png';
import linkedin_icon from '../icons/linkedin.png';
import twitter_icon from '../icons/twitter.png';
import facebook_icon from '../icons/facebook.png';
import instagram_icon from '../icons/instagram.png';
import f6s_icon from '../icons/f6s-icon.png'

// Other icons.
import next_icon from '../icons/next.png';
import prev_icon from '../icons/previous.png';

import up_arrow from '../icons/up-arrow.png';
import registration_icon from '../icons/google-fonts/registration.png';
import individual_icon from '../icons/individual.png';
import company_icon from '../icons/enterprise.png';
import view_in_ar_icon from '../icons/google-fonts/view-in-ar.png';
import rotation_3d from '../icons/google-fonts/rotation_3d.png';
import read from '../icons/google-fonts/read.png';
import scanQR from '../icons/icons8-scan-96.png'

// Xenkia Configurations.
import XenkiaAWSconfig from '../config';

// gif_image.
//import xenkia_gif from '../gifs/gif_xenkia_chair.gif';
import xenkia_gif from '../gifs/gif_xenkia_chair.gif';

// Reflection image.
import environment_hdr from '../environment_hdr/sidekix-media-BAVam-y_9Wg-unsplash.jpg';

// Modal based MessageBox Class.
import ModalMessageBox from '../helper_classes/ModalMessageBox';


/**
 * Global variables.
 */
var demoAssets =[];
var individualPricingPlan =[];
var companyPricingPlan =[];

// When the home page gas been loaded for the first time, this flag will be set.
var flag_loaded = 0;

// Default Billing-Cycle and Cost values passed through URL as parameters.
var default_billing_cycle = "";
var default_cost = "";



/**
 * Home class.
 */
class Home extends Component {
    
    state = {   
                demoAssets:                 [], 
                width:                      640, 
                height:                     480,
                individualOrCompany:        0,
                billMonthlyOrYearly:        'Monthly',
                subscriptionEmail:          "",

                modalTitle:                 "",
                modalBody:                  "",
                modalShow:                  false,

                subscriptionButtonHide:     true,

                goToTopVisible:             false,
                goToTopButtonPosY:          0,

                windowInnerWidth:           0
            };


    num_of_demo_items_set           = 0;            // This variable stores the total number of Sets of Demo Assets in the 'demo_items.js' file.
    currently_selected_batch        = 1;            // This is the Currently Selected Batch of Demo Items.



    UNSAFE_componentWillMount(){                

        // console.log(window.innerWidth);
        
        this.setState({width: window.innerWidth,   height: window.innerHeight});


        if (flag_loaded !== 1)
        {
            // Load the demo assets.
            this.loadDemoAssets();
            this.loadPricingPlan();
        }

        //console.log("component will mount", flag_loaded);      
        
        // Everytime the Window is Resized, thie event gets fired.
        window.addEventListener('resize', (event) =>{
            this.toggleGoToTopButtonVisibility();
        });

        // Everytime the Window is Scrolled, thie event gets fired.
        window.addEventListener('scroll', (event) =>{
            this.toggleGoToTopButtonVisibility();
        });

        // Run this at the begining once, so that the GoToTop button appears based on the current window size.
        this.toggleGoToTopButtonVisibility();

        // Everytime the Window is Resized, thie event gets fired.
        window.addEventListener('resize', (event) =>{
            this.setState({width: window.innerWidth,   height: window.innerHeight});
          });
    }


    UNSAFE_componentDidMount()
    {
        // Set the flag to mark that the components have already mounted once. This is mandatory.
        flag_loaded = 1;

        // Set the Resolution of the current window.
        //this.setState({width: this.props.windowWidth.toString()+'px',   height: this.props.windowHeight.toString()+'px'});
        //this.setState({width: window.innerWidth.toString()+'px',   height: window.innerHeight.toString()+'px'});
    }


    

    // This function will scroll the page to the top.
    // Note: https://www.coderomeos.org/scroll-to-top-of-the-page-a-simple-react-component
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

    // Show button when page is over a certain width.
    toggleGoToTopButtonVisibility (){

        //console.log((window.scrollY/window.innerHeight)*100 + "%");

        //console.log(window.innerWidth - 5);

        // The "GoToTop" button should appear 150px inside of the current window.innerWidth.
        this.setState({goToTopButtonPosY: (window.innerWidth - 150)});

        // The "GoToTop" button should appear when the window.innerWidth is greater than 1024px and 100% of the page height (when its first loaded) has been scrolled down.
        //if ( (window.innerWidth > 1024) && ((window.scrollY/window.innerHeight)*100 > 100)) {
        if ( (window.scrollY/window.innerHeight)*100 > 100) {

            this.setState({goToTopVisible: true})
        } else {
            this.setState({goToTopVisible: false})
        }
    };


    showEmail = () =>
    {
        alert('info@xenkia.com');
    }


    // Select the Previous Batch of Demo Items.
    selectPreviousBatch = () =>
    {
        if (this.currently_selected_batch > 1) this.currently_selected_batch--;
        else this.currently_selected_batch = this.num_of_demo_items_set;

        this.loadDemoAssets();

        //console.log("previous batch: ", this.currently_selected_batch);
    }

    // Select the Next Batch of Demo Items.
    selectNextBatch = () =>
    {
        if (this.currently_selected_batch < this.num_of_demo_items_set) this.currently_selected_batch++;
        else this.currently_selected_batch = 1;

        this.loadDemoAssets();

        //console.log("next batch", this.currently_selected_batch);
    }


    

    /**
     * Load the Pricing Plan from Server.
     */
     loadPricingPlan = (selectedPlan) => {


        // Load all the Individual Pricing plans.
        individualPricingPlan = [];
        pricing_plan_list[0].forEach(element => {

            // Select the 'default_billing_cycle' to pass as parameters to the /SignUpForm/
            if(selectedPlan === 'Yearly')
            {
                default_billing_cycle       ='Yearly';
                default_cost                = element.yearly_cost_in_usd;
            } else{
                default_billing_cycle       ='Monthly';
                default_cost                = element.monthly_cost_in_usd;
            }


            individualPricingPlan.push(
                <Card
                    bg='Light'
                    text='dark'
                    style={{ width: '32rem', marginLeft: '1%', marginRight: '1%', marginTop: '30px', marginBottom: '1%', maxWidth: '90%'}}
                    className="pricingPackageZoom"
                >
                    <Card.Header className="pricing-card-header"><b>{element.plan}</b></Card.Header>
                    <Card.Body>                                
                        <br/>
                        {selectedPlan === 'Yearly'
                            ?
                                <section>
                                    <Card.Title className="pricing-card-price"> <b><td dangerouslySetInnerHTML={{__html: element.yearly_cost}} /></b> </Card.Title>
                                </section>
                            :
                                <section>
                                    <Card.Title className="pricing-card-price"> <b><td dangerouslySetInnerHTML={{__html: element.cost}} /></b> </Card.Title>
                                </section>
                        }
                        <Button style={{boxShadow: "1px 1px 8px #99CEBA"}} variant="outline-success" onClick={
                            () =>
                            {    
                                if (this.state.billMonthlyOrYearly === 'Monthly') {
                                    this.setState({billMonthlyOrYearly: 'Yearly'});
                        
                                    // Re-loading is necessary, otherwise the price portion is not re-rendering as we switch between monthly and yearly plan.
                                    this.loadPricingPlan("Yearly");
                                }
                                else if(this.state.billMonthlyOrYearly === 'Yearly'){
                                    this.setState({billMonthlyOrYearly: 'Monthly'});
                        
                                    // Re-loading is necessary, otherwise the price portion is not re-rendering as we switch between monthly and yearly plan.
                                    this.loadPricingPlan("Monthly");
                                }
                            }
                        }> <b>{selectedPlan === 'Yearly'? 'Show Monthly price' : 'Show Yearly price'}</b> 
                        </Button>
                        
                        <Card.Text  style={{textAlign: 'justify'}}>
                            <br/>
                            <h5 style={{textAlign: 'justify', color: '#3F51B5'}}> <td dangerouslySetInnerHTML={{__html: element.good_for}} /></h5>
                            <br/>
                            <div style={{paddingLeft: '1rem'}}>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature1}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature2}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature3}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature4}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature5}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature6}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature7}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature8}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature9}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature10}} />
                            </div>
                        </Card.Text>

                        <br/>                    
                        {/* <Button variant="success" size="lg" as={Link} to={"/signupform/"+element.plan+"/"+default_billing_cycle+default_cost+"/"}><b>Subscribe to this Package</b></Button> */}
                        {/* <Button style={{boxShadow: "1px 1px 8px grey"}} variant="warning" size="lg" as={Link} to={"/signupform/params=1&pricingPlan="+element.plan+"&accountType=Personal&billingCycle="+default_billing_cycle}> <img src={registration_icon} alt="registration" width="32" height="32"/> <b> Subscribe</b></Button> */}
                        <Button style={{boxShadow: "1px 1px 8px grey"}} variant="warning" size="lg" as={Link} to={"/signupform"}> <img src={registration_icon} alt="registration" width="32" height="32"/> <b> Subscribe</b></Button>
                    </Card.Body>
                </Card>
                );
        });



        // Load all the Company Pricing plans.
        companyPricingPlan = [];
        pricing_plan_list[1].forEach(element => {

            // Select the 'default_billing_cycle' to pass as parameters to the /SignUpForm/
            if(selectedPlan === 'Yearly')
            {
                default_billing_cycle       ='Yearly';
                default_cost                = element.yearly_cost_in_usd;
            } else{
                default_billing_cycle       ='Monthly';
                default_cost                = element.monthly_cost_in_usd;
            }

            companyPricingPlan.push(
                <Card
                    bg='Light'
                    text='dark'
                    style={{ width: '32rem', marginLeft: '1%', marginRight: '1%', marginTop: '30px', marginBottom: '1%', maxWidth: '90%'}}
                    className="pricingPackageZoom"
                >
                    <Card.Header className="pricing-card-header"><b>{element.plan}</b></Card.Header>
                    <Card.Body>                                
                        <br/>
                        {selectedPlan === 'Yearly'
                            ?
                                <section>
                                    <Card.Title className="pricing-card-price"> <b><td dangerouslySetInnerHTML={{__html: element.yearly_cost}} /></b> </Card.Title>
                                </section>
                            :
                                <section>
                                    <Card.Title className="pricing-card-price"> <b><td dangerouslySetInnerHTML={{__html: element.cost}} /></b> </Card.Title>                                    
                                </section>
                        }
                        <Button style={{boxShadow: "1px 1px 8px #99CEBA"}} variant="outline-success" onClick={() =>
                            {    
                                if (this.state.billMonthlyOrYearly === 'Monthly') {
                                    this.setState({billMonthlyOrYearly: 'Yearly'});
                        
                                    // Re-loading is necessary, otherwise the price portion is not re-rendering as we switch between monthly and yearly plan.
                                    this.loadPricingPlan("Yearly");
                                }
                                else if(this.state.billMonthlyOrYearly === 'Yearly'){
                                    this.setState({billMonthlyOrYearly: 'Monthly'});
                        
                                    // Re-loading is necessary, otherwise the price portion is not re-rendering as we switch between monthly and yearly plan.
                                    this.loadPricingPlan("Monthly");
                                }
                            }
                        }> <b>{selectedPlan === 'Yearly' ? 'Show Monthly price' : 'Show Yearly price'}</b> </Button>  

                        <Card.Text style={{textAlign: 'justify'}}>
                            <br/>
                            <h5 style={{textAlign: 'justify', color: '#3F51B5'}}><td dangerouslySetInnerHTML={{__html: element.good_for}} /></h5>
                            <br/>
                            <div style={{paddingLeft: '1rem'}}>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature1}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature2}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature3}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature4}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature5}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature6}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature7}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature8}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature9}} />
                                <br/>
                                <td className="pricing-card-features" dangerouslySetInnerHTML={{__html: element.feature10}} />
                            </div>
                        </Card.Text>

                        <br/>
                        
                        {/* <Button variant="warning" size="lg" as={Link} to={"/signupform/"+element.plan+"/"+default_billing_cycle+default_cost+"/"}><b>Subscribe to this Package</b></Button> */}
                        {/* <Button style={{boxShadow: "1px 1px 8px grey"}} variant="warning" size="lg" as={Link} to={"/signupform/params=1&pricingPlan="+element.plan+"&accountType=Company&billingCycle="+default_billing_cycle}> <img src={registration_icon} alt="registration" width="32" height="32"/> <b>Subscribe</b></Button> */}
                        <Button style={{boxShadow: "1px 1px 8px grey"}} variant="warning" size="lg" as={Link} to={"/signupform"}> <img src={registration_icon} alt="registration" width="32" height="32"/> <b>Subscribe</b></Button>
                    </Card.Body>
                </Card>
                );
        });
    }




    /**
     * Load the Demo Assets from Server.
     */
    loadDemoAssets = (e) => {

        // Get the Number of Sets of Demo items.
        this.num_of_demo_items_set = 0;
        demo_items_list.forEach(element => {
            this.num_of_demo_items_set = this.num_of_demo_items_set + 1;
        });
        //console.log(this.num_of_demo_items_set);


        // Load all the Demo Assets in a file.
        demoAssets = [];
        //this.setState({demoAssets: []});
        demo_items_list[this.currently_selected_batch-1].forEach(element => {
            //console.log(element);

            if(window.innerWidth>2000)
            // if(this.state.width>2000)
            {
                demoAssets.push(
                    <Card
                        className="p-3 card-margin" 
                        style={{ width: '32rem', alignItems: 'center', justifyContent: 'center', boxShadow: "1px 1px 10px grey"}}
                        bg="light"
                        text='dark'
                    >
                    <model-viewer
                        environment-image={environment_hdr}
                        shadow-intensity="1" 
                        shadow-softness="1"
                        style={{ width: '480px', height: '360px', backgroundColor: '#f7f7f7', justifyContent: 'center'}} 
                        // src={element.model_address}
                        src={"https://xenkiaassetbucket.s3-accelerate.amazonaws.com/protected/us-east-1%3A008a3f2b-57b4-4a55-ab16-c7b4c2c01c85/asset/" + element.uuid + "/" + element.uuid + ".glb"}
                        //poster={element.poster_address}
                        poster={"https://xenkiaassetbucket.s3-accelerate.amazonaws.com/protected/us-east-1%3A008a3f2b-57b4-4a55-ab16-c7b4c2c01c85/asset/" + element.uuid + "/POSTER_" + element.uuid + ".png"}
                        alt="3D model"
                        autoplay 
                        exposure={element.exposure}
                        auto-rotate 
                        camera-controls
                        field-of-view={element.camera_field_of_view}
                        min-field-of-view="10deg"
                        max-field-of-view="170deg"
                        >                        
                    </model-viewer>

                    <Card.Body>
                        <h3 style={{fontFamily: 'Open Sans, sans-serif, Arial, Helvetica'}}> <b> {element.item_name} </b></h3>
                        {/* <h5 className="card-title"> <a href={element.src_address} target="_blank" rel="noreferrer" style={{color: '#006EFF'}}> <u><i>{element.src_name}</i></u> </a> </h5> */}


                        {/* <hr/> */}
                            {/*{element.hashtags.forEach(tag => {                                                                        
                                console.log(tag);
                                
                                <p> jasvdhjasd </p>
                            })}*/
                            }
                            {/* <h6 className="home-subtext" style={{color: 'green'}}> <i>{element.simple_hashtag}</i> </h6> */}
                        <hr/>

                        <br/>
                        {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> <p style={{textAlign: 'center'}}>Click to open the model in 3d-viewer</p></Tooltip>}> */}
                            <Button className='btn-margin view3dButtonZoom' variant="danger" style={{boxShadow: "1px 1px 5px grey"}} onClick={() => window.open(config.viewer3d.url + "#/&uuid=" + element.uuid)}> <b style={{fontSize: '18px'}}> <img src={rotation_3d} height='24px' width='24px' alt='3d-logo'/> Click to open 3D viewer </b></Button>
                        {/* </OverlayTrigger> */}
                        <p style={{color: '#006EFF', fontSize: '14px'}}> <b> AR is supported in Mobile & Tablet only </b> </p>

                        <hr/>

                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> <p style={{textAlign: 'center'}}>Scan this QRCode from Mobile Devices (Android/iOS phone/tablet) camera app to view in AR</p> </Tooltip>}>
                            {/* <Image style={{width: '96px', height: '96px'}} src={element.qr_code_address}/> */}
                            {/* Note: https://zpao.github.io/qrcode.react/ */}
                            <QrCode 
                                value={config.viewer3d.url + "#/&uuid=" + element.uuid}
                                size={128} 
                                id="qrCode" 
                                includeMargin={true} 
                                level='M'
                                renderAs={"svg"}
                                imageSettings={{
                                    src:      scanQR,
                                    x:        null,
                                    y:        null,
                                    height:   24,
                                    width:    24,
                                    excavate: true,
                                }}
                            />
                        </OverlayTrigger>
                        <p style={{color: '#006EFF', fontSize: '14px'}}> <b> Scan to view in AR </b> </p>

                        <img className="buttonZoom" height='32px' width='32px' src={youtube_icon} alt="youtube" onClick={() => window.open(element.youtube_video_address)}/>
                    </Card.Body>
                    </Card>
                );
            }
            else if(window.innerWidth>1700)
            // else if(this.state.width>1700)
            {
                demoAssets.push(
                    <Card
                        className="p-3 card-margin" 
                        style={{ width: '24rem', alignItems: 'center', justifyContent: 'center', boxShadow: "1px 1px 5px grey"}}
                        bg="light"
                        text='dark'
                    >
                    <model-viewer
                        environment-image={environment_hdr}
                        shadow-intensity="1" 
                        shadow-softness="1"
                        style={{ width: '320px', height: '240px', backgroundColor: '#f7f7f7', justifyContent: 'center'}} 
                        // src={element.model_address}
                        src={"https://xenkiaassetbucket.s3-accelerate.amazonaws.com/protected/us-east-1%3A008a3f2b-57b4-4a55-ab16-c7b4c2c01c85/asset/" + element.uuid + "/" + element.uuid + ".glb"}
                        poster={"https://xenkiaassetbucket.s3-accelerate.amazonaws.com/protected/us-east-1%3A008a3f2b-57b4-4a55-ab16-c7b4c2c01c85/asset/" + element.uuid + "/POSTER_" + element.uuid + ".png"}
                        //poster={element.poster_address}
                        alt="3D model"
                        autoplay 
                        exposure={element.exposure}
                        auto-rotate 
                        camera-controls
                        field-of-view={element.camera_field_of_view}
                        min-field-of-view="10deg"
                        max-field-of-view="170deg"
                        >                        
                    </model-viewer>

                    <Card.Body>
                        <h3 style={{fontFamily: 'Open Sans, sans-serif, Arial, Helvetica'}}> <b> {element.item_name} </b></h3>
                        {/* <h5 className="card-title"> <a href={element.src_address} target="_blank" rel="noreferrer" style={{color: '#006EFF'}}> <u><i>{element.src_name}</i></u> </a> </h5> */}


                        {/* <hr/> */}
                            {/*{element.hashtags.forEach(tag => {                                                                        
                                console.log(tag);
                                
                                <p> jasvdhjasd </p>
                            })}*/
                            }
                            {/* <h5 className="home-subtext" style={{color: '#006EFF'}}> <i>{element.simple_hashtag}</i> </h5> */}
                        <hr/>

                        <br/>
                        {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> <p style={{textAlign: 'center'}}>Click to open the model in 3d-viewer</p></Tooltip>}> */}
                        <Button className='btn-margin view3dButtonZoom' variant="danger" style={{boxShadow: "1px 1px 5px grey"}} onClick={() => window.open(config.viewer3d.url + "#/&uuid=" + element.uuid)}> <b style={{fontSize: '18px'}}> <img src={rotation_3d} height='24px' width='24px' alt='3d-logo'/> Click to open 3D viewer </b></Button>
                        {/* </OverlayTrigger> */}
                        <p style={{color: '#006EFF', fontSize: '14px'}}> <b> AR supported in Mobile & Tablet only </b> </p>

                        <hr/>

                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> <p style={{textAlign: 'center'}}>Scan this QRCode from Mobile Devices (Android/iOS phone/tablet) camera app to view in AR</p> </Tooltip>}>
                            {/* <Image style={{width: '96px', height: '96px'}} src={element.qr_code_address}/> */}
                            {/* Note: https://zpao.github.io/qrcode.react/ */}
                            <QrCode 
                                value={config.viewer3d.url + "#/&uuid=" + element.uuid}
                                size={128} 
                                id="qrCode" 
                                includeMargin={true} 
                                level='M'
                                renderAs={"svg"}
                                imageSettings={{
                                    src:      scanQR,
                                    x:        null,
                                    y:        null,
                                    height:   24,
                                    width:    24,
                                    excavate: true,
                                }}
                            />
                        </OverlayTrigger>
                        <p style={{color: '#006EFF', fontSize: '14px'}}> <b> Scan to view in AR </b> </p>

                        <img className="buttonZoom" height='32px' width='32px' src={youtube_icon} alt="youtube" onClick={() => window.open(element.youtube_video_address)}></img>
                    </Card.Body>
                    </Card>
                );
            }
            else{
                demoAssets.push(
                    <Card
                        className="p-3 card-margin" 
                        style={{ width: '24rem', alignItems: 'center', justifyContent: 'center', boxShadow: "1px 1px 5px grey"}}
                        bg="light"
                        text='dark'
                    >
                    <model-viewer
                        environment-image={environment_hdr}
                        shadow-intensity="1" 
                        shadow-softness="1"
                        style={{ width: '320px', height: '240px', backgroundColor: '#f7f7f7', justifyContent: 'center'}} 
                        // src={element.model_address}
                        src={"https://xenkiaassetbucket.s3-accelerate.amazonaws.com/protected/us-east-1%3A008a3f2b-57b4-4a55-ab16-c7b4c2c01c85/asset/" + element.uuid + "/" + element.uuid + ".glb"}
                        poster={"https://xenkiaassetbucket.s3-accelerate.amazonaws.com/protected/us-east-1%3A008a3f2b-57b4-4a55-ab16-c7b4c2c01c85/asset/" + element.uuid + "/POSTER_" + element.uuid + ".png"}
                        //poster={element.poster_address}
                        alt="3D model"
                        autoplay 
                        exposure={element.exposure}
                        auto-rotate 
                        camera-controls
                        field-of-view={element.camera_field_of_view}
                        min-field-of-view="10deg"
                        max-field-of-view="170deg"
                        > 
                    </model-viewer>

                    <Card.Body>
                        <h3 style={{fontFamily: 'Open Sans, sans-serif, Arial, Helvetica'}}> <b> {element.item_name} </b></h3>
                        {/* <h5 className="card-title"> <a href={element.src_address} target="_blank" rel="noreferrer" style={{color: '#006EFF'}}> <u><i>{element.src_name}</i></u> </a> </h5> */}
                       
                        {/* <hr/> */}
                            {/*{element.hashtags.forEach(tag => {                                                                        
                                console.log(tag);
                                
                                <p> jasvdhjasd </p>
                            })}*/
                            }
                            {/* <h5 className="home-subtext" style={{color: '#006EFF'}}> <i>{element.simple_hashtag}</i> </h5> */}
                        <hr/>

                        <br/>
                        {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> <p style={{textAlign: 'center'}}>Click to open the model in 3d-viewer</p></Tooltip>}> */}
                        <Button className='btn-margin view3dButtonZoom' variant="danger" style={{boxShadow: "1px 1px 5px grey"}} onClick={() => window.open(config.viewer3d.url + "#/&uuid=" + element.uuid)}> <b style={{fontSize: '14px'}}> <img src={rotation_3d} height='24px' width='24px' alt='3d-logo'/> Click to open 3D viewer </b></Button>
                        {/* </OverlayTrigger> */}
                        <p style={{color: '#006EFF', fontSize: '14px'}}> <b> AR supported in Mobile & Tablet only </b> </p>

                        <p> <b>OR</b> </p>

                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> <p style={{textAlign: 'center'}}>Scan this QRCode from Mobile Devices (Android/iOS phone/tablet) camera app to view in AR</p> </Tooltip>}>
                            {/* <Image style={{width: '96px', height: '96px'}} src={element.qr_code_address}/> */}
                            {/* Note: https://zpao.github.io/qrcode.react/ */}
                            <QrCode 
                                value={config.viewer3d.url + "#/&uuid=" + element.uuid}
                                size={128} 
                                id="qrCode" 
                                includeMargin={true} 
                                level='M'
                                renderAs={"svg"}
                                imageSettings={{
                                    src:      scanQR,
                                    x:        null,
                                    y:        null,
                                    height:   24,
                                    width:    24,
                                    excavate: true,
                                }}
                            />
                        </OverlayTrigger>
                        <p style={{color: '#006EFF', fontSize: '12px'}}> <b> Scan to view in AR </b> </p>

                        {/* <Row style={{justifyContent: 'center', alignItems: 'center'}}> */}
                            <img className="buttonZoom" height='32px' width='32px' src={youtube_icon} alt="youtube" onClick={() => window.open(element.youtube_video_address)}></img>
                        {/* </Row> */}
                        <br/>
                        {/*<Row style={{justifyContent: 'center', alignItems: 'center'}}>
                            <Button variant="primary" onClick={this.selectNextBatch}> 
                                Next Item
                            </Button>
                        </Row>*/
                        }
                        
                    </Card.Body>
                    </Card>
                );
            }
        });

        this.setState({demoAssets: []});
        this.setState({demoAssets: [this.state.demoAssets, demoAssets]})
        //console.log(this.state.demoAssets);
    }




    /**
     * Rendering Starts here.
     */
    render() {

        //const {uploadPercentage} = this.state.percentage;
        

        return (
        
            <div>                  

                <div className="Model-Showcase">
                        <br/> <br/>

                        <Container fluid>
                            <p className="home-header"     style={{textAlign: 'center', color: "#006EFF"}}> <b>Bridging the gap between in-store and online shopping experience</b> </p>
                            <p className="home-subheader"  style={{textAlign: 'center', color: "black"}}> In a few simple steps, add our <b style={{color: '#006EFF'}}>Interactive 3D model viewer</b> with <b style={{color: '#006EFF'}}>Augmented Reality (AR)</b> capability to your E-commerce website </p>
                        </Container>
 
                        {/* {this.state.goToTopVisible
                        ?
                            // <Button variant="outline-secondary" onClick={this.scrollToTop} style={{position: 'fixed', left: this.state.goToTopButtonPosY, top: '85%', width: '96px', borderWidth: '0px', zIndex: '100'}}>
                            //     <Image src={up_arrow} style={{width: '90%'}}/> 
                            // </Button>
                            <img className="buttonZoom" alt="go-to-top" src={up_arrow} onClick={this.scrollToTop} style={{position: 'fixed', left: this.state.goToTopButtonPosY, top: '70%', width: '3vw', minWidth: '64px', borderWidth: '0px', zIndex: '100'}} /> 
                        :
                            <> </>
                        } */}

                        <div>  
                            <Container className="home-subheader" fluid="xl">
                                <br/>
                                    <Badge pill bg="success">#3D-commerce</Badge>{' '}
                                    <Badge pill bg="success">#Smart-Retail</Badge>{' '}
                                    <Badge pill bg="success">#Web3D</Badge>{' '}
                                    <Badge pill bg="success">#Product-visualization</Badge>{' '}
                                    <Badge pill bg="success">#Hobbyist</Badge>{' '}
                                    <Badge pill bg="success">#Maker</Badge>{' '}
                                    <Badge pill bg="success">#Engineering</Badge>{' '}
                                    <Badge pill bg="success">#Interior-Designing</Badge>{' '}
                                    <Badge pill bg="success">#Architect</Badge>{' '}
                                    <Badge pill bg="success">#E-commerce</Badge>{' '}
                                    <Badge pill bg="success">#Furniture</Badge>{' '}                                    
                                    <Badge pill bg="success">#Fashion</Badge>{' '}
                                    <Badge pill bg="success">#3D-art</Badge>{' '}
                                    <Badge pill bg="success">#Web-developers</Badge>{' '}
                                    <Badge pill bg="success">#Education</Badge>{' '}
                                    <Badge pill bg="success">#Industry</Badge>{' '}                                    
                            </Container>

                            <br/>

                            {/* <Container fluid>
                                <Row style={{justifyContent: 'center', alignItems: 'center'}}>
                                    <model-viewer 
                                        id='model'
                                        environment-image={environment_hdr}
                                        shadow-intensity="1" 
                                        shadow-softness="1" 
                                        style={{ width: '1024px', height: '768px', justifyContent: 'center', backgroundColor: "unset"}} 
                                        src="https://xenkia55844-vscode.s3-accelerate.amazonaws.com/protected/us-east-1%3A09842cfb-ac25-4050-9947-14fe78874f28/generator.glb"
                                        // poster={poster_address}
                                        alt="3D model" 
                                        auto-rotate 
                                        camera-controls 
                                        exposure="3"
                                        autoplay
                                        ar 
                                        ar-modes="scene-viewer webxr"> 
                                    </model-viewer>
                                </Row>
                            </Container> */}

                            <Container fluid>
                                {this.state.width>1700
                                ?
                                    <section>
                                        {//console.log("greater", this.state.width)
                                        }
                                        <Row style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center', maxWidth: '95%'}}>
                                            <img className="leftArrowButtonZoom" alt="previous" src={prev_icon} height="256px" width="128px" onClick={this.selectPreviousBatch} style={{maxWidth: '128px'}}/>
                                                {demoAssets}
                                            <img className="rightArrowButtonZoom" alt="next" src={next_icon} height='256px' width='128px' onClick={this.selectNextBatch} style={{maxWidth: '128px'}}/>                                               
                                        </Row>
                                    </section>
                                :
                                    <section>
                                        {//console.log("less", this.state.width)
                                        }
                                        <Row style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center', maxWidth: '95%'}}>
                                            {demoAssets}
                                        </Row>
                                    </section>

                                }
                            </Container>
                        </div>
                        <br/> <br/>

                        
                        <br/> <br/>
                            <Row style={{justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
                                <Col style={{minWidth: '350px'}}>
                                    <p className="why-us-subheader" style={{textAlign: 'center', color: "#006EFF", maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}> We believe in simplicity  </p>
                                    <p className="why-us-subtext" style={{textAlign: 'justify', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                                        - Our <b>3D Viewer is Browser based (WebAR)</b>, so there is <b>no need</b> for an additional <b>API or Application</b> to integrate the models into your existing website.
                                    </p>
                                    <p className="why-us-subtext" style={{textAlign: 'justify', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                                        - It can be easily <b>embedded</b> into <b>your website</b> using <b>a single line HTML code</b>. It's that <b>simple</b>!
                                    </p>
                                    <p className="why-us-subtext" style={{textAlign: 'justify', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                                        {/* <b> HTML codes are autogenerated</b> as you upload 3d models and can be <b>accesssed</b> via your personal <a href={window.location.href+"assetmanager"} target="_blank" rel="noreferrer" style={{color: '#006EFF'}}> <u> Asset Manager</u></a>. */}
                                        - <b> HTML codes are autogenerated</b> as you upload 3D models of your product to our website and <b>can also be edited</b> in our <b>Asset Manager</b>.
                                    </p>
                                    <p className="why-us-subtext" style={{textAlign: 'justify', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                                        - The 3D viewer supports <b>Material/Variants</b> selection and well as <b>Animation</b>. Even when the AR is enabled!
                                    </p>
                                    <br/> <br/>                           
                                </Col>
                                
                                <Col style={{minWidth: '350px'}}> 
                                    <p className="why-us-subheader" style={{textAlign: 'center', color: "#006EFF", maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}> Easy to use and accessible </p>
                                    <p className="why-us-subtext" style={{textAlign: 'justify', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                                        - Our 3D viewer can greatly <b>complement</b> your <b>existing website</b>.
                                    </p>
                                    <p className="why-us-subtext" style={{textAlign: 'justify', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                                        - Your customers can view the <b>3D models</b> via a simple <b>Button click</b> or by <b>scanning the QR code</b> from their mobile devices that can run <b>modern browsers</b>.
                                    </p>
                                    <p className="why-us-subtext" style={{textAlign: 'justify', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                                        - Being a <b>browser based solution</b>, our 3D viewer runs on <b>PC</b>, <b>Mac</b>, <b>iOS</b> and <b>Android</b> devices (mobiles or tablets).
                                    </p>
                                    <p className="why-us-subtext" style={{textAlign: 'justify', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                                        - The <b>3D viewer</b> is not here to replace images of products, rather to <b>complement it</b>.
                                    </p>
                                    <br/> <br/>
                                </Col>

                                <Col style={{minWidth: '350px'}}>
                                    <p className="why-us-subheader" style={{textAlign: 'center', color: "#006EFF", maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}> Engaging and immersive experience </p>
                                    
                                    <p className="why-us-subtext" style={{textAlign: 'justify', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                                        - Online <b>3D representation</b> of your products allows your customer to get a <b>better idea</b> of your <b>products dimension and color</b>.
                                    </p>
                                    <p className="why-us-subtext" style={{textAlign: 'justify', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                                        - The 3D viewer is <b>interactive</b>, so your <b>customers</b> can have a <b>immersive experience</b> while exploring your products.
                                    </p>
                                    <p className="why-us-subtext" style={{textAlign: 'justify', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                                        - <b>AR</b> allows <b>the customer</b> to make a <b>confident decision</b> before every purchase.
                                    </p>
                                    <p className="why-us-subtext" style={{textAlign: 'justify', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
                                        - They can <b>appreciate</b> your <b>design and craftsmanship</b> from the <b>comfort of their home</b>.
                                    </p>
                                </Col>
                            </Row>
                        <br/>
                </div>


                <div style={{marginBottom: '0', backgroundColor: '#333333'}}>            
                {//<div class="w3-container w3-animate-fading" style={{marginBottom: '0', backgroundColor: '#B3E5C8', animation: 'fading 1s'}}>
                }
                    <br/> 
                    <Container fluid>
                        <br/> <br/> <br/>
                        <p className="home-header" style={{color: "orange"}}>  <b>Why 3D Viewer with AR capability?</b> </p>
                    </Container>
                    <br/> <br/>

                    <Container>
                        <Row>
                            <br/>
                            <Col>                            
                                <Row style={{justifyContent: 'center'}}>
                                    {/* <img src={xenkia_gif} width="10%" height="100%" style={{minWidth: '300px', marginLeft: '5%', marginRight: '5%', marginBottom: '2%'}}/> */}
                                    <img src="https://xenkia-web-assets.s3-accelerate.amazonaws.com/ezgif.com-gif-maker.gif" alt='git-animated' width="10%" height="100%" style={{minWidth: '300px', marginLeft: '5%', marginRight: '5%', marginBottom: '2%'}}/>
                                </Row>
                                <Row style={{justifyContent: 'center'}}>
                                    <p style={{color: 'white', maxWidth: '400px', fontSize: '1rem'}}> <b style={{fontSize: '1.1rem'}}>Note:</b>  &nbsp;&nbsp;3d Viewer is supported in any platform, whereas AR is only supported in Mobile & Tablet. </p>
                                </Row>
                                {/* <Row style={{justifyContent: 'center'}}> */}
                                    <img className="buttonZoom" height='32px' width='32px' src={youtube_icon} alt="youtube" onClick={() => window.open("https://www.youtube.com/watch?v=MmnZXvGmnPw&feature=youtu.be")}></img>
                                {/* </Row> */}
                                <br/>
                            </Col>

                            <Col lg={6} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '5%', marginRight: '5%', maxWidth: '90%'}}>
                            
                                <p className="home-subheader why-us-item" style={{textAlign: 'justify', color: 'white', marginLeft: '5%', marginRight: '5%', width: '100%'}}> <b style={{color: '#DCE775'}}>3D models</b> will increase <b style={{color: '#DCE775'}}>customer engagement</b> and <b style={{color: '#DCE775'}}>enrich</b> their <b style={{color: '#DCE775'}}>shopping experience</b>, which will result in <b style={{color: '#DCE775'}}>decreased product return</b> and <b style={{color: '#DCE775'}}>increase in sales</b>. </p>
                                
                                <p className="home-subheader why-us-item" style={{textAlign: 'justify', color: 'white', marginLeft: '5%', marginRight: '5%', width: '100%'}}> <b style={{color: '#DCE775'}}>AR 3d models</b> conveys accurate information about the <b style={{color: '#DCE775'}}>dimension</b> and <b style={{color: '#DCE775'}}>color</b>, which makes the customer more <b style={{color: '#DCE775'}}>confident</b> before every <b style={{color: '#DCE775'}}>online purchase</b>. </p>
                                
                                <p className="home-subheader why-us-item" style={{textAlign: 'justify', color: 'white', marginLeft: '5%', marginRight: '5%', width: '100%'}}> AR allows the customer to <b style={{color: '#DCE775'}}>place the product</b> in their <b style={{color: '#DCE775'}}>immediate environment</b> and check how well it <b style={{color: '#DCE775'}}>blends into its surroundings</b>. No need for <b style={{color: '#DCE775'}}>erroneous mental guesswork!</b>  </p>                                
                            </Col>
                        </Row>

                        <br/> <br/> <br/> <br/>
                        <hr/>
                        <Row>
                            <h3 className="quote-header" style={{color: 'white', maxWidth: '90%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'justify'}}> 
                                <i>"I do think that a significant portion of the population of developed countries, and eventually all countries, will have AR experiences every day, 
                                almost like eating three meals a day. It will become that much a part of you."</i>
                            </h3>
                            <h3 className="quote-subheader" style={{color: 'white', maxWidth: '80%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'right'}}> —Tim Cook, CEO of Apple Inc. </h3>
                        </Row>
                        <hr/>
                    </Container>
                    <br/> <br/> 
                </div>


                <div className="Faqs-Showcase" style={{justifyContent: 'center', display: 'flex', flexDirection: 'column', minWidth: '400px'}}>
                    <br/> <br/> <br/>

                        <p className="home-header" style={{color: '#2196F3'}}> <b>FAQs</b> </p>
                        <br/>

                        <Accordion className="accordion accordion-flush" defaultActiveKey="0" style={{maxWidth: '1200px', textAlign: 'justify', marginLeft: '5%', marginRight: '5%'}}>
                            
                            <Accordion.Item as={Card.Header} eventKey="0">
                                <Accordion.Header className="faqs-question"> # How will the 3d viewer with AR help my business? </Accordion.Header>
                                <Accordion.Body className="faqs-answer">
                                    Regardless of whether a customer is buying any product from a Retail store or an E-commerce website, one problem they always face is that they cannot try-out the product in their space, which hinders their decision making process. 
                                    They cannot check whether the products will fit into their space, or how well it will blend into it. This has been a major problem that has plagued both the retail and e-commerce industry for decades.

                                    <br/><br/> Xenkia is trying to solve this problem with virtual furniture that the customers can place in their space. Augmented Reality (AR) allows the customer to try-out any furniture from the comfort 
                                    of their home, with the confidence they never experienced before.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item as={Card.Header} eventKey="9">
                                <Accordion.Header className="faqs-question"> # Does the 3d-viewer support variants of the product? </Accordion.Header>
                                <Accordion.Body className="faqs-answer"> 
                                    Absolutely! every 3D model can contain as many material variants as you want. The users can select them from a drop-down menu. This is also supported in AR view.

                                    <br/><br/> Please check this <a href="https://viewer.xenkia.com/#/&uuid=2dd6022d-f86b-50d0-8195-0669e65fb548" target="_blank" rel="noreferrer" style={{color: 'blue'}}> Shoe</a>.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item as={Card.Header} eventKey="8">
                                <Accordion.Header className="faqs-question"> # Does the 3d-viewer support animated models? </Accordion.Header>
                                <Accordion.Body className="faqs-answer"> 
                                    Yes! In fact, the 3d-viewer supports multiple animation for a single 3d model, and the viewers can easily switch between them as needed.
                                    
                                    <br/><br/>The benefits are profound, as it gives you a oppurtunity to explain to your customers how to operate/use your products, without any complex manuals.

                                    <br/><br/> Please check this <a href="https://www.3d-viewer.media/#/params=1&uuid=10f19017-aa05-50ca-89d2-4764ced2ceaf" target="_blank" rel="noreferrer" style={{color: 'blue'}}> Bar stool</a>.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item as={Card.Header} eventKey="1">
                                <Accordion.Header className="faqs-question"> # Which formats are supported by the 3d Model Viewer?</Accordion.Header>
                                <Accordion.Body className="faqs-answer"> 
                                    The 3d Model Viewer supports 
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> <p style={{textAlign: 'left'}}> Visit this page for more details @ <a href="https://docs.fileformat.com/3d/glb/"> https://docs.fileformat.com/3d/glb/ </a></p> </Tooltip>}>
                                    <b style={{color: '#03A9F4'}}> *.glb </b>
                                    </OverlayTrigger>
                                        and 
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> <p style={{textAlign: 'left'}}> *.usdz format is only needed for the AR experience in iOS based phones and tablets only. So, it is not mandatory to upload this format if you do not intent to support the iOS platform. </p> </Tooltip>}>
                                        <b style={{color: '#03A9F4'}}> *.usdz </b>
                                    </OverlayTrigger>
                                        formats. The *.glb format is needed to display the 3d model in model viewer in any browser and also for the AR experience in the Android platform. It is <b>mandatory to upload the *.glb format!</b> <br/> <br/> On the other hand, *.usdz format is needed for AR experience in iOS based phones and tablets. It is not mandatory to upload this format, if you do not intent to support the iOS platform.
                                </Accordion.Body>
                            </Accordion.Item>
                            
                            <Accordion.Item as={Card.Header} eventKey="2">
                                <Accordion.Header className="faqs-question"> # How can I upload 3d models?</Accordion.Header>
                                <Accordion.Body className="faqs-answer"> 
                                    First, you will need to create an account with us. To upload the 3d models in the  <u>*.glb</u> and <u>*.usdz</u> format, you can go to the <b> Asset Manager</b>, then <b>Create an Asset</b> and upload 3d models in there.
                                </Accordion.Body>
                            </Accordion.Item>
                            
                            <Accordion.Item as={Card.Header} eventKey="7">
                                <Accordion.Header className="faqs-question"> # Where can I find my uploaded models?</Accordion.Header>
                                <Accordion.Body className="faqs-answer"> 
                                    Any 3d model you upload will be added to your account. You can find your uploaded models by clicking the <b>Asset Manager</b> button in the menubar.
                                    <br/> <br/> In the Asset Manager, you will find all our assets as card
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item as={Card.Header} eventKey="3">
                                <Accordion.Header className="faqs-question"> # I have existing 3d models of my products, but need help with conversion to *.glb and *.usdz format. Which 3d files formats do you support for conversion?  </Accordion.Header>
                                <Accordion.Body className="faqs-answer">
                                    We currently support *.max, *.skp, *.fbx, *.obj, *.3ds, *.dwg and *.dae formats. We will add more 3d file formats in our list of supported files.
                                    <br/> <br/> We are working on an online converter for 3d models. We will notify you via email when it is ready.
                                    <br/> <br/> Please contact us at: <a href="mailto:info@xenkia.com" style={{color: 'blue'}}> info@xenkia.com </a> for conversion related support.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item as={Card.Header} eventKey="9">
                                <Accordion.Header className="faqs-question"> # We are a company with limited number of users. Can we Subscribe to Individual packages?  </Accordion.Header>
                                <Accordion.Body className="faqs-answer"> 
                                    Ofcourse! You are free to choose any package you want based on your need, regardless of whether you are an Individual or a Company. The packages were designed keeping in mind the traffic our users might expect in their website.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item as={Card.Header} eventKey="4">
                                <Accordion.Header className="faqs-question"> # I am excited about AR, but we currently do not have 3d models. Could you help us with that?  </Accordion.Header>
                                <Accordion.Body className="faqs-answer">
                                    We expect that many of pur potential customers may not have their products digitized already. We have a group of competent 3d artists who can create good quality 3d models of your products at a reasonable price. All we need from you in approximate dimensions and some photos of the product from different angles. It's that simple!
                                    <br/> <br/> For very complex models we recommed 3d scanning. Current generation 3d scanners has micro-meter scanning accuracy, which at the same time captures high resolution texture.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item as={Card.Header} eventKey="5">
                                <Accordion.Header className="faqs-question"> # I am very interested, but do not think I need it at this stage. What to do?  </Accordion.Header>
                                <Accordion.Body className="faqs-answer"> 
                                    This is perfectly fine! Not all business needs such service, but as the demand for AR grows you may eventually decide to use this as more and more customers starts using it. Why not subscribe to our newsletter, so that we can keep you updated on new features. Also, follow us in our <a href="https://www.linkedin.com/company/xenkia/" target="_blank" rel="noreferrer" style={{color: 'blue'}}> <u>LinkedIn</u></a> page, and stay update on the new features as we release them!
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <br/> <br/> <br/> <br/>
                </div>
                


                <div className="Supported-Browsers" style={{marginBottom: '0', backgroundColor: '#DCE0E6'}}>
                    <br/> <br/>
                    <p className="home-header" style={{color: '#008DFF'}}> <b>Supported Browsers</b></p>
                    <Container>
                        <br/> <br/>
                        <Row>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        width={96}
                                        height={96}
                                        alt="chrome"
                                        src={chrome_icon}
                                    />
                                    <Figure.Caption>
                                        <b>Chrome</b>
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        width={96}
                                        height={96}
                                        alt="safari"
                                        src={safari_icon}
                                    />
                                    <Figure.Caption>
                                        <b>Safari</b>
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        width={96}
                                        height={96}
                                        alt="firefox"
                                        src={firefox_icon}
                                    />
                                    <Figure.Caption>
                                        <b>Firefox</b>
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col>
                                <Figure>
                                    <Figure.Image
                                        width={96}
                                        height={96}
                                        alt="microsoft-edge"
                                        src={edge_icon}
                                    />
                                    <Figure.Caption>
                                        <b>Microsft Edge</b>
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                        </Row>   
                        
                        <br/> <br/>

                        <Row className="home-subtext" style={{display: 'flex', justifyContent: 'center'}}>                            
                            <ul style={{listStyleType: 'square', maxWidth: '90%'}}>
                                <li style={{ textAlign: 'justify'}}> <b>3d Viewer</b> is supported on all <b>Windows</b>, <b>MacOS</b>, <b>Linux</b>, <b>iOS</b>, <b>Android</b> based <b>computers, phones and tablets</b>. Basically anything with a browser can run our 3d viewer.</li>
                                <li style={{ textAlign: 'justify'}}> <b>AR</b> is supported only on <b>mobile devices</b> (phones and tablets). Currently, devices running <b>iOS 11.0 or newer</b> and <a href="https://developers.google.com/ar/discover/supported-devices" target="_blank" rel="noreferrer"> <b>Android 7.0 or newer</b></a> is supported.</li>
                            </ul>
                        </Row>
                    </Container>
                    <br/> <br/>
                </div>
                                      
                <br/><br/>
                
                <div style={{marginBottom: '0', backgroundColor: 'white'}}>
                    <br/>
                    <p className="home-header" style={{textAlign: 'center', color: "#008DFF"}}> <b>Plans and Pricing</b></p>
                    {/* <p className="home-sub-subtext" style={{textAlign: 'center', color: "black", marginLeft: '5%', marginRight: '5%'}}> <i>Get <b style={{color: '#FF5722'}}>30% discount<sup><i style={{color: '#FF5722'}}>*</i></sup></b> on <b>yearly subscriptions</b></i> </p> */}
                    

                    {/* <div style={{textAlign: 'center', maxWidth: ''}}> 
                        <p>We have created distinct packages depending on the </p>
                    </div> */}
                    <br/>
                    {/* <p className="home-subtext">Depending on whether you are an Individual, a Team or a Company, you will have certain needs in terms of allocated quota for uploads, views/download, size of the 3d model, storage etc. <br/>In order to prevent clutter we have grouped 6 distinct packages in two separate groups, that you can select visa the following two buttons. </p> */}
                    <p className="home-subtext"> Whether you are <b> an Individual, a Team or a Company</b>, choose from any of the <b>6 distinct packages</b> based on your need. </p>
                    <br/>
                    <Button className="IndividualOrCompanyButtonZoom" variant="success" size="lg" style={{boxShadow: "1px 1px 5px grey", marginRight: '0.5%'}} onClick={ () => this.setState({individualOrCompany: 0})}> <p style={{textShadow: '1px 1px 2px grey'}}> <img src={individual_icon} alt="individual" width="24" height="24"/> Are you an Individual?</p> </Button>{' '}
                    <Button className="IndividualOrCompanyButtonZoom" variant="warning" size="lg" style={{boxShadow: "1px 1px 5px grey", marginLeft: '0.5%'}} onClick={ () => this.setState({individualOrCompany: 1})}> <p style={{textShadow: '1px 1px 2px grey'}}> <img src={company_icon} alt="company" width="24" height="24"/> Are you a Company?</p> </Button>{' '}
                    <p style={{color: 'red', marginTop: '5px', fontSize: 'calc(9px + 0.75vmin)'}}><i><b>**Click to explore pricing-plan tailored to your needs.</b></i></p>



                    <br/>
                    <Container fluid>
                        {this.state.individualOrCompany === 0
                        ?
                            <section>
                                <Row style={{justifyContent: 'center', alignItems: 'center', marginBottom: '1%'}}> 
                                    {individualPricingPlan}
                                </Row>
                            </section>
                        :
                            <section>
                                <Row style={{justifyContent: 'center', alignItems: 'center', marginBottom: '1%'}}> 
                                    {companyPricingPlan}
                                </Row>
                            </section>
                        }
                    </Container>
                    <br/>
                    
                    <Container fluid style={{display: 'flex', justifyContent: 'center', marginLeft: '5%', marginRight: '5%', maxWidth: '80%'}}>
                        {/* <Col> </Col> */}
                        <div>
                            <p className="foot-note" style={{textAlign: 'justify', color: "black"}}> <b>Note:</b> </p>
                            <p className="foot-note" style={{textAlign: 'justify', color: "black"}}> <b style={{color:'#FF5722'}}>*</b> <i>Get <b style={{color: '#FF5722'}}>30% discount</b> on <b>yearly subscriptions</b>. The discount is <b>applicable for</b> the <b>first year only</b>.</i> </p>
                            <p className="foot-note" style={{textAlign: 'justify', color: "black"}}> <b style={{color:'#FF5722'}}>**</b> You are free to <b>choose from any of the 6 packages</b> you want based on your need, <b>regardless of whether you are an Individual or a Company</b>. The packages were designed primarily keeping in mind <b>the traffic our users might expect in their website</b>. </p>
                            <p className="foot-note" style={{textAlign: 'justify', color: "black"}}> <b style={{color:'#FF5722'}}>***</b> <i>The <b>Views/Downloads</b> per month <b>does not</b> include the views from the Asset Manager. <b>Only your customers views are counted</b>.</i> </p>
                        </div>
                        {/* <Col> </Col> */}
                    </Container>
                    <br/>
                    <br/>
                </div>


                <div style={{backgroundColor: '#333333'}}>
                    <br/>
                    <Container fluid>
                        <Row>
                            <Col> </Col>
                            <Col> </Col>
                            {/* <Col style={{minWidth: '400px', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}> */}
                            <Col >
                                <Row style={{minWidth: '25rem'}}>
                                    <img className="buttonZoom" style={{marginRight: '1%', marginLeft: '1%', minWidth: '48px', width: '48px'}} src={linkedin_icon} alt="linkedin" onClick={() => window.open("https://www.linkedin.com/company/xenkia")}></img>
                                    <img className="buttonZoom" style={{marginRight: '1%', marginLeft: '1%', minWidth: '48px', width: '48px'}} src={facebook_icon} alt="facebook" onClick={() => window.open("https://www.facebook.com/Xenkia-100151998649559")}></img>
                                    <img className="buttonZoom" style={{marginRight: '1%', marginLeft: '1%', minWidth: '48px', width: '48px'}} src={twitter_icon} alt="twitter" onClick={() => window.open("https://twitter.com/Xenkia1")}></img>
                                    <img className="buttonZoom" style={{marginRight: '1%', marginLeft: '1%', minWidth: '48px', width: '48px'}} src={instagram_icon} alt="instagram" onClick={() => window.open("https://www.instagram.com/XenkiaAR/")}></img>
                                    <img className="buttonZoom" style={{marginRight: '1%', marginLeft: '1%', minWidth: '48px', width: '48px'}} src={youtube_icon} alt="youtube" onClick={() => window.open("https://www.youtube.com/channel/UCW7OvJfZ6iZ2fxo5b2hsfMA")}></img>
                                    <img className="buttonZoom" style={{marginRight: '1%', marginLeft: '1%', minWidth: '48px', width: '48px'}} src={f6s_icon} alt="f6s" onClick={() => window.open("https://www.f6s.com/xenkia?follow=1")}></img>
                                </Row>
                            </Col>
                            
                        </Row>

                        <Row>
                            <Col> </Col>
                            <Col lg={3} style={{minWidth: '400px', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                {/* <p className="subscription-maintext"> Please subscribe</p> */}
                                {/* <p className="subscription-subtext"> This is the subtext</p> */}
                                <Form style={{justifyContent: 'center', marginRight: '5%', marginLeft: '5%', marginBottom: '5px', marginTop: '10px',minWidth: '70%'}}>
                                    <FormControl 
                                        type="text" 
                                        placeholder="Your email address" 
                                        className="mr-lg-3" 
                                        style={{width: '100%'}}
                                        value={this.state.subscriptionEmail}
                                        onChange={e => {
                                            this.setState({subscriptionEmail: e.target.value });
                                            this.setState({subscriptionButtonHide: false});
                                        }}
                                    />
                                    <br/>
                                    <Button variant="info" disabled={this.state.subscriptionButtonHide}
                                            onClick={async(e) => { 
                                                let res = await fetch(XenkiaAWSconfig.newsletterSubscription.apiUrl, {
                                                    method: 'POST',
                                                    /* mode: 'no-cors', */
                                                    body: JSON.stringify({
                                                        "email":  this.state.subscriptionEmail
                                                    })
                                                })/* 
                                                .then((response) => {
                                                    console.log(response);
                                                }) */

                                                // Get the response from the Backend.
                                                let response = await res.json();
                                                //console.log(response);
                                                
                                                // RESET the form.
                                                this.setState({subscriptionEmail: "" });
                                                this.setState({subscriptionButtonHide: true});

                                                // Display Modal based message for Users.
                                                this.setState({modalTitle: response.body.status, modalBody: response.body.message, modalShow: true});
                                            }}
                                    > 
                                        Subscribe to Newsletter
                                    </Button>

                                    <ModalMessageBox
                                        show        = {this.state.modalShow}
                                        onHide={()  => this.setState({modalShow: false})}
                                        title       = {this.state.modalTitle}
                                        body        = {this.state.modalBody}
                                    />
                                </Form>
                            </Col>
                            <Col> </Col>
                        </Row>

                        <br/>
                        <Row>
                            <Col></Col>
                            <Col style={{minWidth: '20rem'}}>
                                <p className="foot-note" style={{color: 'white'}}> <i>&#169; {new Date().getFullYear()} Xenkia&#8482;, Inc. All Rights Reserved.</i> </p>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                    <br/>
				</div>
                
            </div>
        )
    }    
}

//export default windowSize(Home);
export default Home;
