// Reading Form values: https://github.com/react-bootstrap/react-bootstrap/issues/2418
// Running a Background thread:  https://medium.com/@inkdrop/a-simple-way-to-run-js-in-background-thread-on-react-native-8fff345576da, https://www.newline.co/fullstack-react/articles/introduction-to-web-workers-with-react/

import React, { Component, Fragment, useState} from "react";
// import { Auth, Storage} from 'aws-amplify';
// import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { Button, Navbar, Nav, NavDropdown, Form, FormControl, Badge, InputGroup, Row, Col, Image, Card, DropdownButton, Dropdown, Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import BlockUi from 'react-block-ui';
// import 'react-block-ui/style.css';
//import {email, password} from '../App';
//import 'react-phone-number-input/style.css'
//import PhoneInput from 'react-phone-number-input'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
//import './signup.css';
import '../styling/signup.css';

import ModalMessageBox from '../helper_classes/ModalMessageBox'

import { pricing_plan_list } from "../pricing_plans.js";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {
    CardElement,
    useElements,
    useStripe
  } from "@stripe/react-stripe-js";
  //import "./styles.css";

  // From "https://github.com/yosriady/serverless-stripe-frontend/blob/master/components/PayButton.js"
  import config from '../stripe_config.js';
  // import StripeCheckout from 'react-stripe-checkout';    
  import fetch from 'isomorphic-unfetch';
  import PropTypes from 'prop-types';

  import XenkiaAWSconfig from '../config'

// This flag variable will mark if the current use is Authenticated or not.
export var user_authenticated;


//var phone_number = "";
//var email = "";
//var password = "";


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51IY42qKaj3wxayY5OjvguoZgoRZfuoie4e6JSmmfrKvSkX0P7hUdLU3BmLSjSS5TqFquCu2vvGf0Oj6AYXP53Jv000ZCTm58wb');

// Default Pricing-Plan and Billing-Cycle values passed through URL as parameters.
var default_pricing_plan        = "";
var default_billing_cycle       = "";
var default_cost                = "";
var default_max_num_of_uploads  = "";
var default_max_num_of_views    = "";
var default_max_file_size       = "";
var default_max_storage_space   = "";



// Since the State update is slow and asynchronous, we are using this data structure to check when to enable the payment button, so that accidental payments are not made.
var user_details = {
  email:              "",
  password:           "",
  confirm_password:   "",
  phone:              "",
  address:            "",
  website:            "",
  name:               ""
};



const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#fff",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883"
        },
        "::placeholder": {
          color: "#87bbfd"
        }
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee"
      }
    }
  };


  const CardField = ({ onChange }) => (
    <div className="FormRow">
      <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
  );
  
  const Field = ({
    label,
    id,
    type,
    placeholder,
    required,
    autoComplete,
    value,
    onChange
  }) => (
    <div className="FormRow">
      <label htmlFor={id} className="FormRowLabel">
        {label}
      </label>
      <input
        className="FormRowInput"
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
      />
    </div>
  );
  
  const SubmitButton = ({ processing, error, children, disabled }) => (
    <button
      className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
      type="submit"
      disabled={processing || disabled}
    >
      {processing ? "Processing..." : children}
    </button>
  );
  
  const ErrorMessage = ({ children }) => (
    <div className="ErrorMessage" role="alert">
      <svg width="16" height="16" viewBox="0 0 17 17">
        <path
          fill="#FFF"
          d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
        />
        <path
          fill="#6772e5"
          d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
        />
      </svg>
      {children}
    </div>
  );
  
  const ResetButton = ({ onClick }) => (
    <button type="button" className="ResetButton" onClick={onClick}>
      <svg width="32px" height="32px" viewBox="0 0 32 32">
        <path
          fill="#FFF"
          d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
        />
      </svg>
    </button>
  );



  /**
   * All the checkout from related logic will go here.
   * @returns 
   */
  const CheckoutForm = () => {

    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [paymentDisabled, setPaymentDisabled] = useState(true);
    const [passwordMatched, setPasswordMatched] = useState(false);
    const [passwordLengthCheck, setPasswordLengthCheck] = useState(false);
    const [modalMessageShow, setModalMessageShow] = useState(false);
    const [modalMessageTile, setModalMessageTitle] = useState("");
    const [modalMessageBody, setModalMessageBody] = useState("");
    const [billingDetails, setBillingDetails] = useState({
      email: "",
      phone: "",
      name: ""
    });


    const [state, setState] = useState({
      email:              "",
      password:           "",
      confirm_password:   "",
      phone:              "",
      address:            "",
      website:            "",
      name:               "",
      confirmation_code:  "",
      account_type:       "Individual"
    });

    const [pricing_plan, setPricingPlan] = useState({
      currently_selected_plan:    default_pricing_plan,
      monthly_or_yearly:          default_billing_cycle,
      monthly_or_yearly_cost:     default_cost,
      max_num_of_uploads:         default_max_num_of_uploads,
      max_num_of_views:           default_max_num_of_views,
      max_file_size:              default_max_file_size,
      max_storage_space:          default_max_storage_space
    });


  // Since the setState() is asymchoronous, we have to use the following variables.
  let currently_selected_plan     = pricing_plan.currently_selected_plan;
  let monthly_or_yearly           = pricing_plan.monthly_or_yearly;


  /**
   * Enable the Payment button only if the required fields have been filled up and both the passwords matches.
   */
  function enable_payment_button()
  {
    //Only if the Password and Name isn't empty and the Passwords mateches, proceed.    
    if (user_details.address !== "")
    { 
      if (user_details.email !== "")
      {
        if (user_details.password !== "")
        {
          // The Name of the user cannot be empty.
          if(user_details.name !== "")
          {  
            // The phone number of the user cannot be empty. 
            if (user_details.phone !== "")
            {
                // Check if the Password matches and it's not an empty string.
                if (user_details.password === user_details.confirm_password)
                {
                    // Enable the payment button.
                    setPaymentDisabled(false);
                }
                else{  
                    // Disable the payment button.
                    setPaymentDisabled(true);
                }
            }
            else {
                // Disable the payment button.
                setPaymentDisabled(true);
            }
          }
          else {
              // Disable the payment button.
              setPaymentDisabled(true);
          }
        }
        else{
            // Disable the payment button.
            setPaymentDisabled(true);
        }
      }
      else{
        // Disable the payment button.
        setPaymentDisabled(true);
      }
    }
    else{
      // Disable the payment button.
      setPaymentDisabled(true);
    }

    // Check if the Password has a minimum length.
    if(user_details.password !== "")
    {
      // Check if the Password is at least 8 characters.
      if(user_details.password.length < 8)
      {
        //password_length_check = 0;
        setPasswordLengthCheck(false);
        //console.log("less");
      }
      else
      {
        //console.log('more');
        //password_length_check = 1;
        setPasswordLengthCheck(true);
      }

      // Check if both passwords are identical.
      if (user_details.password === user_details.confirm_password)
      {
          // Passwords matched.
          setPasswordMatched(true);
      }
      else{  
          // Password did not match.
          setPasswordMatched(false);
      }
    }
  }



  /**
   * This function will deal with the SignUp process.
   */
  async function signup_function()
  {
    /*
    console.log(state.name);
    console.log(state.address);
    console.log(state.website);
    console.log('+',state.phone);
    console.log(state.email);
    console.log(state.password);
    console.log(state.confirm_password);

    console.log(billingDetails.name);
    console.log(billingDetails.phone);
    console.log(billingDetails.email);
    */


    //Only if the Password and Name isn't empty and the Passwords mateches, proceed.     
    if (state.password !== "")
    {
      // Check if the Password matches and it's not an empty string.
      if (state.password === state.confirm_password)
      {   
        if (state.phone !== "")
        {
            // The Name of the user cannot be empty.
            if(state.name !== "")
            {
                let retSignup;

                // If all required info has been entered bythe user, then proceed with Signup.
              //  signUp(state.email, state.password, state.name, state.address, state.phone, state.website);

                retSignup = await signUp(state.email, state.password, state.name, state.address, '+'+state.phone, state.website);
                //console.log(ret);

                // Show Success or Error messsage based on the outcome.
                if(retSignup[0] === "error") 
                {
                  console.log("ERROR: ", retSignup[1].message);
                  // Display error.
                  setModalMessageTitle("Error");
                  setModalMessageBody(retSignup[1].message);
                  setModalMessageShow(true);
                }
                else if (retSignup[0] === "success") 
                {
                  console.log("SUCCESS: Almost done! An email was sent to " + retSignup[1].username + " with a verification link. Click that link to verify your email.");

                    // Add the User to the database.
                    let res = await fetch(XenkiaAWSconfig.registration.packageSubscriptionUrl, {
                        method: 'POST',
                        /* mode: 'no-cors', */
                        body: JSON.stringify({
                          "email":                  state.email,
                          "request_type":           "subscribe",
                          "name":                   state.name,
                          "address":                state.address,
                          "phone_number":           state.phone,
                          "secondary_email":        "-",
                          "website":                state.website,
                          "pricing_package":        pricing_plan.currently_selected_plan,
                          "billing_cycle":          pricing_plan.monthly_or_yearly,
                          "package_cost":           Number(pricing_plan.monthly_or_yearly_cost),
                          "expiry_date":            (Date.now() + (365*24*60*60*1000)).toString(),           // Add 1 Year worth of mili-seconds to the current date (in mili-seconds).
                          "max_view_allowed":       Number(pricing_plan.max_num_of_views),
                          "max_uploads_allowed":    Number(pricing_plan.max_num_of_uploads),
                          "max_storage_space":      Number(pricing_plan.max_storage_space)*1024,
                          "max_file_size":          Number(pricing_plan.max_file_size),
                          "download_counter":       0,
                          "upload_counter":         0,
                          "storage_space_used":     0,
                          "subscriptionDate":       Date.now().toString(),
                          "lastActive":             Date.now().toString(),
                          "account_type":           state.account_type,
                          "image_icon_uploaded":    false
                      })
                    })/* 
                    .then((response) => {
                        console.log(response);
                    }) */

                    // Get the response from the Backend.
                    let response = await res.json();

                  // Display success.
                  setModalMessageTitle("Success");
                  setModalMessageBody("Almost done! An email was sent to " + retSignup[1].username + " with a verification link. Click that link to verify your email.");
                  setModalMessageShow(true);
                }


                // Disable the payment button.
                setPaymentDisabled(true);
                setPasswordLengthCheck(false);
                setPasswordMatched(false);

                // RESET the Card Element.
                reset();
            }
            else{
                //console.log("User's Name cannot be empty...");
                alert("Error: User's name cannot be empty");
            }
        }
        else {
            //console.log("Phone number is mandatory for registraion...");
            alert("Error: Phone number is missing");
        }
      }
      else {
          //console.log("Password doesn't match...");
          alert("Error: Passwords do not match");
      }
    }
    else{
        //console.log("Password is empty...");
        alert("Error: Password cannot be empty");
    }
  }

  /**
   * This will select account type as either Individual or Company.
   * @param {*} account_type 
   * @returns 
   */
  const select_account_type  = (account_type) =>{

    console.log(account_type.target.text);

    setState({ ...state, account_type: account_type.target.text })
  }


    /**
     * This function will take into consideration the Pricing-Plan and Billing-Cycle selected by the user, update the price
     * and update the payment details for Stripe.
     * @param {*} plan 
     */
    const select_plan  = (plan) =>
    {    
      //console.log(pricing_plan);
      
      if(plan.target.id === "1") 
      {     
          currently_selected_plan = "Starter";
      }
      else if(plan.target.id === "2") 
      {
          currently_selected_plan = "Personal";
      }
      else if(plan.target.id === "3") 
      {
          currently_selected_plan = "Professional";
      }
      else if(plan.target.id === "4") 
      {
          currently_selected_plan = "Small Business";
      }
      else if(plan.target.id === "5") 
      {
          currently_selected_plan = "Medium Business";
      }
      else if(plan.target.id === "6") 
      {
          currently_selected_plan = "Large Business";
      }
      else if(plan.target.id === "7") 
      {
          monthly_or_yearly = "Monthly";
      }
      else if(plan.target.id === "8") 
      {
          monthly_or_yearly = "Yearly";
      }


      /**
       * Based on the selected Billing-Cycle, display the price.
       */
      if(monthly_or_yearly === "Monthly") 
      {                        
          // Update the "PricingPlan" limts based on the selected package.
          var i;
          for (i=0; i<pricing_plan_list.length; i++)
          {
              pricing_plan_list[i].forEach(element => {

                  if (element.plan === currently_selected_plan)
                  {
                    setPricingPlan({ ...pricing_plan, monthly_or_yearly_cost:     element.monthly_cost_in_usd,
                                                      currently_selected_plan:    element.plan, 
                                                      monthly_or_yearly:          monthly_or_yearly,
                                                      max_num_of_uploads:         element.max_num_of_uploads, 
                                                      max_num_of_views:           element.max_num_of_views, 
                                                      max_file_size:              element.max_file_size,
                                                      max_storage_space:          element.max_storage_space
                                  });
                  }
              });
          }
      }
      else if(monthly_or_yearly === "Yearly") 
      {            
          // Update the "PricingPlan" limts based on the selected package.
          var i;
          for (i=0; i<pricing_plan_list.length; i++)
          {
              pricing_plan_list[i].forEach(element => {

                  if (element.plan === currently_selected_plan)
                  {
                    setPricingPlan({ ...pricing_plan, monthly_or_yearly_cost:     element.yearly_cost_in_usd,
                                                      currently_selected_plan:    element.plan, 
                                                      monthly_or_yearly:          monthly_or_yearly,
                                                      max_num_of_uploads:         element.max_num_of_uploads, 
                                                      max_num_of_views:           element.max_num_of_views, 
                                                      max_file_size:              element.max_file_size,
                                                      max_storage_space:          element.max_storage_space
                                  });
                  }
              });
          }
      }
    }


    /**
     * Process the payment based on the TOKEN generated by Stripe and then Proceed with User Registration if it was a success.
     * @param {*} token 
     */
    const onToken = async token => {// 'token' returned from Stripe.
      
      // Disable payment button.
      setPaymentDisabled(true);

      const res = await fetch(config.stripe.apiUrl, { // Backend API url
        method: 'POST',
        body: JSON.stringify({
          token,
          charge: {
            // The amount is in 'cents'.
            //amount: 1500,
            amount: Number(pricing_plan.monthly_or_yearly_cost)*100,
            currency: config.stripe.currency,
          },
        }),
      });
      
      // Get the response from the Backend.
      const data = await res.json();

      // Based on the status, next step will be taken.
      return data;
    }

    /**
     * This function will handle the Signup process of new users in AWS Cognito.
     * @param {*} username 
     * @param {*} password 
     * @param {*} name 
     * @param {*} address 
     * @param {*} phone 
     * @param {*} website 
     * @returns 
     */
    async function signUp(username, password, name, address, phone, website) {
      /* function signUp(username, password, name, address, phone, website) { */
    
        try {
            // const { user } = await Auth.signUp({
            // /* const { user } = Auth.signUp({ */
            //     username,
            //     password,
            //     attributes: {
            //         // other custom attributes 
            //         "name":             name,
            //         "address":          address,
            //         "phone_number":     phone,              // optional - E.164 number convention
            //         "website":          website
            //     }
            // });
            //console.log(user);
            //console.log("Success: An email was sent to " + user.username + " with a verification link. Click that link to confirm your email.");
            //alert("SUCCESS: Almost done! An email was sent to " + user.username + " with a verification link. Click that link to verify your email.");
            // return ["success", user];
        } catch (error) {
            //console.log("Error: ", error.message);
            //alert("ERROR: ", error.message);
            return ["error", error];
        }
      }



    /**
     * Generate a TOKEN by STRIPE to process the payment.
     * @param {*} event 
     * @returns 
     */
    const handleSubmit = async (event) => {
      
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
  
      if (error) {
        elements.getElement("card").focus();
        return;
      }
  
      if (cardComplete) {
        setProcessing(true);
      }
  
      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card);

      if (result.error) {
        // Show error to your customer.
        //console.log(result.error.message);
        console.log("Error: Token could not be generated");

        // Display error.
        setModalMessageTitle("Error");
        setModalMessageBody("Token could not be generated");
        setModalMessageShow(true);
      } else {
        //signup_function();                // Create a User in the Cognito database first, if successful, then proceed with payment.

        // If all required info has been entered by the user, then proceed with Signup.
        let retSignup, retPayment;

        // Signup, only if, all the required info has been entered, password related checks meet the requirement.
        if( (paymentDisabled === false) && (passwordMatched === true) && (passwordLengthCheck === true))
        {
          let package_durration_in_days;

          // For Expiry-date calculation.
          if(pricing_plan.monthly_or_yearly === "Monthly") package_durration_in_days = 30;
          else if(pricing_plan.monthly_or_yearly === "Yearly") package_durration_in_days = 365;


          retSignup = await signUp(state.email, state.password, state.name, state.address, '+'+state.phone, state.website);
          //console.log(ret);

          // Show Success or Error messsage based on the outcome.
          if(retSignup[0] === "error") 
          {
            console.log("ERROR: ", retSignup[1].message);
            // Display error.
            setModalMessageTitle("Error");
            setModalMessageBody(retSignup[1].message);
            setModalMessageShow(true);
          }
          else if (retSignup[0] === "success") 
          {
                // Add the User to the database.
                let res = await fetch(XenkiaAWSconfig.registration.packageSubscriptionUrl, {
                  method: 'POST',
                  /* mode: 'no-cors', */
                  body: JSON.stringify({
                      "email":                  state.email,
                      "request_type":           "subscribe",
                      "name":                   state.name,
                      "address":                state.address,
                      "phone_number":           state.phone,
                      "secondary_email":        "-",
                      "website":                state.website,
                      "pricing_package":        pricing_plan.currently_selected_plan,
                      "billing_cycle":          pricing_plan.monthly_or_yearly,
                      "package_cost":           Number(pricing_plan.monthly_or_yearly_cost),
                      "expiry_date":            (Date.now() + (package_durration_in_days*24*60*60*1000)).toString(),           // Add 1 Month / 1 Year worth of mili-seconds to the current date (in mili-seconds).
                      "max_view_allowed":       Number(pricing_plan.max_num_of_views),
                      "max_uploads_allowed":    Number(pricing_plan.max_num_of_uploads),
                      "max_storage_space":      Number(pricing_plan.max_storage_space)*1024,
                      "max_file_size":          Number(pricing_plan.max_file_size),
                      "download_counter":       0,
                      "upload_counter":         0,
                      "storage_space_used":     0,
                      "subscriptionDate":       Date.now().toString(),
                      "lastActive":             Date.now().toString(),
                      "account_type":           state.account_type,
                      "image_icon_uploaded":    false
                  })
              })/* 
              .then((response) => {
                  console.log(response);
              }) */

              // Get the response from the Backend.
              let response = await res.json();


              //console.log(result.token);          
              retPayment = await onToken(result.token);
              //console.log("Payment:", retPayment);

              if(retPayment.status === "error")
              {
                console.log("ERROR: Payment was not successful. " + retPayment.error + "However, your accout was created with STARTER package.");
                setModalMessageTitle("Error");
                setModalMessageBody("Payment was not successful. " + retPayment.error + "However, your accout was created with STARTER package.");
                setModalMessageShow(true);
              }
              else if (retPayment.status === "success")
              {
                console.log("SUCCESS: " + retPayment.message);
                // Display success.
                setModalMessageTitle("Success");
                setModalMessageBody("Almost done! An email was sent to " + retSignup[1].username + " with a verification link. Click that link to verify your email. \n\n Also," + retPayment.message);
                setModalMessageShow(true);
              }
          }
        }

/*         // Send the token to thie backend to initiate a payment.
        if(retSignup[0] === "success")
        {
          
        } */

        // RESET the Card Element.
        reset();
      }

      setProcessing(false);
    };
  

    const reset = () => {
      setError(null);
      setProcessing(false);
      setPaymentMethod(null);
      setBillingDetails({
        email: "",
        phone: "",
        name: ""
      });
      setState({
        email:              "",
        password:           "",
        confirm_password:   "",
        phone:              "",
        address:            "",
        website:            "",
        name:               "",
        confirmation_code:  ""
      });

      user_details = {
        email:              "",
        password:           "",
        confirm_password:   "",
        phone:              "",
        address:            "",
        website:            "",
        name:               ""
      };
    };
  

    const handleClose = () => setModalMessageShow(false);
    const handleShow = () => setModalMessageShow(true);



    return (

      <header>

          <br/>
          <div className="signup-background" style={{marginLeft: '3%', marginRight: '3%', minWidth: '80%', maxWidth: '1024px', justifyContent: 'center'}}>

              <h1>  SignUp Form </h1>

              <br/>
              <label className="subheading"><b>User details</b></label>
              <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1"><b>Full name*</b></InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl 
                      type="username" 
                      placeholder="Enter your full name"
                      value={state.name}
                      onChange={e => {
                        setState({ ...state, name: e.target.value });
                        setBillingDetails({ ...billingDetails, name: e.target.value });
                        user_details.name = e.target.value;
                        enable_payment_button();
                      }}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                  />
              </InputGroup>

              <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1"><b>Address*</b></InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl 
                      type="address" 
                      placeholder="Enter address"
                      value={state.address}
                      onChange={e => {
                        setState({ ...state, address: e.target.value });
                        user_details.address = e.target.value;
                        enable_payment_button();
                      }}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                  />
              </InputGroup>

              <InputGroup className="mb-3">
                  <PhoneInput
                      country={'us'}
                      value={state.phone}
                      onChange={phone => {
                        setState({ ...state, phone: phone });
                        setBillingDetails({ ...billingDetails, phone: phone });
                        user_details.phone = phone;
                        enable_payment_button();
                      }}
                  />
              </InputGroup>

              <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1"><b>Website</b></InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl 
                      type="address" 
                      placeholder="Enter website"
                      value={state.website}
                      onChange={e => {
                        setState({ ...state, website: e.target.value });
                        user_details.website = e.target.value;
                        enable_payment_button();
                      }}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                  />
              </InputGroup>

              <InputGroup className="mb-3">
                  <DropdownButton
                      as={InputGroup.Prepend}
                      variant="outline-primary"
                      title="Account type"
                      id="input-group-dropdown-1"
                  >
                  <Dropdown.Item text="individual" onClick={select_account_type}>Individual</Dropdown.Item>

                  <Dropdown.Item text="company" onClick={select_account_type}>Company</Dropdown.Item>
                  </DropdownButton> 
                  <FormControl 
                      aria-describedby="basic-addon1"
                      placeholder={state.account_type}
                      disabled/>
              </InputGroup>


              <br/>
              <label className="subheading"><b>Login credentials</b></label>
              <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1"><b>Email*</b></InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl 
                      type="email" 
                      placeholder="Enter email address"
                      value={state.email}
                      onChange={email => {
                        setState({ ...state, email: email.target.value });
                        setBillingDetails({ ...billingDetails, email: email.target.value });
                        user_details.email = email.target.value;
                        enable_payment_button();
                      }}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                  />
              </InputGroup>

              <Row>
                <Col style={{minWidth: '250px'}}>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1"><b>Password*</b></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl 
                            type="password" 
                            autoComplete="new-password"
                            placeholder="Enter password"
                            value={state.password}
                            onChange={e => {
                              setState({ ...state, password: e.target.value });
                              user_details.password = e.target.value;
                              enable_payment_button();
                            }}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>

                    {passwordLengthCheck
                    ?
                      <p className="note-password" style={{color: 'green'}}> <img src="https://xenkia-web-assets.s3-accelerate.amazonaws.com/checked.png" width="auto" height="16px" style={{marginTop: '5%', marginBottom: '5%'}}></img> <b>Password</b> is acceptable.</p>
                    :
                      <p className="note-password" style={{color: 'red'}}> <img src="https://xenkia-web-assets.s3-accelerate.amazonaws.com/cancel.png" width="auto" height="16px" style={{marginTop: '5%', marginBottom: '5%'}}></img> Minimum <b>Password</b> length is 8 characters.</p>
                    }
                  </Col>

                  <Col style={{minWidth: '250px'}}>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1"><b>Confirm*</b></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl 
                            type="password"
                            placeholder="Re-type password"
                            autoComplete="new-password"
                            value={state.confirm_password}
                            onChange={e => {
                              setState({ ...state, confirm_password: e.target.value });
                              user_details.confirm_password = e.target.value;
                              enable_payment_button();
                            }}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>

                    {passwordMatched
                    ?
                      <p className="note-password" style={{color: 'green'}}> <img src="https://xenkia-web-assets.s3-accelerate.amazonaws.com/checked.png" width="auto" height="16px" style={{marginTop: '5%', marginBottom: '5%'}}></img> <b>Passwords</b> are identical.</p>
                    :
                      <p className="note-password" style={{color: 'red'}}> <img src="https://xenkia-web-assets.s3-accelerate.amazonaws.com/cancel.png" width="auto" height="16px" style={{marginTop: '5%', marginBottom: '5%'}}></img> <b>Passwords</b> has to match.</p>
                    }
                  </Col>                  
                </Row>


              <div>
                  <br/>
                  <label className="subheading" style={{textAlign: 'justify'}}><b>Pricing and Subscription</b></label>
                  
                  <Row>
                      <Col style={{minWidth: '250px'}}>
                          <InputGroup className="mb-3">
                              <DropdownButton
                                  as={InputGroup.Prepend}
                                  variant="outline-danger"
                                  title="Pricing-Plan"
                                  id="input-group-dropdown-1"
                              >
                              <Dropdown.Item id="1" onClick={select_plan}>Starter</Dropdown.Item>
                              <Dropdown.Item id="2" onClick={select_plan}>Personal</Dropdown.Item>
                              <Dropdown.Item id="3" onClick={select_plan}>Professional</Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item id="4" onClick={select_plan}>Small Business</Dropdown.Item>
                              <Dropdown.Item id="5" onClick={select_plan}>Medium Business</Dropdown.Item>
                              <Dropdown.Item id="6" onClick={select_plan}>Large Business</Dropdown.Item>   
                              </DropdownButton> 
                              <FormControl 
                                  aria-describedby="basic-addon1"
                                  placeholder={pricing_plan.currently_selected_plan}
                                  disabled/>
                          </InputGroup>
                      </Col>

                      <Col style={{minWidth: '250px'}}>
                          <InputGroup className="mb-3">
                              <DropdownButton
                                  as={InputGroup.Prepend}
                                  variant="outline-primary"
                                  title="Billing Cycle"
                                  id="input-group-dropdown-1"
                              >
                              <Dropdown.Item id="7" onClick={select_plan}>Billed Monthly</Dropdown.Item>

                              <Dropdown.Item id="8" onClick={select_plan}>Billed Annually</Dropdown.Item>
                              </DropdownButton> 
                              <FormControl 
                                  aria-describedby="basic-addon1"
                                  placeholder={pricing_plan.monthly_or_yearly}
                                  disabled/>
                          </InputGroup>
                      </Col>
                  </Row>

                  <br/>
                  <Row style={{display: 'flex', justifyContent: 'center'}}>
                      {/* <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                          <InputGroup.Text><b>Price:</b></InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl placeholder={pricing_plan.monthly_or_yearly_cost} disabled/>
                          <InputGroup.Append>
                          <InputGroup.Text><b>USD</b></InputGroup.Text>
                          </InputGroup.Append>
                      </InputGroup> */}
                      <label style={{color: '#673AB7'}}> <b>${pricing_plan.monthly_or_yearly_cost} USD</b> </label>
                  </Row>


                  <Row>
                    <Col> </Col>

                    <Col lg={10}>
                      
                      <hr/>
                        
                          {/* { paymentMethod 
                            ? (
                              <div className="Result">
                                <div className="ResultTitle" role="alert">
                                  Payment successful
                                </div>
                                <div className="ResultMessage">
                                  Thanks for trying Stripe Elements. No money was charged, but we
                                  generated a PaymentMethod: {paymentMethod.id}
                                </div>
                                <ResetButton onClick={reset} />
                              </div>
                            )
                            : ( */}
                              
                            { pricing_plan.currently_selected_plan !== "Starter" 
                            ?
                              <form className="Form" onSubmit={handleSubmit}>
                                  <Row style={{justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', marginBottom: '1%'}}>
                                    <fieldset className="FormGroup" style={{minWidth: '350px', width: '90%'}}>
                                      <CardField
                                        onChange={(e) => {
                                          setError(e.error);
                                          setCardComplete(e.complete);
                                        }}
                                      />
                                    </fieldset>
                                    <p className="note-info"> <i> <b>**ZIP</b> is your the postal code registered with your bank against this credit card.</i> </p>
                                  </Row>

                                  <Row style={{justifyContent: 'center', marginTop: '1%', marginLeft: 'auto', marginRight: 'auto', minWidth: '50%', width: '85%'}}>
                                    {error && <ErrorMessage>{error.message}</ErrorMessage>}
                                    <SubmitButton processing={processing} error={error} disabled={!stripe} disabled={paymentDisabled}>
                                        Pay ${pricing_plan.monthly_or_yearly_cost} USD and Signup
                                    </SubmitButton>                                    
                                  </Row>
                              </form> 
                            :
                                <Button variant="info" type="button" disabled={paymentDisabled} onClick={signup_function} style={{marginLeft: 'auto', marginRight: 'auto', minWidth: '50%', width: '85%'}}> <b>FREE Subscription</b> </Button>
                            }

                            {/* )
                          } */} 
                          <hr/>
                    </Col>

                    <Col> </Col>
                  </Row>
              </div>

              {/* <Button variant="info" type="button" onClick={() => setModalMessageShow(true)} > <b>Modal</b> </Button> */}

              <ModalMessageBox
                  show        = {modalMessageShow}
                  onHide={()  => setModalMessageShow(false)}
                  title       = {modalMessageTile}
                  body        = {modalMessageBody}
              />
              

              <br/>
              <p className="note-text" style={{marginLeft: 'auto', marginRight: '5%', textAlign: 'justify'}}> <b><u>Note</u>:</b> </p>
              <ul>
                  <li className="note-subtext" style={{textAlign: 'justify', marginRight: '5%'}}>  <i>Full name</i>, <i>Email</i>, <i>Address</i> , <i>Phone Number</i> and <i>Password</i> is mandatory for Signup.  </li>
                  <li className="note-subtext" style={{textAlign: 'justify', marginRight: '5%'}}>  <i>'Starter'</i> package is <i>free</i>. </li>
                  {/* <li className="note-subtext" style={{textAlign: 'justify', marginRight: '5%'}}> After <i>SignUp</i> button has been pressed, <i>Do not</i> refresh this page, until your email has been confirmed. </li>        
                  <li className="note-subtext" style={{textAlign: 'justify', marginRight: '5%'}}> A <i>Confirmation Code</i> will be sent to your email. Paste submit that code below to complete the SignUp process.</li>         */}
              </ul>

              </div>
        </header>
      );
  };
  
  
  const ELEMENTS_OPTIONS = {
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
      }
    ]
  };






class Login extends Component {

    constructor() {
        // This had to be included. Source: https://stackoverflow.com/questions/31067368/how-to-extend-a-class-without-having-to-use-super-in-es6
        super();
    
        /* 1. Initialize Ref */
        //email           = React.createRef();
        //password        = React.createRef();
    }



    UNSAFE_componentWillMount()
    {
        //console.log(this.props.match.params.email);
        //console.log(this.props.match.params.pass);

        default_pricing_plan  = this.props.match.params.plan;
        default_billing_cycle = this.props.match.params.cycle;
        default_cost          = this.props.match.params.cost;


        // Depending on the Pricing-Plan related paramters passed to this page, the following segement of code will get the limits that applies to this account.
        var i;
        for (i=0; i<pricing_plan_list.length; i++)
        {
            pricing_plan_list[i].forEach(element => {

                if (element.plan === default_pricing_plan)
                {
                  default_max_num_of_uploads          = element.max_num_of_uploads;
                  default_max_num_of_views            = element.max_num_of_views;
                  default_max_file_size               = element.max_file_size;
                  default_max_storage_space           = element.max_storage_space;

                  //console.log(element.plan);
                }
            });
        }

        

        //let email_pass = this.props.location.pathname.split('email=');
        //console.log(this.props.match.params.plan);
        //console.log(this.props.match.params.cycle);
        //console.log(default_pricing_plan, default_billing_cycle, default_cost);
    }

    
    render() {
        return (
          <header>
              <div className="AppWrapper">
                  <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                      <CheckoutForm />
                  </Elements>
              </div>       
          </header>        
        );
    }    
}



  // This function will handle the SignUp process.
/*   async function signUp(username, password, name, address, phone, website) {
  // function signUp(username, password, name, address, phone, website) {

    try {
        const { user } = await Auth.signUp({
        // const { user } = Auth.signUp({
            username,
            password,
            attributes: {
                // other custom attributes 
                "name":             name,
                "address":          address,
                "phone_number":     phone,              // optional - E.164 number convention
                "website":          website
            }
        });
        //console.log(user);
        //console.log("Success: An email was sent to " + user.username + " with a verification link. Click that link to confirm your email.");
        //alert("SUCCESS: Almost done! An email was sent to " + user.username + " with a verification link. Click that link to verify your email.");
        return ["success", user];
    } catch (error) {
        //console.log("Error: ", error.message);
        //alert("ERROR: ", error.message);
        return ["error", error];
    }
  } */


export default Login;
