// Reading Form values: https://github.com/react-bootstrap/react-bootstrap/issues/2418
// Running a Background thread:  https://medium.com/@inkdrop/a-simple-way-to-run-js-in-background-thread-on-react-native-8fff345576da, https://www.newline.co/fullstack-react/articles/introduction-to-web-workers-with-react/

import React, { Component, Fragment, useState} from "react";
// import { Auth } from 'aws-amplify';
// import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { Button, Navbar, Nav, NavDropdown, Form, FormControl, Badge, InputGroup, Row, Col, Image, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import BlockUi from 'react-block-ui';
// import 'react-block-ui/style.css';
//import {email, password} from '../App';
//import 'react-phone-number-input/style.css'
//import PhoneInput from 'react-phone-number-input'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
//import './signup.css';


// This flag variable will mark if the current use is Authenticated or not.
export var user_authenticated;



//var phone_number = "";
//var email = "";
//var password = "";



class ChangeTemporaryPassword extends Component {
    

    state ={
        email:              "",
        password:           "",
        confirm_password:   "",
        temp_password:      ""
    };

    constructor(props) {
        // This had to be included. Source: https://stackoverflow.com/questions/31067368/how-to-extend-a-class-without-having-to-use-super-in-es6
        super(props);        

        //console.log(props.location.pathname.split('email='));  
        //console.log(props.location.pathname.split('pass='));  


        // 1. Initialize Ref
        //email           = React.createRef();
        //password        = React.createRef();
    }

    UNSAFE_componentWillMount()
    {
        //console.log(this.props.match.params.email);
        //console.log(this.props.match.params.pass);

        this.setState({ email:          this.props.match.params.email })
        this.setState({ temp_password:  this.props.match.params.pass })

        //let email_pass = this.props.location.pathname.split('email=');
        /* console.log(this.props.location.pathname); */
        //console.log(email_pass); 
    }

    // This function will deal with the SignUp process.
    signin_function = () => {
        /*console.log(this.state.name);
        console.log(this.state.address);
        console.log(this.state.website);
        console.log('+',this.state.phone);
        console.log(this.state.email);
        console.log(this.state.password);
        console.log(this.state.confirm_password);
        */


        // Only if the Password and Name isn't empty and the Passwords matches, proceed.
        if (this.state.password !== "")
        {
            // Check if the Password matches and it's not an empty string.
            if (this.state.password === this.state.confirm_password)
            {   
                /* // The Name of the user cannot be empty.
                if(this.state.name !== "")
                {
                    signUp(this.state.email, this.state.password, this.state.name, this.state.address, '+'+this.state.phone, this.state.website);
                }
                else{
                    console.log("User's Name cannot be empty...");
                    alert("Error: User's name cannot be empty");
                } */
                
                
                signIn(this.state.email, this.state.password, this.state.temp_password);
            }
            else {
                console.log("Password doesn't match...");
                alert("Error: Passwords do not match");
            }
        }
        else{
            console.log("Password is empty...");
            alert("Error: New Password cannot be empty");
        }
    }


    
    // Confirm the SignUp process by sending the code to the server.
    signup_confirm_function = () => {

        //confirmSignUp(this.state.email, this.state.confirmation_code);
        //console.log(this.state.confirmation_code);
    }



    render() {
        return (

            <div>
                <div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '1%', marginBottom: '1%', minWidth: '400px', maxWidth: '1024px', justifyContent: 'center'}}>

                    <h1> Update password </h1>

                    <br/>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1"><b>Email</b></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl 
                            type="email" 
                            placeholder={this.state.email}
                            //onChange={e => this.setState({ email: e.target.value })}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            disabled
                        />
                    </InputGroup>

                    {/* <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1"><b>Temporary Password</b></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl 
                            type="email" 
                            placeholder={this.state.temp_password}
                            //onChange={e => this.setState({ temp_password: e.target.value })}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            disabled
                        />
                    </InputGroup> */}

                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1"><b>New Password</b></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl 
                            type="password" 
                            autoComplete="new-password"
                            placeholder="Enter new password"
                            onChange={e => this.setState({ password: e.target.value })}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1"><b>Confirm Password</b></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl 
                            type="password" 
                            placeholder="Re-type new password"
                            autoComplete="new-password"
                            onChange={e => this.setState({ confirm_password: e.target.value })}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>

                    <br/>

                    <Button variant="primary" size="lg" type="button" onClick={this.signin_function}> Update Password </Button>

                    <br/>
                    {/* <p className="note-text" style={{marginLeft: 'auto', marginRight: '5%', textAlign: 'justify'}}> <b><u>Note</u>:</b> </p>
                    <ul>
                        <li className="note-subtext" style={{textAlign: 'justify', marginRight: '5%'}}>  <i>Full name</i>, <i>Email</i> and <i>Password</i> is mandatory for SignUp.  </li>
                        <li className="note-subtext" style={{textAlign: 'justify', marginRight: '5%'}}> After <i>SignUp</i> button has been pressed, <i>Do not</i> refresh this page, until your email has been confirmed. </li>        
                        <li className="note-subtext" style={{textAlign: 'justify', marginRight: '5%'}}> A <i>Confirmation Code</i> will be sent to your email. Paste submit that code below to complete the SignUp process.</li>        
                    </ul> */}

                </div>                
            </div>
        );
    }    
}


// Asynchronous SignIn function.
// Note: Solve how to change the state variable externally based on this article: https://stackoverflow.com/questions/37513440/set-react-component-state-from-outside-of-component, 
// and https://twitter.com/dan_abramov/status/824308413559668744/photo/2
async function signIn(username, new_password, temp_password) {

    //console.log(username.current.value);
  
    try {
        //console.log(username, new_password, temp_password);
        // await Auth.signIn(username, temp_password)
        //     .then(user => {
        //         if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        //             const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
        //             Auth.completeNewPassword(
        //                 user,               // the Cognito User Object
        //                 new_password,       // the new password
        //             ).then(user => {
        //                 // at this time the user is logged in if no MFA required
        //                 //console.log(user);
        //                 alert("Password successfully updated");
        //                 window.location.replace("http://www.xenkia.com");
        //             }).catch(e => {
        //             console.log(e);
        //             });
        //         } else {
        //             // other situations
        //         }
        //     }).catch(e => {
        //         console.log(e);
        // });
  
        // Refresh the app to update the login specific informations on the Nav Bar.
        //window.location.reload();
  
        //return 1;
    } catch (error) {
        console.log('Error signing in', error);
  
        //return 1;
    }
 }


export default ChangeTemporaryPassword;
